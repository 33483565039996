/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Components
import { Carousel, message } from "antd";
import { HeaderSteps, CommentsCamara, DocumentListItem, Loader, Button, Camara } from "../../components";
import { AppContext } from "../../context/app.context";

// Styles
import { TaskPageWrapper, ImageWrapper, BadPhotoOverlayWrapper, LabelWrapper, Subheader, TaskPageContent } from "./task.styled";
import { TableWrapper, TableRow } from "../view-car/view-car.style";

// Utils
import { getTimeString, getDateString } from "../../utils/index";

export default function TaskPage(props) {
  let location = useLocation();
  const { user, getTasks, setCurrentDetailTask, API_URL, setIsLoading } = useContext(AppContext);
  const [task, setTask] = useState(null);

  const [generalPhotos, setGeneralPhotos] = useState([]);
  const [generalPhotosVinovat, setGeneralPhotosVinovat] = useState([]);
  const [cupiPhotos, setCupiPhotos] = useState([]);

  const [documentFromFormPhoto, setDocumentFromFormPhoto] = useState([]);
  const [photoToBeEdited, setPhotoToBeEdited] = useState(null);
  const [commentsCameraVisible, setCommentsCameraVisible] = useState(false);
  const [normalCameraVisible, setNormalCameraVisible] = useState(false);

  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    if (!task) {
      fetchTask();
    }

    if (task) {
      organizePhoto();
    }
  }, [task]);

  const fetchDocuments = () => {
    axios({
      method: "get",
      url: `${API_URL}/task/documents?task_id=${location.search.substring(4)}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then(function (response) {
        //handle success
        setDocuments(response.data.documents);
        console.log("documents", response.data.documents);
      })
      .catch(function (response) {
        //handle error
        console.dir(response);
      });
  };

  const fetchTask = () => {
    const bodyFormData = new FormData();
    bodyFormData.set("id", location.search.substring(4));

    axios({
      method: "post",
      url: `${API_URL}/task/get-task`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then(function (response) {
        //handle success
        setTask(response.data);
        setCurrentDetailTask(response.data);
        fetchDocuments();
      })
      .catch(function (response) {
        //handle error
        console.dir(response);
      });
  };

  const organizePhoto = () => {
    // cupi 4
    // generale 3
    //
    const futureDocumentsPhotos = photos.filter((photo) => photo.category_id === 1 || photo.category_id === 2 || photo.category_id === 5);
    const futureGeneralPhotos = photos.filter((photo) => photo.category_id === 3);
    const futureCupiPhotos = photos.filter((photo) => photo.category_id === 4);
    const futureGeneralPhotosVinovat = photos.filter((photo) => photo.category_id === 6);

    setGeneralPhotosVinovat(futureGeneralPhotosVinovat);
    setDocumentFromFormPhoto(futureDocumentsPhotos);
    setCupiPhotos(futureCupiPhotos);
    setGeneralPhotos(futureGeneralPhotos);
  };

  const sortRejectedFirst = (list) => {
    list = list.sort((e) => {
      if (e.status === "rejected") {
        return -1;
      } else {
        return 1;
      }
    });

    return list;
  };

  const renderBadPhotoOverlay = ({ photo, text }) => {
    return (
      <BadPhotoOverlayWrapper className="BadPhotoOverlayWrapper">
        <h2>{text}</h2>

        <Button
          onClick={() => {
            setPhotoToBeEdited(photo);

            if (photo.is_cupi === true) {
              setCommentsCameraVisible(true);
            } else {
              setNormalCameraVisible(true);
            }
          }}
          shape="round"
          title="Refă poză"
        />
      </BadPhotoOverlayWrapper>
    );
  };

  const sendPhoto = (photoURI, cupiPartObject = null) => {
    setIsLoading(true);

    console.log(photoURI);
    const responseObject = {
      id: photoToBeEdited.id,
      base64: photoURI,
      comment: cupiPartObject ? cupiPartObject.comment : null,
    };

    axios({
      method: "post",
      url: `${API_URL}/task-photo/update-photo`,
      data: responseObject,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.jwt}`,
      },
    })
      .then(function (response) {
        //handle success
        fetchTask();
        getTasks(user?.jwt);
        message.success("Poza a fost schimbată");
        setIsLoading(false);
      })
      .catch(function (response) {
        //handle error
        setIsLoading(false);
      });
  };

  const renderGeneralPhoto = (photo) => {
    return (
      <ImageWrapper key={photo.id}>
        {photo.status === "requested" && renderBadPhotoOverlay({ photo, text: "Poză noua solicitată" })}
        {photo.status === "rejected" && renderBadPhotoOverlay({ photo, text: "Poză refuzată" })}

        <img alt={`Bad ${photo.name}`} src={photo.uri} />
        <LabelWrapper>{photo.name}</LabelWrapper>
      </ImageWrapper>
    );
  };

  const renderCupiPhoto = (photo) => {
    return (
      <ImageWrapper key={photo.id}>
        {photo.status === "requested" && renderBadPhotoOverlay({ photo, text: "Poză noua solicitată" })}
        {photo.status === "rejected" && renderBadPhotoOverlay({ photo, text: "Poză refuzată" })}
        <img alt={`Cupi ${photo.name}`} src={photo.uri} />

        {/* Disabled until new design */}
        {false && (
          <span
            className="CommentLabel"
            style={{
              top: `${photo.comment.coords.top}%`,
              left: `${photo.comment.coords.left}%`,
            }}
          >
            {photo.comment.label}
          </span>
        )}
        <LabelWrapper className="LabelWrapper">{photo.name}</LabelWrapper>
        <span className="photo-type">{photo.photo_type}</span>
      </ImageWrapper>
    );
  };

  if (!task) {
    return <Loader />;
  }

  const {
    car_make,
    car_model,
    car_registration_number,
    created_at,
    task_asignee,
    task_id,
    photos,
    task_client,
    task_status,
    task_type,
  } = task;

  // Parse 2 times because we got "bad" response from server
  const task_response = JSON.parse(JSON.parse(task.task_response));

  const renderNormalPage = (step) => {
    return (
      <>
        <label className="step-title">{step.title}</label>

        <TableWrapper className="TableWrapper">
          {step.inputs.map((input) => {
            if (input.type === "text") {
              return (
                <TableRow className="TableRow">
                  <label>{input.label}:</label>
                  <span>{input.value ? input.value : "Necompletat"}</span>
                </TableRow>
              );
            }
            if (input.type === "date") {
              return (
                <TableRow className="TableRow">
                  <label>{input.label}:</label>
                  <span>{input.value ? getDateString(input.value) : "Necompletat"}</span>
                </TableRow>
              );
            }
            if (input.type === "time") {
              return (
                <TableRow className="TableRow">
                  <label>{input.label}:</label>
                  <span>{input.value ? getTimeString(input.value) : "Necompletat"}</span>
                </TableRow>
              );
            }
            if (input.type === "select") {
              return (
                <TableRow className="TableRow">
                  <label>{input.label}:</label>
                  <span>{input.value?.label || "Necompletat"}</span>
                </TableRow>
              );
            }
            if (input.type === "radio") {
              return (
                <TableRow className="TableRow">
                  <label>{input.label}:</label>
                  <span>{input.value?.label || "Necompletat"}</span>
                </TableRow>
              );
            }
            if (input.type === "photo") {
              return (
                <TableRow className="TableRow">
                  <label>{input.general_label}:</label>
                  <span>
                    {input.photos
                      ? input.photos?.map((photo, index) => {
                          return <img alt={input.general_label} key={`photo-${index}`} src={photo.value} />;
                        })
                      : "Fără poză"}
                  </span>
                </TableRow>
              );
            }
          })}
        </TableWrapper>
      </>
    );
  };

  return (
    <TaskPageWrapper className="TaskPageWrapper">
      <HeaderSteps title={`Task #${task_id}`} />

      <Subheader className="Subheader">
        <div className="task-info">
          <span>Detalii solicitare #{task_id}</span>
          <span className="status">{task_status.name}</span>
        </div>
      </Subheader>

      {commentsCameraVisible && (
        <CommentsCamara
          photoType={photoToBeEdited.photo_type}
          title={photoToBeEdited.name}
          editMode
          disableUpload
          setImagePreviewFullScreenMode={() => {}}
          carPart={photoToBeEdited}
          setPhotoURI={(photoURI, cupiPhotoNew) => {
            sendPhoto(photoURI, cupiPhotoNew);
          }}
          setCamaraVisible={setCommentsCameraVisible}
        />
      )}

      {normalCameraVisible && (
        <Camara
          title={"TEST"}
          disableUpload
          setImagePreviewFullScreenMode={() => {}}
          setPhotoURI={(photoURI) => {
            sendPhoto(photoURI);
          }}
          cameraVisible={normalCameraVisible}
          setCamaraVisible={setNormalCameraVisible}
        />
      )}

      <TaskPageContent className="TaskPageContent">
        {/* Render steps */}
        {/* Disabled because we got wrong info from bo*/}

        {false &&
          task_response.map((step) => {
            if (!step.page) {
              return renderNormalPage(step);
            }
          })}

        {/* Documents */}
        {documents && (
          <>
            <label className="step-title">Documente</label>
            <TableWrapper className="TableWrapper">
              {documents.map((document) => (
                <DocumentListItem document={document} key={document.fileName} />
              ))}
            </TableWrapper>
          </>
        )}

        {/* General photos */}
        {generalPhotos.length > 0 && (
          <>
            <label className="step-title">{task_type === "IR" ? "Poze generale auto asigurat" : "Poze generale păgubit"}</label>
            <TableWrapper className="TableWrapper">
              <Carousel>{sortRejectedFirst(generalPhotos).map((photo) => renderGeneralPhoto(photo))}</Carousel>
            </TableWrapper>
          </>
        )}

        {/* General photos */}
        {generalPhotosVinovat.length > 0 && (
          <>
            <label className="step-title">Poze generale vinovat</label>
            <TableWrapper className="TableWrapper">
              <Carousel>{sortRejectedFirst(generalPhotosVinovat).map((photo) => renderGeneralPhoto(photo))}</Carousel>
            </TableWrapper>
          </>
        )}

        {/* Cupi photos */}
        {cupiPhotos.length > 0 && (
          <>
            <label className="step-title">Poze piese</label>
            <TableWrapper className="TableWrapper">
              <Carousel>{sortRejectedFirst(cupiPhotos).map((photo) => renderCupiPhoto(photo))}</Carousel>
            </TableWrapper>
          </>
        )}

        {/* Documents photos */}

        {documentFromFormPhoto.length > 0 && (
          <>
            <label className="step-title">Poze documente</label>
            <TableWrapper className="TableWrapper">
              <Carousel>{sortRejectedFirst(documentFromFormPhoto).map((photo) => renderGeneralPhoto(photo))}</Carousel>
            </TableWrapper>
          </>
        )}

        {/* Render car info */}
        <label className="step-title">Despre mașină</label>
        <TableWrapper className="TableWrapper">
          <TableRow className="TableRow">
            <label>Model:</label>
            <span>{car_make}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Model:</label>
            <span>{car_model}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Număr inmatriculare:</label>
            <span>{car_registration_number}</span>
          </TableRow>
        </TableWrapper>

        {/* Render extra */}
        <label className="step-title">Extra info</label>
        <TableWrapper className="TableWrapper">
          <TableRow className="TableRow">
            <label>Solicitare id:</label>
            <span>{task_id}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Creat la data de:</label>
            <span>{created_at}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Tipul solicitării:</label>
            <span>{task_type}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Asignată către:</label>
            <span>{task_asignee.name}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Numele clientului:</label>
            <span>{task_client.name}</span>
          </TableRow>
          <TableRow className="TableRow">
            <label>Starea solicitării:</label>
            <span>{task_status.name}</span>
          </TableRow>
        </TableWrapper>
      </TaskPageContent>
    </TaskPageWrapper>
  );
}
