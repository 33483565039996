import React from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { FancyHeader, HeaderSteps } from '../../../../components';

import { LegalPageWrapper, LegalPageContent } from './legal.style';

const ContestRules = () => (
  <div>
    <h1>REGULAMENTUL OFICIAL AL CAMPANIEI</h1>
    <h2>
      “Carfix premiază fidelitatea”

      desfășurată in perioada
      {' '}
      <strong>08.03.2022 – 08.06.2022</strong>
    </h2>
    <br />
    <br />
    <br />
    <h3><strong>Art. 1. ORGANIZATORUL CAMPANIEI</strong></h3>
    <p>
      Campania „Carfix premiază fidelitatea” denumita in continuare
      {' '}
      <strong>"Campania"</strong>
      , este organizata de catre GTM Managing Services SA cu
      sediul în Mun. București, Sector 1, Nicolae Caramfil nr. 71-73, ART BC 5, corp A, etaj 4, inregistrata la Registrul Comertului
      sub nr. J40/14833/2013, avand CUI 32542239, denumita in continuare
      {' '}
      <strong>„Organizator"</strong>
      .
      {' '}
    </p>
    <p>
      Campania se va derula cu respectarea prevederilor cuprinse în prezentul regulament (denumit în cele ce urmează
      {' '}
      <strong>“Regulamentul”</strong>
      ).
      Termenii și condițiile prezentului Regulament, așa cum sunt prezentate mai jos, sunt obligatorii pentru toți participanții
      la campanie.
    </p>

    <p>
      Organizatorul isi rezerva dreptul de a modifica sau schimba prezentul Regulament Oficial, urmand ca astfel de modificari
      sa intre in vigoare numai dupa anuntarea in mod public a modificarilor respective prin intermediul acelorasi canale de
      comunicare prin care Regulamentul Oficial a fost facut public anterior, precum si prin notificarea autoritatilor competente,
      dupa caz, daca modificarile efectuate necesita o astfel de notificare.

    </p>

    <p>
      Organizatorul isi rezerva dreptul de a suspenda, inceta si/sau intrerupe oricand desfasurarea Concursului, fara vreo obligatie
      fata de Participanti.
    </p>
    <p>
      Regulamentul este facut public conform legislatiei aplicabile din Romania, fiind disponibil in mod gratuit oricarei persoane
      atat on-line pe website-ul www.carfixapp.com cat si in baza solicitarii persoanelor interesate, transmisa catre adresa de
      e-mail contact@carfixapp.com pe intreaga durata a Campaniei potrivit celor mentionate in Articolul 2 al prezentului Regulament.
    </p>
    <p>
      Rugam persoanele care transmit astfel de solicitari sa precizeze in subiectul e-mailului sau pe plicul de corespondenta
      informatii precum „Regulament campanie”. Organizatorul va trimite, in acest caz, un raspuns in scris pe adresa de e-mail
      furnizata de catre Participant. in termen de 15 zile lucratoare de la data primirii cererii de informatii.
    </p>
    <h3>
      <strong>
        Art. 2. DURATA SI ARIA DE DESFĂȘURARE A CAMPANIEI
      </strong>

    </h3>
    <p>
      <strong>Campania</strong>
      {' '}
      „Carfix premiază fidelitatea” va fi organizata și se va desfășura în perioada
      {' '}
      <strong>
        08.03.2022– 08.06.2022 (denumita in continuare ”Durata Campaniei”)
      </strong>
      {' '}
      la nivel national, in conformitate cu prevederile prezentului Regulament, iar extragerea Castigatorului va fi realizata
      in data de 10.06.2022.
    </p>
    <p>
      Organizatorul isi rezerva dreptul de a modifica perioada si sistemul de functionare al Campaniei pe parcursul derularii
      acesteia, dar nu inainte de a anunta acest lucru public, conform prevederilor de mai sus.
    </p>
    <p>
      Campania se desfasoara in mediul online, fiind adusa la cunostinta participantilor prin intermediul website-ului
      www.carfixapp.com dar si pe paginile Organizatorului, deschise pe urmatoarele retele de socializare: Facebook - @Carfix.Romania,
      respectiv Instagram - @carfix.app si presupune, printre altele, descarcarea Aplicatiei CARFIX (denumita in continuare si
      “Aplicatia”), astfel cum este detaliat in cele de mai jos.
    </p>
    <h3>
      <strong>
        Art. 3. DREPTUL DE PARTICIPARE
      </strong>
    </h3>
    <p>
      In Campanie au dreptul sa participe toate persoanele fizice cu domiciliul/ resedinta (chiar temporara) in Romania, cu varsta
      minima de 18 ani impliniti la data inscrierii in Campanie, care respecta termenii si conditiile prezentului Regulament
      (denumite in continuare individual
      {' '}
      <strong>“Participant”</strong>
      {' '}
      si colectiv
      {' '}
      <strong>“Participanti”</strong>
      ).
    </p>

    <p>
      Participarea la prezenta Campanie are valoare de acceptare integrală şi liber consimţită a prevederilor prezentului Regulament.
      Dreptul de participare aparţine în exclusivitate persoanei fizice care îndeplineşte condiţiile impuse de Regulament, detaliate
      mai jos.
    </p>
    <p>
      Nu au dreptul de a participa la Campanie angajatii Organizatorului, ai companiilor distribuitoare ale Organizatorului, precum
      si membrii familiilor tuturor acestora (copii, parinti, frati/surori, sot/sotie).
    </p>
    <p>
      Daca sunt identificate persoane care au influentat sau care au facilitat castigarea Concursului, incalcand prevederile
      prezentului Regulament Oficial, Organizatorul are dreptul de a cere restituirea obiectului castigat si de a actiona conform
      dispozitiilor legale in vigoare in vederea recuperarii eventualelor prejudicii cauzate.
    </p>
    <p>
      Organizatorul isi rezerva dreptul de a anula orice participare la Concurs care se efectueaza cu incalcarea prezentului
      Regulament Oficial.
    </p>
    <h3>
      <strong>
        Art. 4. MODUL DE DESFĂȘURARE A CAMPANIEI
      </strong>
    </h3>
    <h4>
      4.1. Inscrierea in Campanie se realizeaza voluntar si in mod gratuit.
    </h4>
    <p>
      Pentru a participa la Campania „Carfix premiază fidelitatea” si a fi eligibil pentru castigarea Premiului - un autoturism
      marca Dacia Sandero Stepway Comfort TCE 90, Participantii trebuie utilizeze in mod activ aplicatia CARFIX, astfel:
      <br />
      <br />
      1. Utilizatorii trebuie:
      <br />
      <br />
      • Sa descarce gratuit aplicatia CARFIX din magazinele Google Play sau App Store, Huawei, daca nu o au deja instalată
      <br />
      <br />
      • Sa isi creeze cont de utilizator in aplicatia CARFIX prin parcurgerea pasilor necesari si sa-l activeze prin accesarea
      link-ului din mesajul primit prin email
      <br />
      <br />
      • Sa completeze datele personale in mod complet conform cerintelor pentru crearea contului de utilizator (inlcusiv numarul
      de telefon);
      <br />
      <br />
      • Sa isi exprime consimtamantul pentru a participa la concurs (prin bifarea casutei specifice „Mă înscriu la concurs”)
      <br />
      <br />
      • Sa adauge cel putin o masina in sectiunea „Garajul meu” din Aplicatie;
      <br />
      <br />
      • Sa-și exprime opțiunea de a se înscriere in campanie, prin aplicație, in pagina dedicata acesteia,urmând instrucțiunile
      de pe ecran
      <br />
      <br />
      • Sa indeplineasca criteriul de eligibiliate pentru ridicarea Premiului – In acest sens, Participantul trebuie sa faca
      dovada dreptului de folosinta a autoturimului adaugat in cadrul sectiunii “Garajul meu”, conform celor de mai sus.
      Dreptul de folosinta presupune faptul ca Participantul se afla in una dintre urmatoarele situatii: detine autoturismul
      inregistrat in aplicatie (proprietate personala), autoturismul este proprietatea unei rude de gradul 1 sau gradul 2,
      autoturismul este folosit prin achizitie in sistem leasing sau credit auto, folosinta autoturismului a fost dobandita
      in virtutea unei relatii de munca active si incredintat de angajator pentru prestarea activitatilor profesionale
      <br />
      <br />
      2. In situatia in care Participantul are deja instalata aplicatia CARFIX, contul acestuia trebuie sa fie activ. Ultimele 3
      conditii prevazute la pct. 1 de mai sus trebuie indeplinite si in situatia unui Participant care este deja utilizator al
      Aplicatiei.
      <br />
      <br />
      Astfel cum a fost mentionat, in ambele situatii de mai sus, criteriul de eligibilitate pentru acordarea Premiului consta in
      posibilitatea Castigatorului de a demonstra Organizatorului faptul ca detine un drept de folosinta asupra masinii introduse
      in sectiunea „Garajul meu” din Aplicatie. Astfel, in situatia in care Castigatorul desemnat prin tragerea la sorti electronica
      nu poate dovedi faptul ca acesta are cel putin un drept legitim de a utiliza masina din „Garajul meu”, acesta va fi invalidat
      pentru acordarea Premiului, reluandu-se procedura de tragerea la sorti electronica.
      <br />
      <br />
      Fiecare Participant este unic si se poate inscrie o singura data in Campanie. Un Participant este individualizat prin contul
      creat in Aplicatie odata cu introducerea datelor sale cu caracter personal si apasarea butonului „Mă înscriu la concurs”
      din sectiunea dedicată campaniei.
      <br />
      <br />
      Introducerea de catre Participant a mai multor masini in sectiunea „Garajul meu” nu conduce la cresterea sanselor de castig.
      <br />
      <br />
      Inscierea valida in cadrul Campaniei presupune ca Participantul sa parcurga intreaga procedura de inscriere conform dispozitiilor
      prezentului Regulament. Omiterea unei etape/ cerinte dintre cele descrise anterior echivaleaza cu nerespectarea procedurii de
      inscriere in Campanie si constituie motiv de invalidare a inscrierii.
    </p>
    <h4>
      4.2. Desemnarea Castigatorului
    </h4>
    <p>
      Un Participant va putea fi desemnat castigator o singura data pe durata Campaniei
    </p>
    <p>
      Toti participantii vor fi centralizati in cadrul unui registru. Fiecarui Participant i se va atribui un numar in ordinea
      inscrierii in Campanie. Prin sistemul de tragere la sorti electronica oferit de platforma www.random.org, Organizatorul
      va introduce numarul total al Participantilor, iar sistemul va genera in mod aleatoriu un singur numar castigator ce
      corespunde Participantului inscris conform registrului detinut de Organizator.
    </p>
    <p>
      Castigatorul va fi desemnat in mod aleatoriu, prin tragere la sorti electronica (prin mecanismul random.org sau alte mecanisme
      similare) dintre toti Participantii cu inscrieri valide. In cadrul mecanismului de tragere la sorti vor fi introdusi toti
      Participantii care indeplinesc conditiile de inscriere, conform celor de mai sus, iar mecanismul utilizat va selecta, fara
      interventie umana, un singur Castigator.
    </p>
    <p>
      Tragerea la sorti electronica va avea loc sub supravegherea unei Comisii de Etica, ce va valida Castigatorul prin raportare
      la indeplinirea de catre acesta a tuturor prevederilor prezentului Regulament si a conditiilor de participare la Campanie.
    </p>
    <p>
      Dupa finalizarea procesului de tragere la sorti electronica, Organizatorul se va asigura ca Participantul castigator
      indeplineste toate conditiile de participare la Campanie si criteriul de eligibilitate. Organizatorul va valida extragerea
      anterior anuntarii publice a Castigatorului.
    </p>
    <p>
      Validarea castigatorului consta in verificarea conditiilor de inscriere si a criteriului de eligibilitate. Pentru a indeplini
      criteriul de eligibilitate, Castigatorul desemnat trebuie sa faca dovada dreptului de folosinta a autoturismului adaugat in
      cadrul sectiunii „Garajul meu” din aplicatia CARFIX, conform celor de mai sus.
    </p>
    <p>
      Dovada poate fi realizata prin orice mijloc veridic, spre exemplu:
      <br />
      &nbsp;&nbsp;&nbsp;a) documente ce atesta faptul ca masina este in proprietatea Castigatorului sau a unei rude gradul sau gradul 2;
      <br />
      &nbsp;&nbsp;&nbsp;b) documente ce atesta faptul ca masina reprezinta o achizitie prin sistem leasing sau credit auto;
      <br />
      &nbsp;&nbsp;&nbsp;c) documente ce atesta ca masina este dobandinta prin beneficiu de la angajator;
      <br />
      &nbsp;&nbsp;&nbsp;d) Alte documente oficiale care atesta dreptul de utilizare ale cel putin unui autoturism salvat in dectiunea Garaj a aplicației.
    </p>
    <h4>
      4.3. Acordarea Premiilor. Intrarea in posesia Premiului
    </h4>
    <p>
      Dupa verificarea criteriului conform celor de mai sus, Castigatorul validat va fi anuntat pe website-ul www.carfixapp.com
      sectiunea „Concurs”. Câștigătorul va fi contactat via e-mail si/ sau telefonic in termen de 3 zile din momentul validarii
      Castigatorului si al publicarii anuntului referitor la Câștigător pe website-ul www.carfixapp.com, de către un reprezentant
      al Organizatorului pentru a stabili detalii privind acordarea si intrarea in posesie a Premiului. In functie de premiul castigat,
      acesta poate fi ridicat personal de Castigator de la sediul Organizatorului din Bucuresti, Sector 1, Nicolae Caramfil nr 71-73,
      ART BC 5, corp A, etaj 4 sau poate fi transmis prin e-mail catre adresa Castigatorului. Dovada identitatii Castigatorului se
      va face prin prezentarea unui act de identificare.
    </p>
    <h4>
      4.4. Imposibilitatea acordarii/ ridicarii Premiului
    </h4>
    <p>
      Organizatorul nu acorda contravaloarea Premiului in bani.
    </p>
    <p>
      In cazul refuzului Castigatorului de a intra in posesia Premiului (refuzul unui Castigator insemnand ca acesta nu doreste
      sa intre in posesia Premiului, iar refuzul este manifestat fata de Organizator in mod expres si neechivoc) sau in cazul
      imposibilitatii validarii Castigatorului conform prezentului Regulament, Castigatorul va pierde dreptul de atribuire a
      Premiului fara nicio despagubire din partea Organizatorului.
    </p>
    {' '}
    <p>
      Daca Participantul castigator (1) nu poate fi contactat din motive ce nu tin de culpa Organizatorului (ex: nu se ofera un
      raspuns la mesajul transmis, numarul de telefon nu este alocat, este gresit etc.) sau
    </p>
    {' '}
    <p>
      Castigatorul nu si-a revendicat sau refuza Premiul sau (3) nu se respecta conditiile Regulamentului sau termenul precizat de
      Organizator pentru intrarea in posesia Premiului, in termen de 30 zile calendaristice din momentul ultimei notificari trimise,
      acesta este invalidat si va pierde orice drept asupra Premiului castigat, urmand ca Premiul sa ramana la dispozitia
      Organizatorului.
    </p>
    {' '}
    <p>
      In cazul refuzului unui Castigator de a intra in posesia Premiului, Organizatorul va putea decide reluarea tragerii la sorti
      eletronice pe baza registrului centralizat al Participantilor inscrisi la Campanie.
    </p>
    {' '}
    <p>
      Castigatorul are un termen de 30 zile pentru intrarea in posesia Premiului castigat.
    </p>
    <h3><strong>Art. 5. PROTECTIA DATELOR CU CARACTER PERSONAL</strong></h3>
    <p>
      Prin prezentul Regulament Oficial, Organizatorul Campaniei, in calitate de Operator de date cu caracter personal, se obliga sa
      respecte prevederile Regulamentului nr. 679/2016 (Regulamentul General pentru Protectia Datelor, denumit in continuare si
      „GDPR”), ale Legii 190/2018 privind aplicarea acestui Regulament, dar si bunele practici in materie de prelucrare a datelor
      in scopuri de marketing.
    </p>
    <p>
      Protectia datelor cu caracter personal reprezinta un element – cheie in activitatea Organizatorului. De aceea, acesta garanteaza
      confidentialitatea datelor personale ale tuturor Participantilor, avand implementate toate masurile tehnice si organizatorice
      necesare in scopul efectuarii unei prelucrari de date care sa respecte normativele aplicabile la nivel european si national
      in aceasta materie.
    </p>
    <p>
      Fiecare angajat al Organizatorului, care colecteaza date in vederea derularii de concursuri este instruit expres sa informeze
      persoanele vizate cu privire la drepturile acestora in legatura cu prelucrarea datelor personale, dar si sa limiteze accesul
      la date in general.
    </p>
    <p>
      Datele cu caracter personal colectate in cadrul campaniei si care sunt prelucrate atat de Organizator, cat nu vor fi dezvaluite
      altor destinatari, cu exceptia angajatilor acestora care, prin prisma atributiilor detinute, realizeaza prelucrari ale acestor
      date.
    </p>
    <p>
      Participantul intelege si accepta faptul ca, pentru extragerea castigatorului, Organizatorul va putea utiliza o platforma
      de tipul random.org/ alt mecanism similar ce presupune o extragere aleatorie. In acest caz, datele Participantului ce ar
      putea face obiectul unei dezvaluiri catre detinatorul mecanismului de extragere vor fi sub incidenta Politicii de
      Confidentialitate a mecanismului pentru care se opteaza. Organizatorul se va asigura de faptul ca platforma pentru alegerea
      castigatorului respecta cerintele legale in materie de protectie a datelor.
    </p>
    <p>
      Castigatorul intelege si accepta faptul ca, pentru intrarea in posesia obiectului castigat, alocat in baza prezentului
      Regulament, acesta va furniza Organizatorului o serie de date personale precum: nume, prenume, adresa, numar de telefon,
      adresa de e-mail etc. In cazul in care Castigatorul nu este de acord cu furnizarea acestor informatii, acesta nu va putea
      revendica obiectul castigat.
    </p>
    <p>
      Castigatorul intelege si accepta faptul ca Organizatorul va colecta strict informatiile necesare acordarii si validarii
      premiului, precum si eventualelor verificari ale organelor fiscale privind legalitatea campaniei. Aceste informatii sunt
      limitate la data participarii, nume si prenume, adresa la care a fost/ va fi livrat Premiul, adresa de e-mail, CNP,
      Organizatorul necolectand date ce nu sunt necesare scopului colectarii.
    </p>
    <p>
      Operatorul de date colecteaza datele furnizate in mod voluntar de Participant si va asigura un acces limitat la acestea
      strict pentru personalul propriu cu atributii specifice si/sau al entitatilor partenere.
    </p>
    <p>
      Temeiul juridic al acestei prelucrari este reprezentat de interesul legitim al Organizatorului.
    </p>
    <p>
      Datele cu caracter personal sunt prelucrate exclusiv pentru realizarea campaniei si pentru inmanarea ulterioara a premiului
      si nu in alte scopuri viitoare de marketing sau comerciale, realizate prin comunicari nesolicitate de catre utilizatori,
      exceptand situatiile in care Participanul este de acord cu a primi informari de marketing pe adresa de e-mail introdusa.
    </p>
    <p>
      In desfasurarea activitatilor Organizatorului si pentru indeplinirea scopurilor mentionate mai sus, Organizatorul colaboreaza
      cu diversi furnizori de servicii sau parteneri in baza unor relatii contractuale, carora le pot fi divulgate datele dumneavoastra
      cu caracter personal pentru a fi folosite strict in scopurile stabilite de Organizator.
    </p>
    <p>
      Pentru indeplinirea scopului de organizare și defășurare a Campaniei, datele Participantilor pot fi dezvaluite imputernicitilor
      care prelucreaza date pe seama Organizatorului, sub-imputernicitilor (entitati sub-contractate de imputerniciti pentru
      prelucrarea datelor), parteneri furnizori de servicii si sub-contractanti ai acestora, autoritati publice etc.
    </p>
    <p>
      Datele introduse de Participant vor fi solicitate si prelucrate doar pentru acordarea Premiului, dar si pentru evidenta
      fiscala interna. Daca Participantul opteaza sa se inscrie la newsletterele Organizatorului si va opta pentru primirea de
      comunicari comerciale si de marketing, vom retine si adresa de e-mail a acestuia. Adresa de e-mail, ca data cu caracter
      personal, va fi prelucrata pana la retragerea consimtamantului de prelucrare, pe care Participantul l-a acordat in acest scop.
      In cazul in care Participantul nu este de acord sa primeasca informari de marketing si nu opteaza in acest sens, toate datele
      acestuia vor fi sterse din baza de date in decurs de maxim 90 zile de la inscriere.
    </p>
    <p>Organizatorul	garanteaza	confidentialitatea	datelor	personale	ale	tuturor	Participantilor.</p>
    <p>
      Participantul la Campanie, in calitate de persoana vizata, are urmatoarele drepturi:
      <br />
      &nbsp;&nbsp;&nbsp;a) dreptul de a solicita in scris o confirmare a faptului ca datele sale cu caracter personal sunt prelucrate (dreptul de acces,
      articolul 15 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;b) dreptul de a solicita rectificarea datelor inexacte sau completarea datelor incomplete (dreptul de rectificare, art. 16 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;c) dreptul de a solicita stergerea datelor cu caracter personal si, in cazul in care datele cu caracter personal au fost facute
      publice, transmiterea informatiilor referitoare la solicitarea de stergere catre alti operatori (dreptul de stergere,
      articolul 17 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;d) dreptul de a solicita restrictionarea prelucrarii datelor (dreptul la restrictionarea prelucrarii, articolul 18 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;e) de a solicita transferul datelor catre alt operator (dreptul la portabilitate, articolul 20 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;f) dreptul de a se opune prelucrarii datelor cu intentia de a inceta prelucrarea (dreptul la opozitie, articolul 21 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;g) dreptul de a depune o plangere la o autoritate de supraveghere in cazul in care considera ca drepturile sale au fost
      incalcate (articolul 77 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;h) dreptul de a exercita o cale de atac impotriva unei decizii a unei autoritati de supraveghere (articolul 78 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;i) dreptul de a se adresa unei instante judecatoresti competente, in cazul in care considera ca drepturile sale au fost
      incalcate (articolul 79 GDPR);
      <br />
      &nbsp;&nbsp;&nbsp;j) dreptul de a obtine despagubiri de la operator, in cazul in care considera ca drepturile sale derivate din prelucrarea
      datelor prin intermediul masurilor implementate au fost incalcate (articolul 82 GDPR).
    </p>
    <p>
      Prelucrarea datelor in alte scopuri decat cele prevazute in Regulament va putea opera doar prin intermediul unui acord incheiat
      distinct intre Operator si Participant.
    </p>
    <p>
      Perioada de stocare a datelor cu caracter personal:
      <br />
      &nbsp;&nbsp;&nbsp;a) Datele cu caracter personal ale Participantilor declarati necastigatori vor fi stocate conform prevederilor Politicii de
      confidentialiate disponibila in Aplicatia CARFIX.
      <br />
      &nbsp;&nbsp;&nbsp;b) Datele cu caracter personal ale Castigatorului vor fi stocate timp de 10 ani de la incheierea Campaniei in conformitate cu
      prevederile legislative in vigoare;
      <br />
      &nbsp;&nbsp;&nbsp;c) La expirarea perioadei de stocare a datelor cu caracter personal, Operatorul va sterge/ distruge aceste date de pe
      suporturile de prelucrare si stocare a acestora.
    </p>
    <p>
      In cazul in care exista intrebari legate de prezenta sectiune ori de prelucrarile de date aferente Concursului, persoana vizata
      este rugata sa transmita o solicitare scrisa la adresa de e-mail: contact@carfixapp.com sau in scris, prin posta, la adresa
      de corespondenta: Mun. București, Sector 1, Nicolae Caramfil nr 71-73, ART BC 5, corp A, etaj 4,.
    </p>
    <p>
      Persoanele care transmit astfel de solicitari catre Organizator sunt rugate sa mentioneze in subiectul e-mailului informatii
      precum “protectia datelor”/ „prelucrare date Campanie”, in acest mod existand garantia ca solicitarile vor fi tratate cu
      prioritate.
    </p>
    <p>
      Persoana vizata va primi un raspuns in termen de 15 de zile de la comunicarea solicitarii catre Operator, o extindere a
      acestui termen putand opera numai in situatii exceptionale. In aceste cazuri, persoana vizata va fi informata in mod
      corespunzator cu privire la acest termen.
    </p>
    <h3>
      <strong>
        Art. 6. PREMIII
      </strong>
    </h3>
    <p>
      In cadrul Campaniei se vor acorda urmatoarele Premii:
      <br />
      • Premiul nr. 1: Automobilul Dacia Sandero Stepway
      <br />
      &nbsp;&nbsp;&nbsp;- Marca: Dacia Logan Sandero Stepway Confort TCE 90;
      <br />
      &nbsp;&nbsp;&nbsp;- Culoare: alb glacier;
      <br />
      &nbsp;&nbsp;&nbsp;- Marcă:	Dacia
      <br />
      &nbsp;&nbsp;&nbsp;- Model:	Sandero Stepway
      <br />
      &nbsp;&nbsp;&nbsp;- Nivel de echipare:	COMFORT
      <br />
      &nbsp;&nbsp;&nbsp;- Motorizare:	1.0 TCE
      <br />
      &nbsp;&nbsp;&nbsp;- Combustibil:	Benzina
      <br />
      &nbsp;&nbsp;&nbsp;- Putere maxima:	90 CP
      <br />
      &nbsp;&nbsp;&nbsp;-  Transmisie: Manuala cu 6 trepte
      <br />
      &nbsp;&nbsp;&nbsp;- Volum portbagaj:  328 l
      <br />
      Dotări incluse:
      <br />
      DESIGN
      <br />
      &nbsp;&nbsp;&nbsp;- Faruri de zi (DRL) LED tip semnătură luminoasa "Y"
      <br />
      &nbsp;&nbsp;&nbsp;- Faruri "eco-LED" (faza scurta LED - faza lunga halogen)
      <br />
      &nbsp;&nbsp;&nbsp;- Bare de pavilion modulabile (culoare gris quartz)
      <br />
      &nbsp;&nbsp;&nbsp;- Carcase oglinzi retrovizoare in culoarea caroseriei o Tapițerie textila specifica "Stepway"
      <br />
      POST DE CONDUCERE
      <br />
      &nbsp;&nbsp;&nbsp;- Direcție asistata electric o Computer de bord
      <br />
      &nbsp;&nbsp;&nbsp;- Aer condiționat cu reglare automata
      <br />
      &nbsp;&nbsp;&nbsp;- Volan reglabil pe înălțime si adâncime, îmbrăcat in piele
      <br />
      &nbsp;&nbsp;&nbsp;- Oglinzi retrovizoare reglabile electric, cu sistem de dejivrare o Faruri cu aprindere automata
      <br />
      &nbsp;&nbsp;&nbsp;- Ștergătoare cu senzor de ploaie
      <br />
      CONFORT
      <br />
      &nbsp;&nbsp;&nbsp;- Scaun șofer reglabil pe înălțime, cu cotieră integrate o Bancheta spate rabatabila 1/3-2/3
      <br />
      &nbsp;&nbsp;&nbsp;- Geamuri electrice fata + spate (cu impuls pe partea șoferului)
      <br />
      &nbsp;&nbsp;&nbsp;- Media Display: radio DAB, ecran tactil 8‘’ (fără sistem navigație), funcție replicare smartphone, comenzi pe volan & satelit, Bluetooth® (audio streaming & handsfree), 4 difuzoare
      <br />
      SIGURANTA SI SISTEME DE ASISTENTA
      <br />
      &nbsp;&nbsp;&nbsp;- ESP (sistem de stabilitate electronica a autovehiculului) + HSA (sistem de asistenta a pornirii in rampa)
      <br />
      &nbsp;&nbsp;&nbsp;- Sistem de frânare de urgenta automat o Airbag-uri frontale, laterale, cortina
      <br />
      &nbsp;&nbsp;&nbsp;- Sistem de monitorizare presiune pneuri o Cruise control
      <br />
      &nbsp;&nbsp;&nbsp;- Proiectoare de ceata
      <br />
      &nbsp;&nbsp;&nbsp;- Sistem de prindere ISOFIX pentru locurile laterale spate o Apel de urgenta automat 112
      <br />
      <br />
      Valoarea comerciala a Premiului neta individuala exprimata in RON este in cuantum de 51816,94.
      <br />
      Valoare comerciala neta a Premiului totala exprimata in RON cu TVA inclus este de: 61662.
      <br />
      Participantilor la Campanie nu le sunt impuse niciun fel de cheltuieli suplimentare de participare, cu exceptia tarifului normal de conexiune la Internet.
      <br />
      Pentru a intra in posesia Premiului, Castigatorul va trebui sa se prezinte la sediul societatii mai sus descris in maximum 30 de zile de la publicarea numelui Castigatorului la sectiunea „Campanii” de pe website-ul www.carfixapp.com respectiv de la contactarea acestuia de catre un reprezentant al Organizatorului.
      <br />
      Extragerea pentru acordarea acestui Premiu are loc in data de  10.06.2022
      <br />
      <br />
      • Premiul nr. 2: un numar de 12 vouchere in valoare de 300 RON pentru achizitionarea de combustibil de la benzinariile OMV sau Petrom. Extragerea voucherelor
      are loc saptamanal pe toata durata de desfasurare a Campaniei. Un voucher va fi atribuit unui singur castigator. La fiecare extragere saptamanala, voucherul
      va fi atribuit unui castigator diferit; in acest sens, neexistand posibilitatea ca un Participant sa fie extras de mai multe ore, ci doar o singura data.
      <br />
      <br />
      • Premiul nr. 3: un numar de 3 vouchere inchiriere auto de weekend clasa E (autoturism Skoda Octavia sau unul similar) – voucherul ofera posibilitatea
      Castigatorului de a inchiria autoturismul in intervalul cuprins in timpul weekend-ului, respectiv: vineri ora 15:00 pana luni la ora 10:00. Pe perioada
      închirierii, Castigatorul voucherului se supune Termenilor si Condițiilor generale de închiriere disponibile aici:
      www.autonom.ro/termeni-si-conditii-inchiriere-auto. Serviciile sunt valabile doar pe teritoriul României. Serviciile de inchiere a masinii sunt oferite de
      Autonom Services SA.
      <br />
      &nbsp;&nbsp;&nbsp;- Valoarea serviciilor: 747 EUR cu TVA inclus;
      <br />
      &nbsp;&nbsp;&nbsp;- Extragerea celor 3 vouchere are loc in data de 10.06.2022 Un voucher va fi atribuit unui singur castigator; in acest sens, neexistand posibilitatea ca un Participant sa fie extras de mai multe ore, ci doar o singura data.
      <br />
      &nbsp;&nbsp;&nbsp;- In momentul in care Castigatorul doreste sa utilizeze voucherul, acesta va transmite un mesaj catre adresa de e-mail: rezervari@autonom.ro mentionand urmatoarele detalii: perioada (datele calendaristice), masina si clasa dorita. Castigatorul va atasa voucherul la e-mailul transmis rezervari@autonom.ro, in caz contrar nu se va putea efectua rezervarea pentru inchirierea masinii.
      <br />
      &nbsp;&nbsp;&nbsp;- In voucherul pe care il vom emite si care va fi transmis câștigătorului pe email vom detalia acest flux si conditiile de acordare. “
      <br />
      <br />
      • Premiul nr. 4: un numar de 36 vouchere pentru servicii de asistenta rutiera in caz de accident, respectiv voucherul consta in abonamentul basic Asistenta
      Rutiera. Serviciile sunt valabile doar pe teritoriul României si se supun termenilor si conditiilor de furnizare ale serviciului:
      www.asistentarutiera.ro/termeni-si-conditii. Extragerea are loc saptamanal pe toata perioada Campaniei, fiind desemnati 3 castigatori. Un voucher va fi
      atribuit unui singur castigator. La fiecare extragere saptamanala, voucherul va fi atribuit unui castigator diferit; in acest sens, neexistand posibilitatea
      ca un Participant sa fie extras de mai multe ore, ci doar o singura data. Abonamentul este oferit de catre Autonom Services S.A. si include include:
      <br />
      &nbsp;&nbsp;&nbsp;- Asistenta Rutiera 24/7;
      <br />
      &nbsp;&nbsp;&nbsp;- Tractare in caz de accident imobilizator pe intreg teritoriul României;
      <br />
      &nbsp;&nbsp;&nbsp;- Transportul autoturismului avariat pana la service-ul ales, fara limita de km;
      <br />
      &nbsp;&nbsp;&nbsp;- Recuperarea gratuita a masinii avariate din afara carosabilului;
      <br />
      &nbsp;&nbsp;&nbsp;- Depozitarea fara costuri a masinii avariate pentru maximum 3 zile calendaristice;
      <br />
      &nbsp;&nbsp;&nbsp;- Numar de evenimente nelimitate pe toata durata valabilitatii.;
      <br />
      &nbsp;&nbsp;&nbsp;- Valoarea totala a serviciilor: 3564 lei cu TVA inclus.
    </p>
    <h3>
      <strong>
        Art. 7. LIMITELE RĂSPUNDERII ORGANIZATORULUI
      </strong>
    </h3>
    <p>
      Prin participarea la Concurs, toti Participantii sunt de acord si se obliga sa respecte si sa se conformeze prevederilor prezentului Regulament Oficial,
      precum si tuturor cerintelor si deciziilor luate de Organizatorul principal legate de implementarea prezentului Regulament.
    </p>
    <p>
      Organizatorul isi rezerva dreptul de a exclude definitiv orice Participant care, prin comportamentul fraudulos, afecteaza bunul mers al Concursului sau
      renumele Organizatorului precum si imaginea Produselor.
    </p>
    <p>
      Organizatorul nu are nicio obligatie de a intretine corespondenta cu solicitantii unor revendicari ce apar ulterior acordarii efective a Premiului/incetarii
      Campaniei, exceptie facand situatiile concrete in care persoanele interesate, in calitatea lor de persoane vizate, isi exercita in limitele legale drepturile
      prevazute de legislatia aplicabila in materie de protectie a datelor cu caracter personal. Responsabilitatea Organizatorului cu privire la acordarea premiului
      este limitata in conformitate cu prevederile prezentului Regulament Oficial.
    </p>
    <p>
      Prin participarea la Concurs si parcurgerea Regulamentului Oficial, Participantul declara pe propria raspundere ca a luat cunostinta de continutul acestui
      document si este de acord cu urmatoarele:
      <br />
      &nbsp;&nbsp;&nbsp;a) este singurul raspunzator pentru orice declaratie neconforma cu realitatea (in special cu privire la orice date personale furnizate voluntar);
      <br />
      &nbsp;&nbsp;&nbsp;b) este informat cu privire la prelucrarea datelor cu caracter personal prelucrate in vederea derularii campaniei.
    </p>
    <p>
      Organizatorul:
      <br />
      &nbsp;&nbsp;&nbsp;a) nu este raspunzator pentru deteriorarea, disparitia sau sustragerea Premiului castigat de Participant dupa momentul preluarii lor de catre acesta din urma;
      <br />
      &nbsp;&nbsp;&nbsp;b) nu este raspunzator de eventualele prejudicii sau daune cauzate de catre Castigatori unor terti in legatura cu obiectele castigate, indiferent de natura
      acestor prejudicii;
      <br />
      &nbsp;&nbsp;&nbsp;c) este exonerat de catre Castigatori, acordul de vointa al acestora fiind dat prin simpla participare la prezenta Campanie, de orice raspundere pentru toate
      prejudiciile suferite de catre Castigatori in legatura cu Premiile, ulterior momentului preluarii acestora, indiferent de natura acestor prejudicii, incluzand
      dar fara a se limita la, vatamari corporale si/sau vatamari ale sanatatii, respectiv vatamari sau daune aduse patrimoniilor.
    </p>
    <p>
      Organizatorul este indeptatit sa ia toate masurile necesare in scopul prevenirii sau sanctionarii oricarui caz de frauda, abuz, tentativa de frauda, sau a
      oricaror alte activitati care ar putea afecta desfasurarea, imaginea sau costurile acestei campanii sau care ar putea duce la incalcarea drepturilor tertilor.
    </p>
    <p>
      Organizatorul Campaniei va acorda Premiul in conformitate cu prevederile prezentului Regulament, in limita stocului disponibil.
    </p>
    <p>
      Organizatorul Campaniei nu își asumă răspunderea, incluzând, dar fără a se limita la, urmatoarele:
      <br />
      &nbsp;&nbsp;&nbsp;a) Răspunderea de a remedia viciile, de orice natură, pe care le-ar putea avea produsul ce constituie Premiul Campaniei;
      <br />
      &nbsp;&nbsp;&nbsp;b) Câștigătorul premiului nu poate opta pentru primirea contravalorii premiului în bani sau în alte obiecte si nici nu pot solicita modificarea
      caracteristicilor tehnice ale produsului;
      <br />
      &nbsp;&nbsp;&nbsp;c) Situatiile in care Participantii inscriu pe pagina web un numar de telefon incorect sau o adresa de e-mail gresita.
    </p>
    <p>
      Organizatorul nu isi asuma responsabilitatea si nu va fi numit parte in cazurile referitoare la dreptul de proprietate asupra numarului de telefon
      declarat in momentul inscrierii si va acorda Premiul persoanei care parcurge mecanismul de inscriere si validare conform Regulamentului.
    </p>
    <p>
      Organizatorul nu isi asuma responsabilitatea pentru:
      <br />
      &nbsp;&nbsp;&nbsp;a) Pierderile, intarzierile sau intreruperile neanuntate ale serviciului Internet, generate de retelele de telefonie mobila sau de oricare alte
      defectiuni sau probleme tehnice independente de vointa Organizatorului;
      <br />
      &nbsp;&nbsp;&nbsp;b) Situatiile in care Participantii nu indeplinesc toate conditiile pentru a intra in posesia Premiului;
      <br />
      &nbsp;&nbsp;&nbsp;c) Situatiile in care Participantii inscriu datele personale incorect.
    </p>
    <h3>
      <strong>
        Art. 8 INCETAREA CAMPANIEI INAINTE DE TERMEN
      </strong>
    </h3>
    <p>
      Campania poate inceta inainte de termen doar in caz de forta majora, caz fortuit, precum si in cazul imposibilitatii Organizatorului, din motive
      independente de vointa lui, de a continua Campania organizata in perioada precizata in prezentul Regulament. Incetarea Campaniei inainte de termen
      va fi facuta publica de Organizator.
    </p>
    <h3>
      <strong>
        Art. 9. FORTA MAJORA SI CAZUL FORTUIT
      </strong>
    </h3>
    <p>
      In contextul prezentului Regulament, forta majora inseamna orice eveniment care nu poate fi controlat, remediat sau prevazut de catre Organizator,
      a carui aparitie il pune pe acesta din urma in imposibilitatea de a-si indeplini obligatiile asumate prin prezentul Regulament.
    </p>
    <p>
      Cazul fortuit este definit ca fiind un eveniment care nu poate fi prevazut si nici impiedicat de catre cel care ar fi fost chemat sa raspunda daca
      evenimentul nu s-ar fi produs.
    </p>
    <p>
      Daca o situatie de forta majora sau un caz fortuit impiedica sau intarzie, total sau partial, executarea Regulamentului si continuarea Campaniei,
      Organizatoriul va fi exonerat de raspunderea privind indeplinirea obligatiilor acestora pentru perioada in care aceasta indeplinire va fi impiedicata
      sau intarziata.
    </p>
    <p>
      In intelesul prezentului Regulament, constituie cazuri de forta majora sau caz fortuit urmatoarele evenimente:
      <br />
      &nbsp;&nbsp;&nbsp;a) tentative de frauda a Campaniei prin orice mijloace;
      <br />
      &nbsp;&nbsp;&nbsp;b) orice reglementare care poate aparea ulterior intrarii in vigoare a Regulamentului Oficial si care poate interzice sau modifica termenii acestuia;
      <br />
      &nbsp;&nbsp;&nbsp;c) eventuale situatii politice, sociale, sanitare sau de orice alta natura care pot conduce la imposibilitatea desfasurarii prezentei Campanii.
    </p>
    <p>
      Enumerarea din paragraful de mai sus nu este limitativa, fiind utilizata numai in scop exemplificativ.
    </p>
    <h3>
      <strong>
        Art. 10. TAXE SI IMPOZITE
      </strong>
    </h3>
    <p>
      Premiul Campaniei este acordat in contextul desfasurarii activitatii economice a Organizatorului si reprezinta cheltuieli de reclama si publicitate
      efectuate in scopul popularizarii produselor si serviciilor sale si sunt considerate cheltuieli deductibile in conformitate cu prevederile art. 25 alin.
    </p>
    <p>
      (1) din Legea nr. 227/2015 privind Codul Fiscal (denumita in continuare „Codul Fiscal„).
    </p>
    <p>
      Organizatorul se obliga sa calculeze, sa retina, sa declare si sa vireze impozitul datorat pentru veniturile constand in Premiile acordate
      Castigatorilor in conformitate cu prevederile art. 110 din Codul Fiscal aprobat prin Legea nr. 227/2015, cu modificarile si completarile ulterioare
      , iar orice alte obligatii de orice alta natura, in legatura cu acestea, fiind in sarcina exclusiva a Castigatorului.
    </p>
    <p>
      Participantilor nu le sunt impuse niciun fel de cheltuieli directe sau indirecte suplimentare, cu exceptia cheltuielilor normale de desfasurare a
      Campaniei (contravaloarea accesului la Internet pentru inscrierea la Campanie si utilizarea Aplicatiei).
    </p>
    <h3>
      <strong>
        Art. 11. LITIGII
      </strong>
    </h3>
    <p>
      Eventualele litigii aparute intre Organizator si Participantii la prezenta Campanie se vor rezolva pe cale amiabila sau in cazul in care aceasta nu
      va fi posibila, litigiile vor fi solutionate de instantele judecatoresti competente material din Mun. Bucuresti, de la sediul Organizatorului.
    </p>
    <p>
      Eventualele reclamatii legate de derularea Campaniei se vor putea trimite catre Organizator la urmatoarea adresa de e-mail: contact@carfixapp.com in
      termen de maximum o saptamana de la data incheierii Campaniei. Dupa aceasta data, Organizatorul nu va mai lua in consideratie nicio contestatie.
    </p>
    <p>
      Eventualele contestatii vor cuprinde in mod obligatoriu: numele, domiciliul/resedinta, precum si alte date necesare pentru identificarea contestatorului,
      prezentarea motivelor de fapt pe care se intemeiaza contestatia, precum si orice alte relevante.
    </p>
    <h3>
      <strong>
        Art. 12. CLAUZE FINALE
      </strong>
    </h3>
    <p>
      Organizatorul este indreptatit sa ia toate masurile necesare in caz de tentative de frauda, abuz sau orice alte tentative care ar putea afecta
      imaginea acestei Campanii.
    </p>
    <p>
      Organizatorul nu  va  avea  nicio raspundere si nu  va  putea fi  implicat  in  niciun  litigiu  legat de eventualele cheltuieli suplimentare suportate
      de catre Participanti in legatura cu aceasta Campanie, impuse de catre Organizator.
    </p>
    <p>
      Prezentul Regulament a fost redactat cu respectarea prevederilor Ordonanţei de Guvernnr. 99/2000 privind comercializarea produselor şi serviciilor de
      piaţă, republicată şi a fost redactat şi autentificat in 2 exemplare originale.
    </p>
    <strong>
      07.03.2022
    </strong>
    <br />
    <strong>ORGANIZATOR</strong>
    <br />
    <strong>
      GTM MANAGING SERVICES SA
    </strong>

  </div>
);

const Privacy = () => (
  <div>
    <h1>Politica de prelucrare a datelor cu caracter personal CARFIX</h1>
    <h2>Versiunea 3 din data de 25.01.2022</h2>

    <p>
      Prin intermediul acestui document GTM Managing Services SA (denumita in cele ce urmează „GTM” sau
      Administratorul) va informează despre modul in care colectează, utilizează, transferă si protejează datele
      dumneavoastră cu caracter personal atunci când interacționați cu noi in legătură cu produsele si/sau
      serviciile noastre prin intermediul aplicației Carfix
    </p>
    <p>
      Ne rezervam dreptul de a actualiza si modifica periodic aceasta Politica de Confidențialitate, pentru a
      reflecta orice modificări ale modului in care prelucram datele dumneavoastră cu caracter personal sau
      orice modificări ale cerințelor legale. In cazul oricărei astfel de modificări, vom afișa in aplicația mobila
      versiunea modificata a Politicii de Confidențialitate, motiv pentru care va rugam sa verificați periodic
      conținutul acestei Politici de Confidențialitate.
    </p>

    <h2>1. Introducere</h2>
    <p>
      1.1. Aceasta Politica de confidențialitate face parte integranta din Termenii si conditiile Carfix si se aplica datelor personale
      colectate prin intermediul aplicatiei pentru mobil Carfix, disponibila gratuit pe magazinele de aplicatii Apple AppStore, Google Play
      si Huawei AppGallery . Dorim sa te asiguram ca am luat toate masurile necesre pentru a asigura confidentialitatea datelor tale, acestea
      fiind prelucrate numai de personalul Administratorului instruit cu privire la prelucrarea de date personale si autorizat in acest sens.
      {' '}
    </p>
    <p>
      1.2. Activitatile de prelucrare a datelor cu caracter personal se desfasoara sub responsabilitatea Administratorului Aplicatiei ,
      GTM Managing Services SA cu sediul în București, Nicolae Caramfil nr 71-73, ART BC 5, corp A, etaj 4, sector 1, inregistrata la Registrul
      Comertului sub nr. J40/14833/2013, avand CUI 32542239,  in calitate de operator.
    </p>
    <p>
      1.3. Pentru orice intrebari/ nelamuriri cu privire la protectia datelor cu caracter personal, sunteti invitati sa transmiteti o cerere
      la adresa postala Bucuresti, Nicolae Caramfil nr 71-73, ART BC 5,corp A, etaj 4, sector 1, sau la adresa de corespondenta electronica la
      adresa datepersonale@Carfixapp.com avand inscrisa pe plic, respectiv in subiectul e-mailului mentiuni de tipul “Protectia datelor personale”
      sau “GDPR”.

    </p>

    <h2>2. Definitii</h2>
    <p>
      2.1. Prin
      {' '}
      <strong><em>„date cu caracter personal”</em></strong>
      {' '}
      se intelege, in sensul legislatiei nationale si internationale in vigoare, orice informatii referitoare la o persoana fizica identificata
      sau identificabila. O persoana identificabila este acea persoana care poate fi identificata, direct sau indirect, in mod particular prin
      referire la un numar de identificare ori la unul sau mai multi factori specifici identitatii sale fizice, fiziologice, psihice, economice,
      culturale sau sociale.

      <br />
      In sensul prezentei politici, datele cu caracter personal care vor face obiectul prelucrarii se pot referi la: nume, prenume, numar de
      telefon, adresa de e-mail, varsta, resedinta/ domiciliul, cuantum salarial ori datele continute in CV-ul transmis in scopul recrutarii.
    </p>
    <p>
      2.2. Prin
      {' '}
      <strong><em>„prelucrarea datelor cu caracter personal”</em></strong>
      {' '}
      se intelege orice operatiune sau set de operatiuni efectuate asupra datelor cu caracter personal, prin mijloace automate sau neautomate,
      cum ar fi colectarea, inregistrarea, organizarea, stocarea, adaptarea ori modificarea, extragerea, consultarea, utilizarea, dezvaluirea
      catre terti prin transmitere, diseminare sau in orice alt mod, alaturarea ori combinarea, blocarea, stergerea sau distrugerea.
      <br />
      In sensul acestei politici, prelucrarile de date se refera la acele prelucrari efectuate la momentul utilizarii aplicatiei CARFIX.

    </p>
    <p>
      2.3.
      {' '}
      <strong><em>„Operator”</em></strong>
      {' '}
      inseamna persoana care, singura sau impreuna cu altele, stabileste scopurile si mijloacele de prelucrare a datelor cu caracter personal.
      Pentru claritate, in sensul prezentei Politici de Confidentialitate.
    </p>
    <p>
      2.4.
      {' '}
      <strong><em>„Persoana vizata”</em></strong>
      {' '}
      reprezinta persoana ale carei date cu caracter personal fac obiectul prelucrarii. In sesul prezentei Politici, persoana vizata de prelucrarea
      datelor este utilizatorul site-ului www.Carfixapp.com.
    </p>

    <p>
      2.5. Prin
      {' '}
      <strong><em>„consimtamant”</em></strong>
      {' '}
      al persoanei vizate se intelege orice manifestare de vointa libera, specifica, informata si lipsita de ambiguitate a persoanei vizate prin
      care aceasta accepta, printr-o declaratie sau printr-o actiune fara echivoc, ca datele cu caracter personal care o privesc sa fie
      prelucrate.
      {' '}

    </p>
    <p>
      2.6.
      {' '}
      <strong><em>“Partea terta”</em></strong>
      {' '}
      inseamna persoana fizica sau juridica, autoritate publica, agentie sau organism, altul decat persoana vizata, operatorul, persoana
      imputernicita de operator si persoanele care, sub directa autoritate a operatorului sau a persoanei imputernicite de operator, sunt
      autorizate sa prelucreze date cu caracter personal.

    </p>
    <p>
      2.7.
      {' '}
      <strong><em>„Persoana imputernicita de operator”</em></strong>
      {' '}
      inseamna persoana care prelucreaza datele cu caracter personal in numele operatorului.
    </p>

    <h2>3. Tipuri de date personale pe care le putem colecta</h2>
    <p>
      3.1. La accesarea Aplicației, Utilizatorul va selecta rolul Client după care fie se conecteaza la contul deja creat în aplicație, fie
      creaza unul nou.

    </p>

    <p>
      3.2. Pentru conectarea la aplicație este nevoie de adresa de email și parolă. În cazul în care acesta a uitat parola o poate
      recupera apăsând butonul „Am uitat parola” acțiune ce generează un mesaj trimis prin email care conține un link de redefinire a parolei.
      Odată redefinita parola, clientul poate relua pașii de mai sus conectarea la contul Carfix.
    </p>

    <p>
      3.3. Cand va creati cont pe Carfix, va solicitam date de identificare precum: nume, prenume, e-mail, parola. Aceste date sunt furnizate in mod
      direct de catre Utilizator, pentru a-si crea un cont valabil si pentru a beneficia de serviciul oferit de catre Administrator.
    </p>
    <p>
      3.4. Odata conectat la cont, Utilizatorului îi este afisat ecranul
      {' '}
      <strong><em>„Actiuni”</em></strong>
      {' '}
      unde are posibilitatea:
      <br />
      - sa completeze și sa transmita o notificare de dauna prin apasarea butonului
      {' '}
      <strong><em>„Notificare dauna”</em></strong>
      ; aceasta actiune presupune transmiterea
      datelor de identificare personala: nume, prenume, adresa de domiciliu, e-mail si a datelor de identificare ale autovehiculului: seria CIV,
      nr. inmatriculare, serie sasiu,)
      <br />
      - sa achizitioneze un pachet de revizii, piese sau servicii de reparatie accesând zonele corespunzatoare din același ecran
      <br />
      - sa achizitioneze o polita RCA, aceasta actiune presupune transmiterea datelor de identificare personala: nume, premunume, adresa de domiciliu,
      e - mail si a datelor de identificare ale autovehiculului: seria CIV, nr.inmatriculare, serie sasiu
      <br />
      - sa solicite prestarea serviciului de tractare, aceasta actiune presupune transmiterea datelor de identificare personala:  aceasta actiune presupune
      transmiterea datelor de identificare personala: nume, prenume, adresa de domiciliu, e - mail si a datelor de identificare ale autovehiculului: seria CIV,
      nr.inmatriculare, serie sasiu
      <br />
      - sa apleze la serviciile de inchiriere auto, aceasta actiune presupune transmiterea urmatoarelor date de identificare personala: nume, premunume, adresa
      de domiciliu, e - mail si a datelor de identificare ale autovehiculului.

    </p>
    <p>3.5. La crearea contului, fiecare Utilizator are posibilitatea de a decide daca doreste sa primeasca comunicari comerciale.</p>
    <p>
      3.6. Aplicatia poate fi utilizata doar daca Utilizatorul are un cont valabil.
    </p>
    <p>
      3.7. Carfix poate trimite notificari in Aplicatie catre adresa de e-mail introdusa in urmatoarele cazuri:
      <br />
      • La crearea contului pentru confirmarea adresei de email
      <br />
      • la aprobarea unei solicitari
      <br />
      • La expirarea unui document sau alte alerte configurate in aplicatie
      <br />
      • Pentru  confirmarea  anumitro  actiuni  din  cadrul  aplicatiei,  cum  ar  fi:  programarea  la  service, achizitia de servicii de tractare sau orice alta achizitie.
    </p>
    <p>
      3.8. Contul de utilizator poate contine date precum:
      <br />
      • Istoricul daunelor inregistrate;
      <br />
      • Datele personale folosite la inregistrarea contului;
      <br />
      • Date de identificare ale autovehiculului;
      <br />
      • Garaj- detalii despre masinile inregistrate in cont;
      <br />
      • Istoricul tranzactii;
      <br />
      • Documente inregistrate in cadrul contului: Polite RCA, CASCO, permis de conducere, talon autoturism, carte de identitate;
      <br />
      • Polite de asigurare
    </p>

    <p>
      3.9. Pentru a declara o dauna noua catre un Asigurator, Utilizatorul ofera date precum: nume, prenume, CNP, e-mail, telefon, data nasterii,
      tipul de asigurare, compania de asigurare care a emis polita, numar polita, serie polita, copie a politei, amabila, detalii legate de producerea
      evenimentului declarat (data si ora, judetul, orasul si adresa unde s-a produs evenimentul, cauza producerii evenimentului si/sau orice alte detali relevante).
      Aceste date sunt prelucrate in vederea executarii contractului dintre Utilizator si Asigurator si permite Asiguratorului sa gestioneze dosarele de daune
      inregistrate prin intermediul Carfix.

    </p>

    <p>
      3.10. De asemenea se solicita informații de identificare a șoferului care este vinovat de producerea
      evenimentului (nume, prenume, CNP, telefon, e-mail, copie CI, permis de conducere, talon autoturism,
      polița de asigurare). Utilizatorul este responsabil pentru informarea corecta si completa a tuturor
      persoanelor ale căror date le introduce in cadrul Aplicației CARFIX si pentru obținerea acordului acestor
      persoane. Administratorul va transmite către tert-ul ale cărui date sunt încărcate in sistem un e-mail de
      informare referitor la prelucrarea datelor sale in cadrul dosarului de dauna declarat de către Utilizator.
      Administratorul Aplicației un este responsabil pentru colectarea si introducerea acestor date in cadru
      sistemelor sale.
    </p>

    <p>
      3.11. Daca sunteți agent constatator si doriți sa va alăturați comunității Carfix, vom solicita date
      precum: nume, prenume, adresa email, număr de telefon. Prelucram toate aceste date in vederea
      executării contractului dintre Carfix si agentul constatator.

    </p>
    <p>
      3.12. Daca sunteți companie de asigurări, de leasing sau specializata in avizare si constatare daune, inscrierea se face prin transmiterea unui mesaj la contact@Carfixapp.com care contine date precum:
      <br />
      • Denumirea societatii;
      <br />
      • Numele si prenumele persoanei de contact
      <br />
      • E-mail-ul;
      <br />
      • Telefon;
    </p>

    <p>
      3.13. Companiile de asigurări, companiile de leasing, companiile de avizare si constatare daune sau constatorii independenti:
      <br />
      • se pot inscrie in Carfix si pot alege sa folosească sistemul nostru de administrare a dosarelor de dauna;
      <br />
      • sa solicite realizarea unei inspectii de risc si sa valideze incarcarea corecta a informatiilor;
      <br />
      • sa descarce documentele si imaginile incarcate de proprii clienti;

    </p>
    <p>
      3.14. Pentru a gestiona Carfix si pentru a va oferi o navigare îmbunătățită, atât Administratorul, cat si
      furnizorii noștri de servicii au interesul legitim de a utiliza cookie-uri sau semnalizatoare web, pentru a
      colecta date agregate. Utilizarea de cookie-uri se face pentru a permite afișarea corecta a conținutului,
      crearea si retinerea de date de autentificare, personalizarea interfetei, imbunatatirea serviciului prin
      implementarea si testarea de solutii tehnice. Astfel, este posibil ca prin modulele cookie sau tehnici
      similare sa colectam date personale precum: adresa IP; ID-ul dvs. de cookie; navigatorul dvs. de internet;
      locatia dvs.; paginile web pe care le accesati pe Aplicatie-urile noastre; anunturile pe care le-ati vizualizat
      sau pe care ati dat click;

    </p>
    <p>
      3.15. Administratorul a încheiat acorduri de prelucrare a datelor cu furnizorii de servicii IT/ administrare
      sistem online, însă nu răspunde pentru prelucrările efectuate de acești furnizori, in nume propriu,
      persoana vizata fiind informata in mod corespunzător cu privire la prelucrările ce presupun un transfer de
      date intre Administrator si orice societate care furnizează servicii către Operator.
    </p>
    <p>
      3.16. Datele tale cu caracter personal pot fi comunicate de Administrator, in vederea prelucrarii, unor alti operatori de prelucrare a datelor,
      inclusiv companiilor de asigurări, companiilor de leasing, companiilor de avizare si constatare daune bancilor si institutiilor financiare nebancare
      partenere, autoritatilor si institutiilor financiare, instantelor judecatoresti sau organismelor competente, la solictarea acestora si in scopul
      furnizarii serviciilor oferite de Operator.

    </p>

    <h2>4.  Scopurile colectarii datelor cu caracter personal</h2>

    <p>
      4.1. Datele personale colectate de catre Administrator sunt prelucrate in scopuri care includ:
      <br />
      • Utilizarea serviciilor Carfix;
      <br />
      • Administrarea dosarelor de dauna la solicitarea expresa a Utilizatorului, in conformitate cu prevederile legale aplicabile in materia;
      <br />
      • Achizitionarea politelor RCA;
      <br />
      • Beneficiarea de serviciul de tractare;
      <br />
      • Beneficiarea de servicii de inchiriere auto;
      <br />
      • Activarea sau inregistrarea anumitor produse si servicii sau perfectionarea functionalitatii;
      <br />
      • Primirea de informatii despre produse, oferte si servicii ale Carfix;
      <br />
      • Stocarea preferintelor tale pentru interactiunile si comunicarile viitoare cu Carfix;
      <br />
      • Imbunatatirea serviciilor, testarea produselor si serviciilor noi;
      <br />
      • Gestionarea relatiilor cu clientii parteneri;
      <br />
      • Facilitarea accesului la informatii;
      <br />
      • Imbunatatirea comunicarii;
      <br />
      • Analizarea si intelegerea interactiunilor cu clientii;
      <br />
      • Implementarea programelor de securitate;
      <br />
      • Răspunsul la întrebările Utilizatorilor sau rezolvarea solicitărilor de produse si servicii, precum si informarea Utilizatorilor in legătură cu starea acestora;

    </p>
    <p>
      4.2. Prelucram datele personale ale Utilizatorilor numai in scopurile pe care le-am comunicat. Daca le folosim in alte scopuri (apropiate), se vor aplica masuri
      de protectie a datelor suplimentare si vor fi utilizate numai cu acordul Utilizatorilor, acolo unde acesta este temeiul prelucrarii.
    </p>
    <p>
      4.3. Temeiul prelucrării este reprezentat de:
      <br />
      a) prelucrarea este necesara pentru executarea unui contract la care persoana vizata este parte sau pentru a face demersuri la cererea persoanei
      vizate inainte de incheierea unui contract (art. 6 alin. (1) lit. b) din Regulamentul (UE) nr. 679/ 2016 al Parlamentului European si al Consiliului
      din 27 aprilie 2016), in concret, in scopul asigurarii accesului persoanei vizate la serviciile oferite de Administrator;
      <br />
      b) prelucrarea este efectuata in temeiul consimtamantului persoanelor vizate (art. 6, alin. (1), lit. a) din Regulamentul (UE) nr. 679/ 2016 al
      Parlamentului European si al Consiliului din 27 aprilie 2016), in cazul transmiterii de comunicari de marketing;
      <br />
      c) prelucrarea este necesara in scopul intereselor legitime urmarite de operator (art. 6 alin. (1) lit. f) din Regulamentul (UE) nr. 679/ 2016 al
      Parlamentului European si al Consiliului din 27 aprilie 2016), pentru rezolvarea problemelor de orice natura referitoare la continutul aplicatiei
      CARFIX si pentru actiunile care implica interactiunea cu plaftorma online.

    </p>
    <h2>5. Partajarea datelor cu caracter personal</h2>

    <p>
      5.1. Dupa completarea dosarului de dauna, datele Utilizatorului legate de dosarele de dauna inregistrate se transmit catre asigurator.
      Astfel, se vor transmite: carte de identitate, permis de conducere auto, talon inmatriculare autoturism, polita de asigurare. De asemenea,
      Utilizatorul poate alege sa partajeze cu Asiguratorul anumite informatii disponibile in sectiunea “Documentele mele” din contul sau.
      Asiguratorii sunt operatori de date cu caracter personal, in sensul legislatiei aplicabile. Acestia sunt responsabili, in mod individual,
      pentru prelucrarea datelor care le sunt puse la dispozitie de catre Utilizator ca urmare a declararii unui dosar de daune.
    </p>

    <p>
      5.2. Dupa completarea datelor in vederea achizitionarii unei polite RCA, datele Utilizatorului se transmit catre platforma electrónica 24broker
      pentru emiterea polițelor de asigurare, iar asiguratorii sunt operatori de date cu caracter personal, in sensul legislatiei aplicabile.
      Acestia sunt responsabili, in mod individual, pentru prelucrarea datelor care le sunt puse la dispozitie de catre Utilizator.
    </p>

    <p>
      5.3. Dupa completarea datelor in vederea achizitionarii serviciilor de tractare, datele utilizatorului se transmit catre persoana care va
      fi responsabil de prestarea serviciului. Persoana care va fi responsabil de prestarea serviciului este operator de date cu caracter personal, in
      sensul legislatiei aplicable. Acesta este responsabil in mod individual, pentru prelucrarea datelor care le sunt puse la dispozitie de catre Utilizator.
    </p>

    <p>
      5.4. Dupa completarea datelor in vederea achizitionarii serviciilor de inchiriere auto, datele utilizatorului se transmit catre persoana care va avea
      calitatea de locator. Persoana care va avea calitatea de locator este operator de date cu caracter personal, in sensul legislatiei aplicable. Acesta
      este responsabil in mod individual, pentru prelucrarea datelor care le sunt puse la dispozitie de catre Utilizator.
    </p>

    <p>
      5.5. Putem partaja datele tale personale cu furnizori de produse, parteneri de afaceri si alti terti in conformitate cu legea aplicabila.
    </p>

    <p>
      5.6. Astfel de categorii de destinatari pot fi: furnizori de servicii de contabilitate, asistenta juridica, banci, companii de asigurare,
      companii de leasing sau specializata in avizare si constatare daune , constatatori independenti si orice alti parteneri de afaceri,
      atunci cand sunt necesare pentru executarea unui contract sau cand exista un interes legitim al Administratorului Aplicatie-ului/aplicatiei,
      fara insa a afecta drepturile persoanelor vizate (Utilizatori, persoane de contact parteneri etc) si cu respectarea masurilor de securitate
      necesare.
    </p>

    <p>
      5.7. Putem partaja datele cu caracter personal si catre alti parteneri, pentru gestionarea datelor si alte parti care au o relatie obligatorie
      cu Administratorului, pentru oferirea serviciilor sale (de ex. campanii email marketing, notificari push, contracte).

    </p>
    <p>
      5.8. In cazul in care vom primi o solicitare din partea organelor abilitate ale statului prin care este impusa dezvaluirea unor date cu caracter
      personal ale Utilizatorilor, conform legii, suntem obligati sa ii dam curs.
    </p>
    <p>
      5.9. De asemenea, vom comunica Utilizatorului datele pe care le solicita (in cazul in care formuleaza o cerere de acces la date). Totodata,
      putem comunica datele si unui alt operator, la solicitarea Utilizatorului, pentru garantarea dreptului privind portabilitatea datelor (in masura
      in care avem obligatia si conform regulilor privind portabilitatea datelor aplicabile conform prevederilor legale aplicabile in conditiile
      tehnologice existente la momentul cererii tale).
    </p>
    <p>
      5.10. Putem partaja datele dumneavoastra si daca suntem supusi unei tranzitii a companiei, de exemplu o fuziune, achizitia de catre alta companie
      sau vanzarea integrala sau partiala a activelor.
    </p>

    <h2>6. Drepturile Utilizatorilor</h2>
    <p>
      6.1. Dreptul de a fi informat. Prin prezenta politica de prelucrare a datelor cu caracter personal, utilizatorii serviciilor Carfix sunt informati
      cu privire la identitatea si datele de contact ale operatorului, datele de contact ale responsabilului cu protectia datelor, scopurile prelucrarii
      datelor personale si temeiul juridic al prelucrarii, categoriile de destinatari ai datelor cu caracter personal, transferul catre tari terte sau
      organizatii internationale, perioada de stocare a datelor, precum si drepturile pe care le au.
    </p>
    <p>
      6.2. Dreptul de acces la date. Utilizatorii au dreptul sa obtina din partea noastra o confirmare ca se prelucreaza sau nu date cu caracter personal
      care ii privesc si in caz afirmativ, acces la datele respective si informarea corespunzatoare. La cererea utilizatorilor, putem furniza o copie a
      datelor cu caracter personal care fac obiectul prelucrarii. Pentru orice alte copii solicitate, putem percepe o taxa rezonabila, bazata pe costurile
      administrative. Informatiile vor fi furnizate intr-un format electronic, utilizat in mod curent. Ne rezervam dreptul de a nu raspunde solicitarilor
      vadit neintemeiate, excesive sau repetate.
    </p>
    <p>
      6.3. Dreptul la rectificare. Utilizatorul poate cere rectificarea datelor cu caracter personal inexacte, respectiv completarea datelor cu caracter
      personal care sunt incomplete, inclusiv prin furnizarea unei declaratii suplimentare. Acest drept poate fi exercitat in mod direct prin accesarea
      contului de utilizator. In cazul in care intampini dificultati, te rugam sa ne contactezi..

    </p>
    <p>
      6.4. Dreptul la stergerea datelor ("dreptul de a fi uitat"). Utilizatorul poate cere stergerea datelor cu caracter personal care il privesc,
      in cazul in care se aplica unul dintre urmatoarele motive:
      <br />
      • datele cu caracter personal nu mai sunt necesare pentru indeplinirea scopurilor pentru care au fost colectate sau prelucrate;
      <br />
      • isi retrage consimtamantul pe baza caruia are loc prelucrarea si nu exista niciun alt temei juridic pentru prelucrare;
      <br />
      • se opune prelucrarii;
      <br />
      • datele cu caracter personal au fost prelucrate ilegal;
      <br />
      • datele cu caracter personal trebuie sterse pentru respectarea unei obligatii legale care revine operatorului;
      <br />
      • datele cu caracter personal au fost colectate in legatura cu oferirea de servicii ale societatii informationale;
      <br />
      Conform dispozitiilor legale, obligatia de a sterge datele Utilizatorului nu se aplica in masura in care prelucrarea este necesara:
      <br />
      • pentru exercitarea dreptului la libera exprimare si la informare;
      <br />
      • pentru respectarea unei obligatii legale sau pentru indeplinirea unei sarcini executate in interes public sau in cadrul exercitarii
      unei autoritati oficiale cu care este investit operatorul;
      <br />
      • din motive de interes public in domeniul sanatatii publice;
      <br />
      • in scopuri de arhivare in interes public, in scopuri de cercetare stiintifica sau istorica ori in scopuri statistice sau pentru constatarea,
      exercitarea sau apararea unui drept in instanta.
      <br />
      Acest drept poate fi exercitat in mod direct prin accesarea contului de utilizator. In cazul in care intampinati dificultati, va rugam sa ne contactati.

    </p>
    <p>
      6.5. Dreptul la restrictionarea prelucrarii. Utilizatorul are dreptul de a obtine restrictionarea prelucrarii in cazul in care se aplica
      unul din urmatoarele cazuri:
      <br />
      • utilizatorul contesta exactitatea datelor, pentru o perioada care ne permite sa verificam exactitatea datelor;
      <br />
      • prelucrarea este ilegala, iar utilizatorul se opune stergerii datelor cu caracter personal, solicitand in schimb restrictionarea utilizarii lor;
      <br />
      • nu mai avem nevoie de datele cu caracter personal in scopul prelucrarii, dar utilizatorul ni le solicita pentru constatarea, exercitarea
      sau apararea unui drept in instanta;
      <br />
      • utilizatorul s-a opus prelucrarii, pentru intervalul de timp in care se verifica daca drepturile legitime ale operatorului prevaleaza
      asupra celor ale persoanei vizate.

    </p>
    <p>
      6.6. Dreptul la portabilitatea datelor. Utilizatorul are dreptul de a primi datele cu caracter personal care il privesc si pe care le-a furnizat
      operatorului intr-un format structurat, utilizat in mod curent si care poate fi citit automat si are dreptul de a transmite aceste date altui operator,
      fara obstacole din partea operatorului caruia i-au fost furnizate datele cu caracter personal, in cazul in care:
      <br />
      • prelucrarea se bazeaza pe consimtamant sau pe un contract si
      <br />
      • prelucrarea este efectuata prin mijloace automate.
      <br />
      In exercitarea dreptului sau la portabilitatea datelor, utilizatorul are dreptul ca datele cu caracter personal sa fie transmise direct de la un
      operator la altul acolo unde acest lucru este posibil din punct de vedere tehnic.
      <br />
      Daca va exercitati acest drept, veti primi pe mail un link securizat care contine toate datele astfel solicitate.

    </p>
    <p>
      6.7. Dreptul de opozitie. Utilizatorul are dreptul de a se opune in orice moment, din motive intemeiate si legitime legate de situatia sa particulara,
      ca date care il vizeaza sa faca obiectul unei prelucrari, cu exceptia cazurilor in care exista dispozitii legale contrare. In caz de opozitie justificata,
      prelucrarea nu mai poate viza datele in cauza.
    </p>

    <p>
      6.8. Dreptul de a nu fi supus unui proces decizional individual automatizat. Utilizatorii au dreptul de a cere si de a obtine retragerea/anularea/reevaluarea
      oricarei decizii care produce efecte juridice in privinta sa, adoptata exclusiv pe baza unei prelucrari de date cu caracter personal, efectuata prin mijloace
      automate, destinata sa evalueze unele aspecte ale personalitatii sale, precum competenta profesionala, credibilitatea, comportamentul sau orice alte asemenea aspecte.
    </p>

    <p>
      6.9. Dreptul de a se adresa justitiei. Fara a se aduce atingere posibilitatii de a se adresa cu plangere autoritatii de supraveghere privind protectia datelor personale,
      utilizatori au dreptul de a se adresa justitiei pentru apararea oricaror drepturi garantate de lege, care le-au fost incalcate.
      <br />
      Daca Utilizatorul a suferit un prejudiciu in urma unei prelucrari de date cu caracter personal, efectuata ilegal, se poate adresa instantei competente pentru
      repararea acestuia. Pentru orice solicitare legata de utilizarea datelor tale personale, ne poti contacta la:
      <br />
      • Email:			datepersonale@Carfixapp.com
      <br />
      • Telefon: 		+40215394444
      <br />
      • Adresa contact: 	București, Nicolae Caramfil nr 71-73, ART BC 5,corp A, etaj 4, sector 1.
      <br />
      Va incurajam sa mentineti setarile personale si datele personale complete si curente. Echipa noastra va depune toate eforturile necesare pentru solutionarea
      intr-un timp cat mai scurt a solicitarilor tale.
      <br />
      Conform legislatiei in domeniul protectiei datelor personale, termenul de raspuns este 30 de zile. In cazul in care, in functie de complexitatea cererii
      nu putem implementa masurile solicitate intr-un termen mai mic de o luna, aceasta perioada poate fi prelungita, conform legislatiei aplicabile,
      cu maximum 60 de zile. In aceasta situatie, veti primi o informare in termen de maximum 30 de zile de la trimiterea cererii, cu privire la motivele intarzierii.

    </p>

    <h2>7. Criteriile si perioada de stocare a datelor</h2>
    <p>
      7.1. Datele asociate contului Utilizatorului si alte date furnizare prin intermediul Aplicatiei vor ramane in posesia Administratorului doar pe durata existentei
      serviciilor furnizate si strict pentru respectarea cerintelor legale in vigoare (de ex., termenele de arhivare) si pentru protejarea drepturilor legale si intereselor
      Administratorului.
    </p>
    <p>
      7.2. Daca Utilizatorul doreste sa renunte la cont, in masura in care datele nu vor mai fi necesare pentru respectarea cerintelor legale in vigoare si pentru
      protejarea drepturilor legale si a intereselor Administratorului Aplicatie-ului/aplicatiei, acestea vor fi sterse sau anonimizate (vezi punctul 5.4. Dreptul
      la stergerea datelor ("dreptul de a fi uitat").
    </p>
    <p>7.3. Datele anonimizate vor fi utilizate strict pentru analize statistice ulterioare.</p>
    <p>
      7.4. Administratorul se obligă să ia toate măsurile necesare pentru păstrarea datelor cu caracter personal de o manieră exactă, completată și actualizată,
      pentru a îndeplinii scopurilor pentru care acestea au fost colectate. Datele inexacte sau incomplete vor fi rectificate sau eliminate.

    </p>
    <p>
      7.5. Datele cu caracter personal vor fi păstrate numai pe perioada necesară îndeplinirii scopului stabilit, cu respectarea drepturilor persoanei vizate
      prevăzute de legislația in vigoare.

    </p>
    <h2>8. Conectarea altor Aplicatii/ Platforme on-line</h2>
    <p>
      8.1. In Aplicatie, Utilizatorul poate intalni legaturi catre alte Aplicatie-uri/Aplicatii/Platforme on-line pentru informarea sa. Acestea pot functiona
      independent de Carfix si pot avea propriile notificari, declaratii sau politici de confidentialitate. Va recomandam sa le treceti in revista pentru
      a intelege cum pot fi procesate datele personale in legatura cu acele Aplicatie-uri, intrucat nu suntem responsabili pentru continutul
      Aplicatie-urilor care nu apartin sau care nu sunt gestionate de Administratorul Carfix sau pentru utilizarea sau practicile de confidentialitate
      ale acelor Aplicatie-uri.
    </p>
    <h2>9. Retele sociale</h2>
    <p>
      9.1.  In functie de alegerile facute cu privire la setarile de pe diferite Aplicatie-uri de socializare si/sau in combinatie cu setarile de pe
      paginile Carfix, anumite date personale pot fi partajate cu Carfix, in legatura cu activitatile si profilurile de socializare. Atunci cand
      participati la diferite forumuri ale mediilor de socializare, precum Facebook, Twitter, Yammer, Pinterest, Instagram, LinkedIn etc.,
      trebuie sa fiti familiarizat cu ele si sa intelegi instrumentele oferite de aceste Aplicatie-uri care va permit sa faceti alegeri cu privire
      la modul in care partajati datele personale pe profilurile de pe mediile de socializare.
    </p>
    <p>
      9.2. Carfix este angajat in practicile sau politicile de confidentialitate ale acestor terti, precum si in propriile noastre reguli interne
      privind mediile de socializare si aceasta politica de prelucrare a datelor cu caracter personal, asadar cititi notificarile de confidentialitate
      aplicabile, termenii de utilizare si informatiile aferente privind modul in care datele personale sunt utilizate in aceste medii de socializare.

    </p>
    <h2>10. Cloud</h2>
    <p>
      10.1. Carfix poate stoca datele personale intr-un cloud. Aceasta inseamna ca datele dumneavoastra personale pot fi procesate in numele Administratorului
      de catre un furnizor de servicii cloud si pot fi stocate in diferite locatii din cadrul Spatiului Economic European. Administratorului recurge la masuri
      organizationale si contractuale pentru a-ti proteja datele personale si pentru a impune cerinte similare, insa nu mai putin restrictive pentru furnizorii
      nostri de servicii cloud, inclusiv cerinte conform carora datele personale sa fie procesate exclusiv in scopurile indicate mai sus.
    </p>
    <h2>11. Cum va protejam datele personale</h2>
    <p>
      11.1. Ne asumam si luam in serios responsabilitatea noastra de protectie a datelor personale pe care ni le incredintati. Astfel, pentru a evita pierderea,
      utilizarea necorespunzatoare sau accesul neautorizat, Administratorului utilizeaza o varietate de tehnologii de securitate si proceduri organizationale
      pentru a ajuta la protejarea datelor personale.
    </p>
    <h2>12. Informatii speciale pentru parinti</h2>
    <p>
      12.1. Desi Carfix nu are ca public tinta copiii sub 16 ani, avem obligatia de a respecta legile si de a solicita permisiunea parintilor sau a tutorelui
      legal inainte de colectarea, utilizarea sau divulgarea informatiilor cu privire la copii.
    </p>
    <p>
      12.2. Carfix recomanda ferm ca parintii sa isi asume un rol activ de supraveghere a activitatilor online ale copiilor lor. In cazul in care consideri ca
      am colectat date personale de la persoane sub varsta de 16 ani, va rugam sa ne contactati la adresa de email: datepersonale@Carfixapp.com
    </p>
    <h2>13. Modificari ale notificarii de confidentialitate</h2>
    <p>
      13.1. Serviciile oferite de Carfix evolueaza in permanenta, iar forma si tipul serviciilor oferite se pot modifica din cand in cand fara aviz prealabil.
      Din acest motiv, ne rezervam dreptul de a schimba sau a adauga din cand in cand aceasta notificare de confidentialitate, iar orice revizuiri de
      continut vor fi postate pe Aplicatie-urile noastre sau vor fi trimise printr-o notificare push prin intermediul aplicatiei sau prin e-mail.
    </p>
    <p>
      13.2. Vom posta un anunt important pe pagina notificarii de confidentialitate pentru a va instiinta cu privire la orice modificari semnificative aduse
      acestei notificari de confidentialitate si vom indica in partea superioara a anuntului data la care s-a realizat cea mai recenta actualizare.

    </p>
    <p>
      13.3. Noua politica de prelucrare a datelor cu caracter personal va intra in vigoare la data postarii. Daca nu sunteti de acord cu notificarea revizuita,
      trebuie sa va schimbati preferintele sau sa aveti in vedere intreruperea utilizarii Aplicatiei.

    </p>
    <h2>14. Cum ne puteti contacta</h2>
    <p>
      • Contacteaza-ne la adresa: București, Nicolae Caramfil nr 71-73, ART BC 5, corp A, etaj 4, sector 1.
      <br />
      • Email:	datepersonale@Carfixapp.com sau contact@Carfixapp.com
      <br />
      • Telefon:	+40215394444

    </p>
  </div>
);

const PrivacyEn = () => (
  <div>
    <h1>Personal Data Processing Policy CARFIX</h1>
    <h2>Version 3 from 25.01.2022</h2>

    <p>
      Through this document GTM Managing Services SA (hereinafter referred to as "GTM" or the Controller)
      informs you about how it collects, uses, transfers and protects your personal data when you interact with
      us in connection with our products and/or services through the Carfix application.
    </p>
    <p>
      We reserve the right to periodically update and modify this Privacy Policy to reflect any changes in the
      way we process your personal data or any changes in legal requirements. In case of any such changes, we
      will display in the mobile application the modified version of the Privacy Policy, which is why we ask you
      to periodically check the content of this Privacy Policy.
    </p>

    <h2>1. Introduction</h2>
    <p>
      1.1. This Privacy Policy is an integral part of the Carfix Terms of Service and applies to personal data
      collected through the Carfix mobile application, available for free on apple app stores AppStore, Google
      Play and Huawei AppGallery. We want to assure you that we have taken all the necessary measures to
      ensure the confidentiality of your data, these being processed only by the staff of the Controller trained
      in the processing of personal data and authorized in this regard.
    </p>
    <p>
      1.2. The personal data processing activities are carried out under the responsibility of the Application
      Administrator, GTM Managing Services SA based in Bucharest, Nicolae Caramfil no. 71-73, ART BC 5,
      building A, 4th floor, sector 1, registered with the Trade Register under no. J40/14833/2013, having cui
      32542239, as operator.
    </p>
    <p>
      1.3. For any questions/ concerns regarding the protection of personal data, you are invited to send a
      request to the postal address Bucharest, Nicolae Caramfil no. 71-73, ART BC 5, building A, 4th floor, sector
      1, or by email at datepersonale@Carfixapp.com, having written on the envelope, respectively in the
      subject of the e-mail mentions such as "Protection of personal data" or "GDPR".
    </p>

    <h2>2. Definitions</h2>
    <p>
      2.1.
      {' '}
      <strong><em>„Personal data”</em></strong>
      {' '}
      means, as per the national and international legislation in force, any information
      related to an identified or identifiable natural person. An identifiable person is one who can be identified,
      directly or indirectly, in particular by reference to an identification number or to one or more factors
      specific to his or her physical, physiological, mental, economic, cultural or social identity.

      <br />
      For the purposes of this policy, the personal data that will be the subject of processing may refer to: name,
      surname, telephone number, e-mail address, age, residence / domicile, salary amount or the data
      contained in the CV sent for the purpose of recruitment.
    </p>
    <p>
      2.2.
      {' '}
      <strong><em>"Processing of personal data"</em></strong>
      {' '}
      means any operation or set of operations performed on personal
      data, by automatic or non-automatic means, such as collecting, recording, organizing, storing, adapting
      or modifying, extracting, consulting, using, disclosing to third parties by transmission, dissemination or in
      any other way, joining or combining, blocking, deleting or destroying.
      <br />
      For the purposes of this policy, data processing refers to those processing carried out at the time of using
      the CARFIX application.
    </p>
    <p>
      2.3.
      {' '}
      <strong><em>„Controller”</em></strong>
      {' '}
      means the person who, alone or together with others, establishes the purposes and
      of the processing of personal data. For the sake of clarity, for the purposes of this Privacy Policy.
    </p>
    <p>
      2.4.
      {' '}
      <strong><em>„Data subject”</em></strong>
      {' '}
      means the person whose personal data are the subject of processing. In the case
      of this Policy, the person concerned by the data processing is the user of the www.Carfixapp.com website.
    </p>

    <p>
      2.5. The
      {' '}
      <strong><em>„consent”</em></strong>
      {' '}
      of the data subject means any manifestation of the free, specific, informed and
      unambiguous will of the data subject by which he/she accepts, by a statement or by an unequivocal action,
      that the personal data concerning him/her to be processed.
      {' '}

    </p>
    <p>
      2.6.
      {' '}
      <strong><em>“Third party”</em></strong>
      {' '}
      means the natural or legal person, public authority, agency or body, other than the
      data subject, the controller, the person empowered by the controller and the persons who, under the
      direct authority of the controller or of the person empowered by the controller, are authorized to process
      personal data.
    </p>
    <p>
      2.7.
      {' '}
      <strong><em>„Person empowered by the controller”</em></strong>
      {' '}
      means the person who processes the personal data on behalf of the controller.
    </p>

    <h2>3. Types of personal data we may collect</h2>
    <p>
      3.1. When accessing the Application, the User will select the Client role after which he either logs in
      to the account already created in the application or creates a new one.
    </p>

    <p>
      3.2. To log in to the application you need your email address and password. If he has forgotten the
      password he can recover it by pressing the "I forgot password" button, which generates an email message
      containing a password redefinition link. Once the password is redefined, the customer can resume the
      above steps connecting to the Carfix account.
    </p>

    <p>
      3.3. When you create an account on Carfix, we ask for identification data such as: name, surname, email, password. These data are provided directly by the User, in order to create a valid account and to
      benefit from the service offered by the Administrator.
    </p>
    <p>
      3.4. Once connected to the account, the User is displayed the
      {' '}
      <strong><em>„Actions”</em></strong>
      {' '}
      screen where he has the possibility:
      <br />
      - to complete and submit a damage notification by pressing the
      {' '}
      <strong><em>„Damage Notification”</em></strong>
      button; this action involves the transmission of personal identification data: name, premunume, home
      address, e-mail and identification data of the vehicle: CIV series, no. registration, chassis series,)
      <br />
      - to purchase a package of revisions, parts or repair services by accessing the corresponding areas in the same screen
      <br />
      - to purchase an RCA policy, this action involves the transmission of personal identification data:
      name, premunume, home address, e-mail and vehicle identification data: CIV series, no.registration, chassis series
      <br />
      - to request the provision of the towing service, this action involves the transmission of personal
      identification data: this action involves the transmission of personal identification data: name,
      surname, home address, e-mail and identification data of the vehicle: CIV series, no. registration,
      chassis series
      <br />
      - to pay for car rental services, this action involves the transmission of the following personal
      identification data: name, premunume, home address, e-mail and identification data of the vehicle.

    </p>
    <p>3.5. When creating the account, each User has the possibility to decide if he wants to receive commercial communications.</p>
    <p>
      3.6. The application can be used only if the User has a valid account.
    </p>
    <p>
      3.7. Carfix may send notifications in the Application to the e-mail address entered in the following cases:
      <br />
      • When creating the account to confirm the email address
      <br />
      • upon approval of a request
      <br />
      • Upon expiration of a document or other alerts configured in the application
      <br />
      • For confirmation of a certain action within the app, like: workshop appointment schedule,
    </p>
    <p>
      3.8. The user account may contain data such as:
      <br />
      • History of registered damages;
      <br />
      • Personal data used to register the account;
      <br />
      • Vehicle identification data;
      <br />
      • Garage - details about the cars registered in the account;
      <br />
      • Transaction history;
      <br />
      • Documents registered within the account: RCA policies, CASCO, driving license, car bead, identity card;
      <br />
      • Insurance policies
    </p>

    <p>
      3.9. In order to notify a new loss to an Insurance Company, the User provides data such as: name,
      surname, CNP, e-mail, telephone, date of birth, type of insurance, insurance company that issued the
      policy, policy number, policy series, copy of the policy, courtesy, details related to the occurrence of the
      declared event (date and time, county, city and address where the event occurred, cause of the event and
      / or any other relevant details). These data are processed in order to execute the contract between the
      User and the Insurer and allow the Insurer to manage the damage files registered through Carfix.
    </p>

    <p>
      3.10. It also requires information to identify the driver who is guilty of producing the event (name,
      surname, PNC, telephone, e-mail, copy of the ID, driver's license, car bead, insurance policy). The User is
      responsible for the correct and complete information of all the persons whose data he enters within the
      CARFIX Application and for obtaining the consent of these persons. The Administrator will send to the
      third party whose data are uploaded in the system an e-mail informing about the processing of his data
      within the damage file declared by the User. The Application Administrator is responsible for collecting
      and entering this data within its systems.
    </p>

    <p>
      3.11. If you are a reporting agent and you want to join the Carfix community, we will request data such
      as: name, surname, email address, phone number. We process all this data in order to execute the
      contract between Carfix and the ascertaining agent.
    </p>
    <p>
      3.12. If you are an insurance company, leasing company or a company specialized in damages
      assessment, the registration is done by sending an email to contact@Carfixapp.com containing data such as:
      <br />
      • Name of the company;
      <br />
      • Name and surname of the contact person
      <br />
      • E-mail;
      <br />
      • telephone;
    </p>

    <p>
      3.13. Insurance companies, leasing companies, damage approval and finding companies or independent ascertaining ascertainments:
      <br />
      • they can sign up for Carfix and choose to use our damage file management system;
      <br />
      • to request a risk inspection and to validate the correct loading of the information;
      <br />
      • to download the documents and images uploaded by their own clients;
    </p>
    <p>
      3.14. In order to manage Carfix and to provide you with improved browsing, both the Controller and
      our service providers have a legitimate interest in using cookies or web beacons to collect aggregated
      data. The use of cookies is made to allow the correct display of the content, the creation and retention of
      authentication data, the personalization of the interface, the improvement of the service by implementing
      and testing technical solutions. Thus, it is possible that through cookies or similar techniques we collect
      personal data such as: IP address; Your ID cookie; your browser internet; your location; the web pages
      you access on our Applications; ads that you have viewed or clicked on;
    </p>
    <p>
      3.15. The Controller has concluded data processing agreements with the IT / online system
      administration service providers, but is not responsible for the processing carried out by these providers,
      in his own name, the data subject being properly informed about the processing that involves a transfer
      of data between the Controller and any company that provides services to the Operator.
    </p>
    <p>
      3.16. Your personal data may be communicated by the Controller, for processing purposes, to other
      data processing operators, including insurance companies, leasing companies, damage assessment
      companies, banks and non-banking financial institutions, financial authorities and institutions, courts of
      law or competent bodies, upon their request and for the purpose of providing the services offered by the
      Operator.
    </p>

    <h2>4.  Purposes of collecting personal data</h2>

    <p>
      4.1. The personal data collected by the Controller are processed for purposes that include:
      <br />
      • Use of Carfix services;
      <br />
      • Administration of the damage files at the express request of the User, in accordance with the applicable legal provisions in the field;
      <br />
      • Purchase of TPL insurance policies;
      <br />
      • Beneficiary of the towing service;
      <br />
      • Beneficiary of car rental services;
      <br />
      • Activating or registering certain products and services or perfecting the functionality;
      <br />
      • Receiving information about Products, Offers and Services of Carfix;
      <br />
      • Storing your preferences for future interactions and communications with Carfix;
      <br />
      • Improvement of services, testing of new products and services;
      <br />
      • Managing relationships with partner clients;
      <br />
      • Facilitating access to information;
      <br />
      • Improving communication;
      <br />
      • Analyzing and understanding interactions with customers;
      <br />
      • Implementation of security programs;
      <br />
      • Answering users' questions or solving requests for products and services, as well as informing users about their status;
    </p>
    <p>
      4.2. We process users' personal data only for the purposes we have communicated. If we use them for other (close) purposes, additional data protection
      measures will be applied and will be used only with the users' consent, where this is the basis for processing.
    </p>
    <p>
      4.3. The basis of the processing is represented by:
      <br />
      a) processing is necessary for the performance of a contract to which the data subject is party or to take steps at the request of the data
      subject before concluding a contract (Art. 6 para. (1) point .b) of Regulation (EU) No. 679/2016 of the European Parliament and of the Council
      of 27 April 2016), in concrete terms, in order to ensure the access of the data subject to the services offered by the Controller;
      <br />
      b) the processing is carried out on the basis of the consent of the data subjects (art. 6, para. (1), point (a) of Regulation (EU) No 679/2016
      of the European Parliament and of the Council of 27 April 2016), in the case of transmission of marketing communications;
      <br />
      c) the processing is necessary for the purposes of the legitimate interests pursued by the operator (art. 6 para. (1) point (f) of Regulation (EU)
      No. 679/2016 of the European Parliament and of the Council of April 27, 2016), for solving problems of any kind related to the content of the CARFIX
      application and for actions that involve interaction with the online platform.
    </p>
    <h2>5. Sharing of personal data</h2>

    <p>
      5.1. After completing the damage file, the User's data related to the registered damage files are
      transmitted to the insurer. Thus, the following will be sent: identity card, driving license, car registration
      bead, insurance policy. Also, the User may choose to share with the Insurer certain information available
      in the "My documents" section of his account. Insurers are operators of personal data, within the meaning
      of the applicable legislation. They are individually responsible for the processing of the data made
      available to them by the User as a result of declaring a damage file.
    </p>

    <p>
      5.2. After completing the data in order to purchase an RCA policy, the User's data is transmitted to the
      electrónica 24broker platform for issuing insurance policies, and the insurers are personal data
      operators, within the meaning of the applicable legislation. They are individually responsible for the
      processing of the data made available to them by the User.
    </p>

    <p>
      5.3. After completing the data in order to purchase the towing services, the user's data is transmitted
      to the person who will be responsible for the provision of the service. The person who will be responsible
      for the provision of the service is a personal data operator, within the meaning of the applicable
      legislation. It is individually responsible for the processing of the data made available to them by the User.
    </p>

    <p>
      5.4. After completing the data in order to purchase the car rental services, the user's data is
      transmitted to the person who will have the capacity of lessor. The person who will be the lessor is a
      personal data controller, within the meaning of the applicable legislation. It is individually responsible for
      the processing of the data made available to them by the User.
    </p>

    <p>
      5.5. We may share your personal data with product vendors, business partners and other third parties in accordance with applicable law.
    </p>

    <p>
      5.6. Such categories of recipients can be: providers of accounting services, legal assistance, banks,
      insurance companies, leasing companies or specialized in approving and finding damages, independent
      ascertaining and any other business partners, when they are necessary for the performance of a contract
      or when there is a legitimate interest of the Application / Application Administrator, without affecting the
      rights of the data subjects (Users, partner contacts, etc.) and in compliance with the necessary security
      measures.
    </p>

    <p>
      5.7. We may also share personal data with other partners, for data management and other parties
      that have a mandatory relationship with the Controller, for the provision of its services (e.g. email
      marketing campaigns, push notifications, contracts).
    </p>
    <p>
      5.8. In case we receive a request from the competent bodies of the state through which it is imposed
      the disclosure of personal data of the Users, according to the law, we are obliged to comply with it.
    </p>
    <p>
      5.9. We will also communicate to the User the data they request (if they make a request for access to
      the data). At the same time, we may communicate the data to another operator, at the user's request, in
      order to guarantee the right to data portability (to the extent that we have the obligation and according
      to the data portability rules applicable according to the legal provisions applicable in the technological
      conditions existing at the time of your request).
    </p>
    <p>
      5.10. We may also share your data if we are subject to a transition of the company, for example a
      merger, acquisition by another company or the sale of all or part of the assets.
    </p>

    <h2>6. User Rights</h2>
    <p>
      6.1.  Right to be informed. Through this personal data processing policy, the users of the Carfix services
      are informed about the identity and contact details of the controller, the contact details of the data
      protection officer, the purposes of the personal data processing and the legal basis of the processing, the
      categories of recipients of personal data, the transfer to third countries or international organizations,
      the period of data storage, as well as the rights they have.
    </p>
    <p>
      6.2. Right of access to data. Users have the right to obtain from us a confirmation that personal data
      concerning them are being processed or not and, if so, access to that data and proper information. At the
      request of users, we may provide a copy of the personal data subject to processing. For any other copies
      requested, we may charge a reasonable fee based on administrative costs. The information will be
      provided in an electronic format, currently used. We reserve the right not to respond to manifestly
      unfounded, excessive or repeated requests.
    </p>
    <p>
      6.3. Right to rectification. The user may request the rectification of inaccurate personal data,
      respectively the completion of personal data that are incomplete, including by providing an additional
      statement. This right can be exercised directly by accessing the user account. If you encounter any
      difficulties, please contact us.
    </p>
    <p>
      6.4. The right to delete the data ("the right to be forgotten"). The user may request the deletion of
      personal data concerning him/ her, if one of the following reasons applies:
      <br />
      • the personal data are no longer necessary for the purposes for which they were collected or processed;
      <br />
      • withdraws its consent on the basis of which the processing takes place and there is no other legal basis for the processing;
      <br />
      • opposes processing;
      <br />
      • the personal data have been unlawfully processed;
      <br />
      • personal data must be deleted in order to comply with a legal obligation incumbent on the controller;
      <br />
      • the personal data have been collected in connection with the provision of services of the information society;
      <br />
      According to the legal provisions, the obligation to delete the User's data does not apply to the extent that the processing is necessary:
      <br />
      • for exercising the right to free expression and information;
      <br />
      • for compliance with a legal obligation or for the performance of a task performed in the public
      interest or within the exercise of an official authority with which the operator is invested;
      <br />
      • for reasons of public interest in the field of public health;
      <br />
      • for archiving purposes in the public interest, for scientific or historical research purposes or for
      statistical purposes or for the establishment, exercise or defense of a right in court.
      <br />
      This right can be exercised directly by accessing the user account. If you encounter any difficulties, please contact us.

    </p>
    <p>
      6.5. The right to restrict the processing. The user has the right to obtain the restriction of processing
      if one of the following cases applies:
      <br />
      • the user contests the accuracy of the data, for a period that allows us to verify the accuracy of the data;
      <br />
      • the processing is illegal, and the user opposes the deletion of personal data, requesting instead the restriction of their use;
      <br />
      • we no longer need the personal data for the purpose of processing, but the user asks us for the establishment, exercise or defense of a right in court;
      <br />
      • the user has opposed the processing, for the period of time in which it is verified whether the
      legitimate rights of the operator prevail over those of the data subject.

    </p>
    <p>
      6.6. The right to dataportability. The user has the right to receive the personal data concerning him/her
      that he/she has provided to the controller in a structured, commonly used and machine-readable
      format and has the right to transmit this data to another operator, without hindrance from the
      operator to whom the personal data have been provided, if:
      <br />
      • the processing is based on consent or on a contract and
      <br />
      • the processing is carried out by automatic means.
      <br />
      In exercising his right to data portability, the user has the right for personal data to be transmitted directly
      from one operator to another where this is technically possible.
      <br />
      If you exercise this right, you will receive by mail a secure link containing all the data thus requested.

    </p>
    <p>
      6.7. The right of opposition. The user has the right to oppose at any time, for well-founded and legitimate
      reasons related to his particular situation, as data aimed at him to be subject to processing, unless
      there are legal provisions to the contrary. In case of justified opposition, the processing can no longer
      concern the data in question.
    </p>

    <p>
      6.8. The right not to be subject to an automated individual decision-making process. Users have the right
      to request and obtain the withdrawal / cancellation / reassessment of any decision that produces legal effects in respect of them,
      adopted exclusively on the basis of a processing of personal data, carried out by automatic means, intended to evaluate some
      aspects of their personality, such as professional competence, credibility, behavior or any other such aspects.
    </p>

    <p>
      6.9. The right to appeal to justice. Without prejudice to the possibility of complaining to the supervisory
      authority regarding the protection of personal data, users have the right to appeal to the courts for
      the defense of any rights guaranteed by law, which have been violated.
      <br />
      If the User has suffered a prejudice as a result of a processing of personal data, carried out illegally, he
      may address the competent court for its repair. For any request related to the use of your personal data,
      you can contact us at:
      <br />
      • Email:			datepersonale@Carfixapp.com
      <br />
      • Phone: 		+40215394444
      <br />
      • Contact address: Bucharest, Nicolae Caramfil nr 71-73, ART BC 5,corp A, etaj 4, sector 1
      <br />
      We encourage you to maintain your personal settings and personal data complete and current. Our team
      will make every effort to solve your requests in the shortest possible time.
      <br />
      According to the legislation in the field of personal data protection, the deadline for response is 30 days.
      If, depending on the complexity of the request, we cannot implement the requested measures within a
      period of less than one month, this period may be extended, according to the applicable legislation, by a
      maximum of 60 days. In this situation, you will receive a notification within a maximum of 30 days from
      the sending of the request, about the reasons for the delay.

    </p>

    <h2>7. Criteria and period of data storage</h2>
    <p>
      7.1. The data associated with the User's account and other data provided through the Application will
      remain in the administrator's possession only for the duration of the existence of the services provided
      and strictly for the compliance with the legal requirements in force (e.g., archiving deadlines) and for the
      protection of the legal rights and interests of the Administrator.
    </p>
    <p>
      7.2. If the User wishes to give up the account, to the extent that the data will no longer be necessary
      to comply with the legal requirements in force and to protect the legal rights and interests of the
      Application/Application Administrator, they will be deleted or anonymized (see point 5.4. The right to
      delete the data ("the right to be forgotten").
    </p>
    <p>7.3. The anonymized data will be used strictly for further statistical analysis.</p>
    <p>
      7.4. The Controller undertakes to take all necessary measures to preserve the personal data in an
      accurate, completed and up-to-date manner, in order to fulfill the purposes for which they were collected.
      Inaccurate or incomplete data will be rectified or removed.
    </p>
    <p>
      7.5. The personal data will be kept only for the period necessary to fulfill the established purpose, in
      compliance with the rights of the data subject provided by the legislation in force.
    </p>
    <h2>8. Connecting other Online Applications/ Platforms</h2>
    <p>
      8.1. In the Application, the User may encounter links to other on-line
      Applications/Applications/Platforms for his/her information. They may operate independently of
      Carfix and may have their own notifications, statements or privacy policies. We recommend that
      you review them to understand how personal data may be processed in connection with those
      Applications, as we are not responsible for the content of Applications that do not belong to or
      are not managed by the Carfix Administrator or for the use or privacy practices of those
      Applications.
    </p>
    <h2>9. Social Networks</h2>
    <p>
      9.1.  Depending on the choices made regarding the settings on different Social Media Applications and/or
      in combination with the settings on the Carfix pages, certain personal data may be shared with Carfix, in
      connection with social activities and profiles. When you participate in various social media forums, such
      as Facebook, Twitter, Yammer, Pinterest, Instagram, LinkedIn, etc., you must be familiar with them and
      understand the tools offered by these Applications that allow you to make choices about how you share
      your personal data on social media profiles.
    </p>
    <p>
      9.2. Carfix is engaged in the privacy practices or policies of these third parties, as well as in our own
      internal rules regarding social media and this personal data processing policy, so read the applicable
      privacy notices, terms of use and related information on how personal data is used in these social media.
    </p>
    <h2>10. Cloud</h2>
    <p>
      10.1. Carfix can store personal data in a cloud. This means that your personal data may be processed on
      behalf of the Controller by a cloud service provider and may be stored in different locations within the
      European Economic Area. The Controller resorts to organizational and contractual measures to protect
      your personal data and to impose similar requirements, but no less restrictive for our cloud service providers, including requirements that personal data be processed exclusively for the purposes indicated
      above.
    </p>
    <h2>11. How we protect your personal data</h2>
    <p>
      11.1. We assume and take seriously our responsibility for the protection of the personal data entrusted
      to us. Thus, in order to avoid loss, misuse or unauthorized access, the Controller uses a variety of security
      technologies and organizational procedures to help protect personal data.
    </p>
    <h2>12. Special information for parents</h2>
    <p>
      12.1. Although Carfix does not target children under 16 years of age, we have the obligation to comply
      with the laws and to seek the permission of parents or legal guardian before collecting, using or disclosing
      information about children.
    </p>
    <p>
      12.2. Carfix strongly recommends that parents take an active role in supervising their children's online
      activities. If you believe that we have collected personal data from persons under the age of 16, please
      contact us at the email address: datepersonale@Carfixapp.com
    </p>
    <h2>13. Changes to the privacy notice</h2>
    <p>
      13.1. The services offered by Carfix are constantly evolving, and the form and type of services offered may
      change from time to time without prior notice. For this reason, we reserve the right to change or add this
      privacy notice from time to time, and any content revisions will be posted on our Applications or sent via
      a push notification through the app or email.
    </p>
    <p>
      13.2. We will post an important notice on the privacy notice page to notify you of any material changes
      to this privacy notice and we will indicate at the top of the announcement the date on which the most
      recent update was made.
    </p>
    <p>
      13.3. The new personal data processing policy will enter into force on the date of posting. If you do not
      agree to the revised notice, you must change your preferences or consider discontinuing the use of the
      Application.
    </p>
    <h2>14. How to contact us</h2>
    <p>
      • Contact us at: Bucharest, Nicolae Caramfil nr 71-73, ART BC 5, building A, 4th floor, sector 1.
      <br />
      • Email: datepersonale@Carfixapp.com after contact@Carfixapp.com
      <br />
      • Phone: +40215394444
    </p>
  </div>
);

const TermsContent = () => (
  <div>
    <h1>TERMENI SI CONDITII DE UTILIZARE</h1>
    <h2>Carfix</h2>
    <h2>Versiunea 3 din data de 25.01.2022</h2>
    <p>
      Va multumim pentru accesarea Aplicatiei Carfix („Aplicatia”), detinuta si administrata de
      {' '}
      <strong>GTM MANAGING SERVICES SA</strong>
      {' '}
      ("Administratorul").
    </p>

    <p>
      Va rugam sa cititi cu atentie acesti termeni si conditii de utilizare ("Termenii") inainte sa utilizati serviciile Aplicatiei.
      Termenii se aplica accesului si utilizarii Aplicatie-ului de catre toti vizitatorii sai.
    </p>

    <h2>DEFINITII</h2>
    <p>
      In cuprinsul prezentului document urmatorii termeni vor fi interpretati astfel:
      {' '}
      <br />
      • Aplicatia: aplicatia Carfix disponibila in magazinul specific sistemului de operare al
      telefonului, iOS — Appstore, Android - Google Play, Huawei App Gallery sau accesibila din
      browser-ul telefonului la adresa webapp.Carfixapp.com, aplicatie apartinand si fiind administrata
      de societatea GTM MANAGING SERVICES SA.
      <br />
      • Servicii: inseamna orice serviciu oferit prin intermediul Aplicatiei si / sau orice modul sau componenta a acestuia.
      <br />
      • Serviciu digital: un serviciu ce prezinta una dintre urmatoarele caracteristici:
      <br />
      a) permite Utilizatorului crearea, prelucrarea, stocarea sau accesarea datelor in format digital;
      <br />
      b) permite schimbul de date in format digital incarcate sau create de Utilizatori ai serviciului
      respectiv sau orice alta interactiune cu aceste date;
      <br />
      •
      {' '}
      <strong>Administratorul Aplicatiei / Administratorul: GTM MANAGING SERVICES SA denumită în continuare, Administrator</strong>
      , cu sediul în București,
      Str. Nicolae Caramfil nr. 71-73, Corp A, Etaj 4, sector 1. J40/1483/2013, CUI RO 32542239;
      <br />
      <strong>Cod RAF:</strong>
      {' '}
      527617
      {' '}
      <strong>valabil</strong>
      {' '}
      până la data
      {' '}
      <strong>11.10.2024</strong>
      <br />
      <strong>Date contact:</strong>
      <br />
      - Telefon +4021.539.4444
      <br />
      - Email: contact@Carfixapp.com
      <br />
      •
      {' '}
      <strong>Utilizator:</strong>
      {' '}
      persoana care acceseaza Aplicatia, in scopuri private sau profesionale si care a acceptat termenii si conditiile de utilizare ale Aplicatiei;
      <br />
      •
      {' '}
      <strong>Continut digital </strong>
      {' '}
      - date produse si furnizate in format digital.
      <br />
      •
      {' '}
      <strong>Integrate </strong>
      {' '}
      - crearea unei legaturi intre Continutul digital sau un Serviciu digital si
      componentele mediului digital al Utilizatorului si incorporarea Continutului digital sau a
      Serviciului digital in acestea, in asa fel incat Continutul digital sau Serviciul digital sa poata
      fi utilizat in conformitate cu prezentul contract.
      <br />
      • Conformitatea Serviciului/Continutul digital: furnizarea Continutul digital sau a Serviciilor
      digitale in conformitate cu cerintele subiective si obiective de conformitate stabilite de art. 6
      si art. 17 din Ordonanta de urgenta nr. 141 din 28 decembrie 2021 privind anumite aspecte
      referitoare la contractele de furnizare de continut digital si de servicii digitale.
    </p>
    <p>
      <strong>Carfix</strong>
      {' '}
      („Aplicatia”), dezvoltata de
      {' '}
      <strong>GTM MANAGING SERVICES S.A.</strong>
      {' '}
      vă pune la dispozitie o solutie care
      permite utilizatorilor:
      <br />
      • să păstreze intr-un singur loc toate datele si documentele aferente autoturismului si soferului
      <br />
      • să configureze alerte pentru reinnoirea documentelor, prelungirea valabilitatii pentru polite de
      asigurare, realizarea verificarilor recurente ITP sau revizie si pentru inlocuirea la expirare a
      extinctorului sau a trusei medicale
      <br />
      • să simplifice si sa incurajeze declararea dosarelor de daune auto, facilitand comunicarea
      Utilizatorilor cu Asiguratorii de pe teritoriul Romaniei, in cadrul unei proceduri de declarare a
      unei daune auto;
      <br />
      • să permita achizitionarea unor polite de asigurare;
      <br />
      • să permita realizarea unei programâri la service;
      <br />
      • să permita achizitionarea de servicii de tractare;
      <br />
      • să permita incheierea unor contracte de inchiriere auto;

    </p>
    <p>
      Carfix va permite sa declarati o dauna auto, sa efectuati o inspectie de risc, sa salvati documente si detalii specifice referitoare
      la vehiculele si la asigurarile dumneavoastra de tip RCA sau Casco, sa achizitionati polite de asigurare, sa inregistrati o programare
      la una din unitatile reparatoare partenere, sa beneficiati de servicii de tractare, sa incheiati contracte de inchiriere auto

    </p>
    <p>
      Prezentul document continand termenii si conditiile de utilizare a Aplicatiei (denumit in continuare "Termeni si Conditii" sau
      "Contractul") stabileste care sunt conditiile in care orice persoana poate utiliza in serviciile oferite prin intermediul Aplicatiei
      ("Utilizator").
    </p>
    <p>
      Aplicatia este administrata de
      {' '}
      <strong>GTM MANAGING SERVICES S.A.</strong>
      {' '}
      denumită în continuare Administrator, cu sediul în București, Str. Nicolae
      Caramfil nr. 71-73, Corp A, Etaj 4, sector 1. J40/1483/2013, CUI RO 32542239.
    </p>
    <p>
      Prezentele Termeni si Conditii reprezinta prevederile in baza carora Utilizatorul poate solicita prin intermediul Carfix prestarea
      Serviciului (definit mai jos) de catre Administrator (definit mai jos).

    </p>
    <p>
      Utilizatorul intelege si accepta in mod expres, in intregime si neconditionat, prevederile acestor Termeni si Conditii (Contractul
      dintre Utilizator si Administratorul Site-ului) si intelege sa utilizeze Serviciul in stricta conformitate cu acestea. Utilizatorii
      care au un cont creat prin intermediul Aplicatiei inteleg si accepta ca utilizarea in continuare a contului lor, precum si orice
      accesare sau vizitare in continuare a Aplicatiei, a oricarei pagini din acesta si/sau utilizarea Serviciului, precum si a oricarei
      componente a acestora constituie o acceptare in intregime si neconditionata a Termenilor si Conditiilor si a oricarei prevederi din
      acestea; neacceptarea Termenilor si Conditiilor de catre Utilizatorii mentionati mai sus atrage obligatia acestora de a inceta de
      indata accesarea Aplicatiei.

    </p>
    <h2>1. INCHEIEREA CONTRACTULUI</h2>
    <p>
      1.1. Inregistrarea si folosirea Aplicatiei constituie consimtamantul expres si neechivoc al Utilizatorului cu privire la incheierea
      Contractului care include prezentele Termene si Conditii si Politica de confidentialitate.
    </p>
    <p>
      1.2. Contractul dintre Administrator si Utilizator se incheie numai daca sunt indeplinite toate conditiile de mai jos:
      <br />
      • Utilizatorul are varsta de minim 18 ani;
      <br />
      • Utilizatorul acceseaza Aplicatia pe aparatele mobile;
      <br />
      • Utilizatorul citeste cu atentie prezentele Termeni si Conditii si Politica de confidentialitate
      si isi exprima consimtamantul cu privire la acestea bifand casuta "Sunt de acord cu Termenii sl
      conditiile si cu Politica de confidentialitate"
      <br />
      • Utilizatorul isi creeaza un cont prin completarea si transmiterea informatiilor reale despre sine, mentionate in formularul de
      aplicare din Aplicatie;
      <br />
      • Utilizatorul acceseaza pentru prima data contul creat.
      <br />
      • Utilizatorul valideaza adresa de e-mail introdusa la crearea contului;
    </p>

    <p>
      1.3. Contractul va fi considerat ca fiind incheiat in momentul in care Utilizatorul bifeaza casuta
      „Sunt de acord cu Termenii si conditiile si cu Politica de confidentialitate” \a momentul crearii
      contului.
    </p>
    <h2>2. UTILIZAREA APLICATIE-ULUI</h2>
    <p>
      2.1. Prin continuarea utilizarii acestei Aplicatii, inseamna ca ati citit si inteles Termenii si Condiitiile de utilizare si ca
      sunteti de acord cu acesti Termeni. Daca nu sunteti de acord cu acesti Termeni, va rugam sa nu accesati sau sa utilizati Aplicatia.
    </p>
    <p>
      2.2. Va incurajam sa verificati frecvent pagina noastra pentru a vedea conditiile actuale in vigoare si orice modificari care le-ar
      fi putut fi aduse.
    </p>
    <p>
      2.3. Daca vom aduce modificari semnificative ale Termenilor, vom posta termenii revizuiti si data la care acestia au fost revizuiti.
      Utilizand Aplicatia ulterior acestor modificari ale Termenilor, acceptati aceste modificari.
    </p>
    <p>
      2.4. Niciun material disponibil in cadrul Aplicatiei nu poate fi copiat, reprodus, republicat, incarcat, postat, transmis sau distribuit
      in vreun fel, cu exceptia cazurilor in care acest lucru este permis in mod specific de Administratorul Aplicatiei.
    </p>
    <p>
      2.5. Termenii si conditiile referitoare la aceasta Aplicatie sunt guvernate de legea romana. Orice litigii decurgand din utilizarea
      Aplicatiei sau in legatura cu acesta vor fi vor fi supuse spre rezolvare instantelor judecatoresti din Romania.
    </p>
    <p>
      2.6. Serviciul de vanzare polițe de asigurare RCA este furnizat de catre GTM Managing Services SA in calitate de asistent în brokeraj,
      în colaborare cu
      {' '}
      <strong>SC INTER BROKER DE ASIGURARE SRL</strong>
    </p>
    <p>
      2.7. Administratorul nu raspunde de nicio pierdere, costuri, procese, pretentii, cheltuieli etc.,
      in cazul in care acestea sunt cauzate direct de nerespectarea Termenilor si conditiilor. Daca
      oricare dintre clauzele de mai sus va fi gasita nula sau invalida, indiferent de cauza, aceasta
      clauza nu va afecta valabilitatea celorlalte clauze ale prezentului Contract.
    </p>
    <h2>3. DESCRIERE FUNCȚIONALĂ A APLICAȚIEI PENTRU MOBIL CARFIX</h2>
    <p>
      3.1. Utilizatorul descarca Aplicația Carfix din magazinul specific sistemului de operare al telefonului, iOS – Appstore, Android - Google Play,
      Huawei App Gallery sau o poate accesa din browser-ul telefonului accesând adresa webapp.Carfixapp.com
    </p>
    <p>
      3.2. La accesarea aplicației, pentru cazul de față, Utilizatorul va selecta rolul Client după care fie se conecteaza la contul deja
      creat în aplicație, fie creaza unul nou.
    </p>
    <p>
      3.3. Pentru conectarea la aplicație este nevoie de adresa de email și parolă. În cazul în care acesta a uitat parola o poate recupera
      apăsând butonul „Am uitat parola” acțiune ce generează un mesaj trimis prin email care conține un link de redefinire a parolei.
      Odată redefinita parola, clientul poate relua pașii de mai sus pentru conectarea la contul Carfix.
    </p>

    <p>
      3.4. Odata conectat la cont, Utilizatorului îi este afisat ecranul „actiuni” unde are posibilitatea :
      <br />
      • sa completeze și sa transmita o notificare de dauna prin apasarea butonului „Notificare dauna”
      <br />
      • sa achizitioneze un pachet de revizii, piese sau servicii de reparatie accesând zonele corespunzatoare din același ecran
      <br />
      • sa achizitioneze o polita de asigurare RCA
      <br />
      • sa inregistreze o programare la una din unitattile reparatoare partenere
      <br />
      • sa achizitioneze servicii de tractare;
      <br />
      • sa incheie un contract de inchiriere auto.

    </p>

    <p>
      3.5. Pentru a notifica o dauna trebuie urmați pașii de mai jos:
      <br />
      • Din ecranul
      {' '}
      <strong><em>“Acasa”</em></strong>
      {' '}
      se selecteaza
      {' '}
      <strong><em>“Notificare daună”</em></strong>
      {' '}
      pentru a incepe procesul de declarare a unei daune, indifferent daca este RCA sau CASCO.
      <br />
      • Se completeaza datele despre asigurare conform indicatiilor de pe ecran.
      <br />
      • Selecție Tip asigurare: RCA sau CASCO
      <br />
      • Alegem asigurătorul din selectorul Asigurator
      <br />
      • Introducem de la tastatură - Numărul de poliță
      <br />
      • Introducem de la tastatură – Serie poliță
      <br />
      • Facem o fotografie cu telefonul Poliței de Asigurare
      <br />
      • Selectie si completare informatii despre soferul pagubit :
      <br />
      • Selecție pentru Persoana care declară dauna : Împuternicit sau Păgubit
      <br />
      • Introducere de la tastatură date de identificare si de contact
      <br />
      • Se incarca documente doveditoare sofer pagubit
      <br />
      • Se completeaza detalii eveniment (localitate, adresa, data, ora, cauza evenimentului)
      <br />
      • Daca au fost implicate 2 masini in eveniment, in pasul urmator se completeaza date despre al doilea sofer implicat in producerea
      evenimentului si masina acestuia alaturi de documente justificative

    </p>

    <p>
      3.6. Utilizatorul are posibilitatea de a selecta detalii despre o masina deja salavata in Aplicatie sau de a adauga detalii despre o
      masina noua conform urmatorilor pasi
      <br />
      • Adăugați un automobil nou completând formularul care se deschide la click pe butonul verde +. Aici se introduce mai întâi seria de șasiu
      după care se apasă butonul “Scaneaza serie sasiu” care deschide un pop-up care perminte fotografierea sau incarcarea unei imagini deja existente
      din telefonul mobil cu seria se sasiu ( VIN ) si apoi se completeaza automat sau manual restul campurilor.
      <br />
      • La pasul 9 apare imaginea 2D a masinii unde trebuie să selectați parțile avariate ale automobilului.
      <br />
      • Selectați parțile afectate in eveniment. Se deschide aparatul foto al telefonului si trebuie sa faceti o fotografie la reperul selectat
      <br />
      • Selectati tipul avariei suferite de piesa respective
      <br />
      • Indicati locul avariei prin atingerea ecranului

    </p>

    <p>
      3.7. Cererea pentru Dosar Dauna trimisă pentru validare poate fi văzută dacă apăsați pictograma
      {' '}
      <strong>Dashboard</strong>
      {' '}
      si apoi unul dintre tab-urile
      <strong>NOI</strong>
      {' '}
      sau
      {' '}
      <strong>IN CURS DE VALIDARE.</strong>
    </p>
    <p>3.8. După validarea cererii trimise, aceasta se mută in tab-ul ÎNCHISE. </p>
    <p>
      3.9. Pentru achizitionarea unei polite de asigurare RCA, utilizatorul trebuie sa parcurga urmatorii pasi :
      <br />
      • Din ecranul “General” se selecteaza “Servicii/Asigurari” pentru a incepe procesul de achizitionare a unei polite de asigurare,
      indifferent daca este RCA sau CASCO
      <br />
      • Se completeaza datele despre obiectul asigurat si proprietar conform indicatiilor de pe ecran
      <br />
      • Alegem polița cea mai avantajoasă din ecranul Oferte
      <br />
      • Efectuam plata online prin modulul integrat in aplicate, funrizat de Banca Transilvania
      <br />
      • Polița de asigurare achiziționată se salvează in aplicație si se transmite prin email la adresa mentionata in formular

    </p>

    <p>
      3.10. In ecranul “Dashboard” Utilizatorul regaseste urmatoarele opțiuni:
      <br />
      • Sectiunea
      {' '}
      <strong><em>“Solicitări”</em></strong>
      {' '}
      conține toate solicitarile, indiferent daca vorbim despre o constatare sau inspectie de risc
      <br />
      • Sectiunea
      {' '}
      <strong><em>“Garaj”</em></strong>
      {' '}
      conține automobilele salvate de Utilizator
      <br />
      •Sectiunea RCA din
      {' '}
      <strong><em>“Garaj”</em></strong>
      {' '}
      contine conține copii ale politelor de asigurare ale Utilizatorului, fotografiate de acesta.
      <br />
      • Sectiunea
      {' '}
      <strong><em>“Documente”</em></strong>
      {' '}
      conține documente relevante pentru Utilizator: Certificatul de înmatriculare al automobilului, Permisul de conducere, Cartea de identitate a masinii,
      certificate inmatriculare si altele
    </p>
    <p>
      3.11. Sectiunea
      {' '}
      <strong><em>“Garajul meu“</em></strong>
      {' '}
      - In aceasta sectiune se pot salva masinile pentru un acces mai facil la informatii si
      completare automata in cazul unei avizari de dauna sau insepctie de risc.
    </p>
    <p>
      3.12. Pentru adaugare masina noua se urmeaza pasii:
      <br />
      • Adăugați un automobil nou completând formularul care se deschide la click pe butonul verde +. Aici se introduce mai întâi seria de șasiu după care se
      apasă butonul “Scaneaza serie sasiu” care deschide un pop-up care perminte fotografierea sau incarcarea unei imagini deja existente din telefonul mobil cu
      seria se sasiu ( VIN ) si apoi se completeaza automat sau manual restul campurilor.
    </p>

    <p>
      3.13. La accesarea unei masini din “garajul meu” exista posibilitatea salvarii documentelor associate acesteia si a altor informatii
      utile (talon, Polița de asigurare RCA: încărcare document cu posibilitate activare notificare expirare document, Polița de asigurare
      facultativa CASCO: încărcare document cu posibilitate activare notificare expirare document, Carte identitate auto: încărcare
      document)
    </p>
    <p>
      3.14. De asemenea, Utilizatorul are posibilitatea de a incarca o serie de documente pe care le considera utile : ITP: încărcare
      document cu posibilitate activare notificare expirare ; Rovinieta: încărcare document cu posibilitate activare notificare expirare ;
      Extinctor: posibilitate activare notificare expirare ; Trusa medicala: posibilitate activare notificare expirare, Revizii:
      posibilitate activare notificare expirare
    </p>
    <p>
      <strong>
        Important : GTM Managing Services SA, prin aplicatia pentru mobil Carfix, nu stocheaza detalii legate de instrumentele de
        plata folosite pentru achizitiile făcute prin aceasta, cum ar fi : numar card, nume deținator, data expirare sau cod de siguranta.
      </strong>

    </p>
    <h2>4. POLITICA DE CONFIDENTIALITATE; INFORMATII UTILIZATORI</h2>
    <p>
      4.1. In cursul utilizarii de catre dvs. a Aplicatiei, vi se poate solicita sa ne furnizati anumite informatii. Utilizarea de catre
      Administrator a oricarei informatii pe care o furnizati va fi guvernata de Politica noastra de confidentialitate disponibila la adresa
      https://webapp.Carfixapp.com/termeni-si-conditii
    </p>
    <h2>5. Furnizarea de continut digital sau de servicii digitale</h2>
    <p>
      5.1. Administratorul furnizeaza Continutul digital sau Serviciul digital fara intarzieri
      nejustificate dupa incheierea contractului. Administratorul isi indeplineste obligatia de furnizare
      atunci cand, in functie de solicitarile Utilizatorului:
      <br />
      a) documentele masinii pot fi incarcate in aplicatie;
      <br />
      b) Utilizatorul este notificat corespunzator in privinta actualizarii documentelor;
      <br />
      c) Aplicatie permite realizarea unei prigramari la service
      <br />
      d) platforma permite incheierea unor polite de asigurare
      <br />
      e) pot fi achizitonare serviciile de tractare ;
      <br />
      f) este permisa incheierea contractelor de inchiriere auto.
    </p>
    <p>
      5.2. Continutul digital sau Serviciul digital este in conformitate cu contractul daca indeplineste
      urmatoarele cerinte, dupa caz:
      <br />
      a) respecta descrierea, cantitatea si calitatea si detine functionalitatea, compatibilitatea, interoperabilitatea;
      <br />
      b) corespunde scopului special pentru care Utilizatorul il solicita, pe care Utilizatorul I-a adus la cunostinta
      Administratorului cel tarziu in momentul incheierii contractului si pe care Administratorul I-a acceptat;
      <br />
      c) este furnizat impreuna cu toate accesoriile, toate instructiunile, inclusiv de instalare, si cu toate serviciile
      de asistenta pentru clienti;
      <br />
      d) este furnizat cu actualizari conform dispozitiilor contractului.
    </p>
    <p>
      5.3. Administratorul este obligat sa respecte si anumite cerinte obiective de conformitate.
      Continutul digital sau Serviciul digital este in conformitate daca:
      <br />
      a) corespunde scopurilor pentru care s-ar utiliza in mod normal;
      <br />
      b) respecta cantitatea si detine calitatile si caracteristicile de
      performanta.
    </p>
    <p>
      5.4. Administratorul asigura informarea Utilizatorului cu privire la faptul ca ii sunt furnizate
      actualizari si ca acestea ii sunt furnizate efectiv, inclusiv actualizari de securitate, care sunt
      necesare pentru a mentine conformitatea continutului digital sau a Serviciului digital.
    </p>
    <p>
      5.5. Nu exista o neconformitate, iar obligatia Administratorului este indeplinita, atunci cand o
      anumita caracteristica a Continutului digital sau a Serviciului digital nu corespunde cerintelor
      obiective daca, la momentul incheierii contractului, Utilizatorul a fost informat in mod explicit
      si intr-un limbaj clar care ii permite sa inteleaga consecintele respectivei neconformitati, iar
      Utilizatorul a acceptat in mod expres si separat acest lucru la momentul incheierii contractului.
    </p>
    <p>
      5.6. In cazul in care vor aparea modificari care ar putea schimba considerabil experienta
      Utilizatorului, Administratorul va anunta Utilizatorul in prealabil, fara a solicita costuri
      suplimentare pentru aceasta. Din acel moment, daca modificarea ar putea avea un impact negativ
      asupra sa, Utilizatorul va avea dreptul de a obtine incetarea contractului sau de a mentine
      continutul sau Serviciul digital fara modificarea in cauza.
    </p>

    <h2>6. TARIFE SI PLATI</h2>
    <p>6.1. Descarcarea Aplicatiei si serviciile prestate catre Utilizatorii care isi creeaza cont sunt gratuite.</p>
    <p>
      6.2. Serviciile pentru listarea Asiguratorilor care doresc sa devina parteneri vor fi taxate conform contractelor incheiate cu acestea.
    </p>

    <p>
      6.3. Avand in vedere ca anumite Servicii disponibile prin intermediul Aplicatiei pot fi oferite contra cost, Utilizatorul se obliga sa
      achite catre Administrator tariful pentru respectivele Servicii, potrivit informatiilor disponibile in cadrul Aplicatiei, in pagina de
      plata. Un Utilizator va putea accesa Serviciile respective conform prevederilor prezentelor Termeni si conditii si conform informatiilor
      disponibile in Aplicatie numai dupa autentificare si plata tarifelor corespunzatoare.
    </p>
    <p>
      6.4. Administratorul nu va pretinde si Utilizatorul nu este obligat sa plateasca vreun tarif suplimentar in afara de cele mentionate pe
      Site. Utilizatorul nu poate cere rambursarea acestui tarif in nicio situatie in care a incalcat oricare din prevederile prezentelor
      Termeni si conditii.
    </p>
    <p>
      6.5. Administratorul Site-ului isi rezerva dreptul de a schimba tarifele pentru oricare dintre Servicii si de a stabili noi tarife in orice
      moment, potrivit propriei sale optiuni. Orice modificare de tarife va deveni operanta imediat dupa notificarea in acest sens a
      Utilizatorului (prin e-mail sau prin afisarea acesteia pe Site, potrivit optiunii Administratorului Site-ului).
    </p>
    <p>
      6.6. Sunt aplicabile urmatoarele reguli pentru transferul de la Utilizator catre Administrator a tarifelor corespunzatoare Serviciilor
      prestate in favoarea Utilizatorului: (a) Utilizatorul va transfera in avans Administratorului contravaloarea Serviciilor, plata sumei
      fiind considerata efectuata doar la momentul la care contul bancar al Administratorului va fi fost creditat cu suma respectiva, iar
      banca va fi confirmat operatiunea prin extras bancar sau alte documente bancare similare eliberate Administratorului; (b) Utilizatorul
      intelege si accepta ca rambursarea de catre Administrator a sumelor platite de acesta catre Administrator pentru serviciile prestate,
      indiferent de motivul invocat sau de cuantumul sumei, nu este posibila.
    </p>
    <p>
      6.7. Utilizatorul intelege si este de acord sa suporte toate taxele si impozitele aplicabile sau orice alte taxe impuse potrivit
      prevederilor legale in materie in legatura cu utilizarea Serviciilor de catre Utilizator, precum si toate comisioanele sau spezele de orice
      fel prevazute in cadrul Aplicatiei sau datorate in orice mod in legatura cu sumele transferate cu orice titlu Administratorului.
    </p>
    <h2>7. DREPTURI DE PROPRIETATE INTELECTUALA</h2>
    <p>
      7.1. Aplicatia, inclusiv continutul sau, cum ar fi textul, imaginile si codul HTML utilizat pentru a genera paginile ("Materiale"),
      sunt proprietatea Administratorului sau a furnizorilor sau licentiatorilor nostri si sunt protejate prin brevete, marci comerciale si / sau
      drepturile de autor. Cu exceptia cazului in care se prevede altfel in prezentul document, nu puteti utiliza, descarca, incarca, copia,
      imprima, afisa, efectua, reproduce, publica, modifica, sterge, adauga, licenta, posta, transmite sau distribui orice Materiale de pe
      aceasta Aplicatie, in intregime sau in parte, pentru orice scop public sau comercial fara permisiunea scrisa specifica a Administratorului.
    </p>
    <p>
      7.2. Fiecare Utilizator beneficiaza de o licenta personala, neexclusiva, netransferabila pentru a accesa Aplicatia si pentru a utiliza
      informatiile si serviciile continute aici. In schimb, ne acordati o licenta neexclusiva, fara redevente, pentru a utiliza orice continut
      pe care il postati pe acest Aplicatie in orice scop, sub rezerva termenilor expresi ai prezentului Acord.
    </p>
    <p>
      7.3. Continutul Aplicati, indiferent de zona in care se afla in Aplicatie si indiferent de tip, poate fi utilizat exclusiv in scopuri
      personale, definite potrivit prezentului document ca fiind scopuri pur necomerciale, fara nicio intentie directa ori indirecta privind
      obtinerea de profituri sau castiguri de orice fel dintr-o asemenea utilizare. Orice utilizare de continut de catre terte persoane in orice
      alte scopuri decat cele personale se poate face numai cu acordul scris, expres si prealabil al Administratorului.
    </p>
    <p>
      7.4. Este interzisa copierea, preluarea, reproducerea, publicarea, transmiterea, vanzarea, distributia totala sau partiala, integrala sau
      modificata a continutului acestei Aplicatii sau a oricarei parti a acesteia efectuate in orice alte scopuri decat cele personale;
      Administratorul isi rezerva dreptul de a actiona in instanta orice persoana si/sau entitate care incalca in orice mod prevederile de mai
      sus.
    </p>
    <p>
      7.5. Cererile de utilizare a continutului Aplicatiei in orice scopuri decat cele personale pot fi trimise la adresa:
      {' '}
      <strong>GTM MANAGING SERVICES</strong>
      SA, cu sediul în București, Str. Nicolae Caramfil nr. 71-73, Corp A, Etaj 4, sector 1. J40/1483/2013, CUI RO 32542239, cu mentiunea
      "Pentru echipa Carfix".
    </p>
    <p>
      7.6. Utilizatorul se va conforma in intregime tuturor prevederilor legale privind dreptul de autor si drepturile conexe, precum si
      oricaror informari si restrictii referitoare la orice Continut accesat in legatura cu Serviciile.
    </p>
    <p>
      7.7. Utilizatorii se obliga sa respecte toate drepturile de autor si drepturile conexe acestuia si orice alte drepturi de proprietate
      intelectuala pe care Adminstratorul si partenerii sai le detin asupra/in legatura cu Aplicatia, continutul acesteia, Serviciul, orice
      modul sau componenenta a acestora sau in legatura cu utilizarea acestora. Utilizatorii inteleg si accepta ca in cazul incalcarii
      vreunei prevederi de mai sus in orice masura, vor putea fi trasi la raspundere fata de autorii/proprietarii Continutului Aplicatiei.
    </p>
    <p>
      7.8. Orice persoana care expediaza in orice mod informatii ori materiale catre Aplicatie isi asuma obligatia de a nu prejudicia in
      nici un mod drepturile de autor pe care o terta persoana le-ar putea invoca in legatura cu materialele si informatiile transmise in
      orice mod catre Aplicatie, iar persoanele care expediaza in orice mod informatii ori materiale inteleg si accepta ca incalcarea in
      orice mod a acestei obligatii nu poate angaja in nici un mod raspunderea Administratorului, ci numai raspunderea persoanelor respective.
      Utilizatorul care furnizeaza materiale / informatii Administratorului declara si garanteaza ca nu incalca si nu a incalcat prin aceasta
      nicio obligatie legala sau contractuala fata de orice terte persoane sau entitati sau orice drepturi pe care le pot invoca acestea,
      inclusiv dar fara a se limita la obligatii de confidentialitate.
    </p>
    <p>
      7.9. Utilizatorii care expediaza in orice mod informatii ori materiale catre Aplicatie sunt de acord sa cedeze neexclusiv, pe termen
      nelimitat si pe toate teritoriile, in momentul trimiterii acestora catre Administrator, toate drepturile de utilizare, adaptare, modificare
      si/sau retransmitere a continutului cu care contribuie sau pe care il posteaza pe Aplicatie. Administratorul nu poate fi considerat
      responsabil pentru eventualele prejudicii cauzate de continutul postat pe Aplicatie de catre orice persoana, indiferent din ce sectiune,
      serviciu sau facilitate face parte respectivul continut.
    </p>
    <p>
      7.10. Este strict interzisa postarea pe Aplicatie a oricarui continut, incluzand materiale, comentarii, imagini, secvente video, dar fara
      a se limita la acestea, care au un limbaj obscen sau vulgar, contin texte ilegale, amenintatoare, abuzive, indecente, mesaje rasiste,
      soviniste ori care ar putea discrimina orice persoane in orice mod sau care ar incalca orice alte drepturi si libertati ale omului
      potrivit legislatiei in vigoare.
    </p>
    <p>
      7.11. Utilizatorii inteleg si accepta ca in cazul incalcarii in orice mod si in orice masura a oricarora din prevederile Termenilor si
      Conditiilor, Administratorul va putea opta in mod discretionar pentru suspendarea totala sau partiala a accesului Utilizatorului la orice
      Serviciu al Aplicatiei, ori pentru nepublicarea oricaruri continut expediat de Utilizator in vederea postarii catre Aplicatie ori pentru
      modificarea in orice mod a unui asemenea continut, pentru interzicerea definitiva a accesului la una sau mai multe dintre facilitatile
      oferite de Aplicatie sau pentru desfiiintarea contului Utilizatorului, fara niciun fel de atentionari sau notificari prealabile si fara
      a fi necesare orice alte formalitati in acest sens.
    </p>
    <h2>8. RASPUNDERE</h2>
    <p>
      8.1. Administratorul raspunde pentru orice nefurnizare a Continutului digital sau a Serviciului
      digital daca aceasta se face cu intarzieri nejustificate dupa incheierea contractului. Se considera
      furnizat Continutul sau Serviciul Site-ului cand devine disponibil sau accesibil pentru Utilizator.
    </p>
    <p>
      8.2. Administratorul raspunde pentru orice neconformitate care exista la momentul furnizarii si
      care este constatata in termen de cinci ani incepand cu data furnizarii. Daca contractul prevede
      furnizarea continua a serviciilor pe parcursul unei anumite perioande, administratorul raspunde
      pentru orice neconformitate care are loc sau este constatata in termenul in care Continutul sau
      Serviciul digital trebuia furnizat.
    </p>
    <p>
      8.3. Se considera ca fiind o neconformitate a Continutului digital sau a Serviciului digital orice
      neconformitate cauzata de Integrarea incorecta a Continutului digital sau a Serviciului digital in
      mediul digital al Utilizatorului, daca este indeplinita una din urmatoarele conditii:
      <br />
      a) Continutul digital sau Serviciul digital a fost integrat de Administrator sau sub raspunderea
      acestuia;
      <br />
      b) Continutul digital sau Serviciul digital a fost destinat sa lie integrat de Utilizator, iar
      integrarea incorecta s-a datorat unor deficiente in instructiunile de integrare furnizate de
      Administrator.
    </p>
    <p>
      8.4. Utilizatorul intelege si accepta ca prin intermediul Serviciilor Administratorul ofera doar o platforma care faciliteaza declarararea
      daunelor auto, efectuarea inspectiilor de risc, achizitionarea politelor de asigurare, beneficiarea de servicii de tractare, incheierea
      unor contracte de inchiriere auto si permite salvarea unor documente si detalii specifice referitoare la vehiculele si asigurari de tip
      RCA sau Casco, astfel ca  are nicio obligatie ori raspundere privind urmatoarele, dar fara a se limita la acestea: Utilizatorii care
      acceseaza Serviciile prin intermediul Aplicatie-ului; continutul fisierelor transmise de Utilizatori catre Administrator; orice
      consecinte care pot deriva din cele mentionate mai sus; actiunile care pot fi / nu pot fi intreprinse de catre oricare Utilizator sau
      oricare persoane, ca rezultat direct sau indirect al utilizarii Serviciilor. Administratorul nu poate fi tras la raspundere in orice mod
      ori masura pentru orice fel de actiuni desfasurate de catre Utilizator si nici pentru utilizarea in orice mod a Serviciilor sau orice
      alta activitate in legatura cu Serviciile. Utilizatorul apeleaza la Servicii pe propria raspundere. Folosirea oricaror informatii
      furnizate prin intermediul Serviciilor va fi facuta de Utilizator in intregime pe propria raspundere.
    </p>
    <p>
      8.5. Utilizatorul intelege si accepta ca prin intermediul Serviciilor Administratorul ofera doar o
      platforma care faciliteaza declarararea daunelor auto, efectuarea inspectiilor de risc,
      achizitionarea politelor de asigurare, beneficiarea de servicii de tractare, incheierea unor
      contracte de inchiriere auto si permite salvarea unor documente si detalii specifice referitoare la
      vehiculele si asigurari de tip RCA sau Casco, astfel ca are nicio obligatie ori raspundere privind
      urmatoarele, dar fara a se limita la acestea: Utilizatorii care acceseaza Serviciile prin
      intermediul Aplicatie-ului; continutul fisierelor transmise de Utilizatori catre Administrator;
      orice consecinte care pot deriva din cele mentionate mai sus; actiunile care pot fi / nu pot fi
      intreprinse de catre oricare Utilizator sau oricare persoane, ca rezultat direct sau indirect at
      utilizarii Serviciilor. Administratorul nu poate fi tras la raspundere in orice mod ori masura
      pentru orice fel de actiuni desfasurate de catre Utilizator si nici pentru utilizarea in orice mod
      a Serviciilor sau orice alta activitate in legatura cu Serviciile. Utilizatorul apeleaza la
      Servicii pe propria raspundere. Folosirea oricaror informatii furnizate prin intermediul
      Serviciilor va fi facuta de Utilizator in intregime pe propria raspundere.
    </p>
    <p>
      8.6. Administratorul nu va fi raspunzator in fata proprietarului / locatorului din cadrul contractului de inchiriere auto sau in fata
      tertilor pentru daune materiale cauzate de Utilizator ca urmare a manevrării defectuoase a autovehiculelor inchiriate respectiv:
      <br />
      • accidente de circulaţie;
      <br />
      • ciocniri, izbiri, rasturnări;
      <br />
      • zgarierea accentuata sau deformarea componentelor de caroserie, spargerea parbrizului, lunetei, geamurilor laterale, farurilor,
      stopurilor sau semnalizatoarelor;

    </p>
    <p>
      8.7. Administratorul nu garanteaza implicit sau expres pentru furnizarea Serviciilor. Administratorul va face toate eforturile
      rezonabile pentru a asigura acuratetea si increderea in Servicii si va incerca sa corecteze erorile si omisiunile cat mai repede posibil.
    </p>
    <p>
      8.8. Serviciile si paginile web aferente acestora sunt puse la dispozitia Utilizatorilor potrivit principiului “asa cum sunt”, “asa cum
      sunt disponibile”, fara nici un fel de garantii, exprese sau implicite, si fara nicio raspundere din partea Administratori in legatura
      cu acestea. Administratorul nu garanteaza in nici un mod sau masura rezultatele utilizarii Serviciilor si Utilizatorul isi asuma toate
      riscurile si intreaga raspundere juridica in legatura cu acestea. In mod expres, Utilizatorii inteleg si accepta ca Administratorul este
      absolvit de orice raspundere pentru orice fel de daune directe, indirecte, rezultate din utilizarea Serviciului sau orice alt aspect in
      legatura cu Serviciul precum si din utilizarea paginilor aferente acestuia in orice mod sau orice efecte juridice derivand din aceasta.
    </p>
    <p>
      8.9. Utilizatorii inteleg si accepta ca Administratorul este absolvit de orice raspundere in eventualitatea producerii oricarei opriri,
      intreruperi, ingreunari, disfunctionalitati sau erori in functionarea Serviciilor si a paginilor web aferente, in situatia unei erori
      tehnice de orice fel sau oricaror erori in furnizarea Serviciului, precum si in orice situatie in care nu ar dovedi cert ca orice erori
      sau probleme de natura tehnica din cele mentionate mai sus se datoreaza in mod direct si exclusiv culpei grave a Administratorului.
      In cazurile de forta majora Administratorul si / sau colaboratorii, operatorii, directorii, angajatii, sucursalele, filialele si
      reprezentantii sai, sunt exonerati total de raspundere. Cazurile de forta majora includ, dar nu se limiteaza la acestea, erori de
      functionare ale echipamentului tehnic al Administratorului, lipsa functionarii conexiunii la internet, lipsa functionarii conexiunilor
      de telefon, virusii informatici, atacurile informatice de orice fel si interferarea cu programe informatice malitioase, accesul
      neautorizat in sistemele Aplicatie-ului, erorile de operare, greva etc.

    </p>
    <p>
      8.10. Utilizatorii inteleg si accepta ca Administratorul este absolvit de orice raspundere in
      eventualitatea producerii oricarei opriri, intreruperi, ingreunari, disfunctionalitati sau erori in
      functionarea Serviciilor si a paginilor web aferente, in situatia unei erori tehnice de orice fel
      sau oricaror erori in furnizarea Serviciului, precum si in orice situatie in care nu ar dovedi cert
      ca orice erori sau probleme de natura tehnica din cele mentionate mai sus se datoreaza in mod direct si exclusiv exclusiv incorectei integrari a
      Continutul digital sau Serviciul digita. In cazurile de forta majora Administratorul si / sau
      colaboratorii, operatorii, directorii, angajatii, sucursalele, filialele si reprezentantii sai,
      sunt exonerati total de raspundere. Cazurile de forta majora includ, dar nu se limiteaza la
      acestea, erori de functionare ale echipamentului tehnic al Administratorului, lipsa functionarii
      conexiunii la internet, lipsa functionarii conexiunilor de telefon, virusii informatici, atacurile
      informatice de orice fel si interferarea cu programe informatice malitioase, accesul neautorizat in
      sistemele Aplicatie-ului, erorile de operare, greva etc.

    </p>
    <h2>9. LEGATURA CU ALTE APLICATIE-URI</h2>
    <p>
      9.1. Utilizatorii inteleg si accepta ca Aplicatia poate contine legaturi sau trimiteri catre alte Aplicatii/ Site-uri de internet,
      care sunt considerate de Administrator utile in legatura cu Continutul Aplicatie, dar care nu se afla sub controlul sau indrumarea sa.
      Administratorul este absolvit de orice raspundere in ceea ce priveste continutul sau opiniile exprimate pe toate Site-urile de internet
      mai sus mentionate, precum si corectitudinea si exactitatea acestora, iar Utilizatorii inteleg si accepta ca aceste Site-uri de internet
      nu sunt monitorizate, controlate sau verificate in orice mod de catre Administrator. Includerea unei legaturi sau trimiteri catre alte
      Aplicatii/ Site-uri de internet nu implica aprobarea in vreun fel a acestora de catre Administrator. In momentul in care Utilizatorii
      acceseaza aceste Aplicatii/ Site-uri de internet, ei o fac pe riscul lor, cunoscand ca utilizarea serviciilor oferite de aceste
      Aplicatii/ Site-uri este supusa conditiilor stabilite de administratorii acestor Aplicatie-uri.
    </p>
    <h2>10. DURATA SI INCETAREA CONTRACTULUI</h2>
    <p>10.1. Contractul se considera incheiat pentru intreaga perioada in care Utilizatorul foloseste Aplicatia</p>
    <p>10.2. Utilizatorul poate inceta folosirea Aplicatiei in orice moment.</p>
    <p>
      10.3. Administratorul isi rezerva dreptul de a suspenda sau inceta imediat Serviciile catre un Utilizator in orice moment si fara
      notificare:
      <br />
      a) in cazul in care constata cu privire la faptul ca acesta nu respecta prevederile prezentelor Termeni si Conditii sau ale altor
      politici publicate in cadrul Aplicatiei;
      <br />
      b) in cazul nefolosirii contului pe o perioada de 24 de luni.
    </p>
    <p>
      10.4. Utilizatorul isi exercita dreptul de a inceta contractul printr-un email catre Administrator
      la adresa contact@Carfixapp.com prin care isi exprima decizia de a inceta contractul, iar
      contractul inceteaza la data transmiterii acestei declaratii.
    </p>
    <p>
      10.5. In cazul incetarii contractului, Administratorul ramburseaza Utilizatorului toate sumele
      platite in temeiul contractului.
    </p>
    <p>
      10.6.  Cu toate acestea, in cazurile in care contractul prevede furnizarea Continutului digital sau
      a Serviciului digital in schimbul platii unui pret si pentru o anumita perioada, iar Continutul
      digital sau Serviciul digital a fost in conformitate pe parcursul unei perioade anterioare datei
      incetarii contractului, Administratorul ramburseaza Utlizatorului numai cota-parte din pretul
      platit care corespunde perioadei in care Continutul digital sau Serviciul digital nu a fost in
      conformitate, precum si orice parte din pretul platit de Utilizator in avans pentru perioada ramasa
      din durata contractului, care ar fi urmat sa se deruleze daca contractul nu ar fi incetat.
    </p>
    <p>
      10.7. Administratorul se abtine de la utilizarea oricarui alt continut in afara datelor cu
      caracter personal care au fost furnizate sau create de Utilizator la utilizarea Continutului
      digital sau a Serviciului digital furnizat de catre Administrator, cu exceptia uneia din
      urmatoarele situatii in care acest continut:
      <br />
      a) nu are nicio utilitate in afara contextului Continutului digital sau al Serviciului digital
      furnizat de Administrator;
      <br />
      b) are legatura doar cu activitatea Utilizatorului atunci cand foloseste Continutul digital sau
      Serviciul digital furnizat de Administrator;
      <br />
      c) a fost agregat cu alte date de catre Administrator si nu poate fi dezagregat sau poate fi
      dezagregat numai cu eforturi disproportionate;
      <br />
      d) a fost generat in comun de catre Utiizator si alte persoane, iar alti Utilizator pot continua sa
      utilizeze continutul.

    </p>
    <p>
      10.8. Administratorul pune la dispozitia Utilizatorului, la cererea acestuia, orice continut care
      nu reprezinta date cu caracter personal, care a fost furnizat sau creat de catre Utilizator in
      momentul utilizarii Continutului digital sau a Serviciului digital furnizat de Administrator, cu
      exceptia situatiilor mentionate la alin.9.6. lit. a)-c), dupa caz.
    </p>
    <p>
      10.9. Utilizatorul are dreptul de a recupera respectivul Continut digital fara costuri si fara
      impedimente din partea Administratorului, intr-un termen rezonabil care nu poate depasi 15 zile
      calendaristice de la data cererii, intr-un format utilizat in mod curent si care poate fi citit
      automat.
    </p>
    <p>
      10.10. Administratorul poate impiedica orice utilizare ulterioara a Continutului digital sau a
      Serviciului digital de catre Utilizator si poate inceta contractul, in special prin oprirea
      accesului Utilizatorului la Continutul digital sau la Serviciul digital sau prin dezactivarea
      contului de utilizator al Utilizatorului, in orice moment si fara notificare:
      <br />
      a) in cazul in care constata cu privire la faptul ca acesta nu respecta prevederile prezentelor
      Termeni si Conditii sau ale altor politici publicate in cadrul Site-ului;
      <br />
      b) in cazul nefolosirii contului pe o perioada de 24 de luni.
    </p>
    <p>
      10.11. Utilizatorul se abtine de la a utiliza Continutul digital sau Serviciul digital si de la a-I
      pune la dispozitie tertilor, dupa incetarea contractului.
    </p>
    <p>
      10.12. Utilizatorul nu este obligat sa plateasca pentru utilizarea Continutului digital sau a
      Serviciului digital in perioada anterioara incetarii contractului si in care Continutul digital sau
      Serviciul digital nu a fost in conformitate.
    </p>
    <h2>11. LEGEA APLICABILA. LITIGIILE</h2>
    <p>
      11.1. Drepturile si obligatiile Utilizatorilor si ale Administratorului, prevazute de Termeni si Conditii, precum si toate efectele
      juridice pe care le produc Termenii si Conditiile vor fi interpretate si guvernate in conformitate cu legea romana in vigoare.
    </p>
    <p>
      11.2. Orice litigiu nascut din sau in legatura cu Termenii si Conditiile va fi solutionat pe cale amiabila. In caz de imposibilitate
      de a ajunge la un acord, litigiul va fi solutionat de catre instanta judecatoreasca romana competenta aflata in raza teritoriala a
      municipiului Bucuresti.
    </p>
    <p>
      11.3. Consumatorii nu pot renunta la drepturile lor conferite de lege. Orice clauze contractuale care inlatura sau limiteaza
      drepturile conferite de lege consumatorilor nu vor produce efecte cu privire la acestia.
    </p>
    <p>
      11.4. Sarcina probei cu privire la furnizarea continutului digital sau a serviciului digital in
      conformitate conform criteriilor regasite in prezentul contract revine Administratorului.
    </p>
    <h2>12. MASURI CORECTIVE IN CAZ DE NEFURNIZARE/NECONFORMITATE</h2>
    <p>
      12.1. In cazul in care Administratorul nu furnizeaza Continutul digital sau Serviciul digital in
      conformitate, Utilizatorul ii solicita Administratorului sa furnizeze Continutul digital sau
      Serviciul digital. Daca, in aceste circumstante, Administratorul nu furnizeaza Continutul digital
      sau Serviciul digital fara intarzieri nejustificate sau intr-un termen suplimentar de 90 de zile,
      astfel cum este prevazut in prezentul contract, Utilizatorul are dreptul sa inceteze contractul.
    </p>
    <p>
      12.2. In caz de neconformitate si doar daca Administratorul nu furnizeaza Continutul digital sau
      Serviciul digital in conformitate ulterior solicitarii Utilizatorului si nici dupa trecerea
      termenului suplimentar de 90 de zile de la data solicitarii, Utilizatorul are dreptul la o reducere
      proportionala a pretului sau la incetarea contractului.
    </p>
    <p>
      12.3. Utilizatorul are dreptul la aducerea in conformitate a Continutului digital sau a
      Serviciului digital, cu exceptia cazului in care acest lucru ar fi imposibil sau ar impune
      Administratorului costuri care ar fi disproportionate, luand in considerare toate circumstantele,
      inclusiv:
      <br />
      a) valoarea Continutului digital sau a Serviciului digital in cazul in care nu ar fi existat
      neconformitatea;
      <br />
      b) gravitatea neconformitatii.
    </p>
    <p>
      12.4. Administratorul aduce Continutul digital sau Serviciul digital in conformitate intr-o
      perioada de timp rezonabila care nu poate depasi 15 zile calendaristice din momentul in care
      Administratorul a fost informat de catre Utilizator cu privire la neconformitate si care este
      stabilit de comun acord, in scris, intre Administrator si Utilizator, fara costuri si fara vreun
      inconvenient semnificativ pentru Utilizator, tinand seama de natura Continutului digital sau a
      Serviciului digital si de scopul pentru care Utilizatorul a solicitat Continutul digital sau
      Serviciul digital.
    </p>
    <p>
      12.5. Utilizatorul are dreptul fie la reducerea proportionala a pretului, in cazul in care
      Continutul digital sau Serviciul digital este furnizat in schimbul platii unui pret, fie la
      incetarea contractului, in oricare dintre urmatoarele cazuri:
      <br />
      a) masura corectiva pentru aducerea in conformitate a Continutului digital sau a Serviciului
      digital este imposibila sau disproportionata;
      <br />
      b) Administratorul nu a adus in conformitate Continutul digital sau Serviciul digital;
      <br />
      c) se constata o neconformitate, in pofida incercarii Adminstratorului de a aduce Continutul
      digital sau Serviciul digital in conformitate;
      <br />
      d) neconformitatea este de o asemenea gravitate incat justifica o reducere imediata de pret sau
      incetarea imediata a contractului;
      <br />
      e) Administratorul a declarat ca nu va aduce Continutul digital sau Serviciul digital in
      conformitate, ori acest lucru reiese clar din circumstantele cazului.
    </p>
    <p>
      12.6. Reducerea de pret este proportionala cu diminuarea valorii Continutului digital sau a
      Serviciului digital furnizat Utilizatorului in comparatie cu valoarea pe care Continutul digital
      sau Serviciul digital ar avea- o daca ar fi in conformitate.

    </p>
    <p>
      12.7. Atunci cand contractul prevede ca furnizarea Continutului digital sau a Serviciului digital
      se face pe parcursul unei anumite perioade in schimbul platii unui pret, reducerea de pret se
      aplica pentru perioada in care Continutul digital sau Serviciul digital nu a fost in conformitate.

    </p>
    <p>
      12.8. Atunci cand Continutul digital sau Serviciul digital este furnizat in schimbul platii unui
      pret, Utilizatorul are dreptul de a obtine incetarea contractului numai daca neconformitatea nu
      este minora.
    </p>
    <p>
      12.9. Sarcina probei cu privire la caracterul minor al neconformitatii revine Administratorului,
      in cazul prevazut la alin. 10.8.
    </p>
    <p>
      12.10. In caz de neconformitate, Utilizatorul are dreptul de a suspenda plata unei parti restante
      din pretul Continutului sau Serviciului digital sau a unei parti a acesteia pana la aducerea in
      conformitate de catre Administrator a Continutului digital sau a Serviciului digital, cu conditia
      ca plata suspendata sa nu fie aferenta unui Continut digital sau Serviciu digital deja furnizat de
      catre Administrator si care a fost conform.
    </p>
    <h2>13. TERMENE SI MODALITATI DE RAMBURSARE DE CATRE ADMINISTRATOR</h2>
    <p>
      13.1. Orice rambursare pe care Adiministratorul o datoreaza Utilizatorului ca urmare a unei
      reduceri de pret sau a incetarii contractului este efectuata fara intarzieri nejustificate si, in
      orice caz, in termen de 14 zile de la data la care Administratorul este informat cu privire la
      decizia Utilizatorului de a invoca dreptul la reducerea pretului sau dreptul sau de a inceta
      contractul.
    </p>
    <p>
      13.2. Administratorul efectueaza rambursarea utilizand aceleasi mijloace de plata pe care Ie-a
      utilizat Utilizatorul pentru plata Continutului digital sau a Serviciului digital, cu exceptia
      cazului in care Utilizatorul si-a dat acordul expres pentru utilizarea altor mijloace de plata si
      cu conditia ca rambursarea sa nu implice plata unor comisioane de catre Utilizator.
    </p>
    <p>
      13.3. Administratorul efectueaza rambursarea utilizand aceleasi mijloace de plata pe care Ie-a
      utilizat Utilizatorul pentru plata Continutului digital sau a Serviciului digital, cu exceptia
      cazului in care Utilizatorul si-a dat acordul expres pentru utilizarea altor mijloace de plata si
      cu conditia ca rambursarea sa nu implice plata unor comisioane de catre Utilizator.
    </p>

    <h2>14. Reguli, limite si modificari ale Aplicatiei</h2>

    <p>
      14.1. Administratorul are dreptul de a modifica oricand si in orice mod oricare dintre prevederile din Termeni si Conditii sau
      Termenii si Conditiile in intregime, fara nicio notificare prealabila si fara a fi obligat sa indeplineasca vreo alta formalitate
      fata de Utilizatori.
    </p>
    <p>
      14.2.Orice modificare va fi considerata ca fiind acceptata deplin si neconditionat de catre oricare dintre Utilizatori prin simpla
      utilizare sau accesare a oricarei facilitati oferite de Aplicatie sau de Serviciu ori prin accesarea Aplicatiei, intervenite oricand
      dupa operarea modificarii, iar neacceptarea oricarei modificari atrage obligatia respectivului Utilizator de a inceta de indata
      accesarea Aplicatiei si/sau utilizarea in orice mod a Serviciului.
    </p>
    <p>
      14.3. Administratorul Aplicatiei poate cesiona oricand catre un tert drepturile si obligatiile izvorate din prevederile acestor Termeni
      si Conditii, inclusiv prin cesionarea catre un tert a Contractului (Termeni si Conditii), iar Utilizatorul consimte anticipat la aceasta
      cesiune, conform dispozitiilor art. 1315 si urmatoarele din Codul Civil. Pentru a inlatura orice indoiala, utilizatorii Aplicatiei consimt
      anticipat la substituirea GTM MANAGING SERVICES SA cu un tert in raporturile nascute intre acesta si Utilizatori in temeiul prevederile
      acestor Termeni si Conditii.
    </p>
    <p>14.4. Utilizatorul este sfatuit sa verifice periodic prezentele Termene si Conditii.</p>
    <p><em>	Versiune din data de 25.01.2022.</em></p>
  </div>
);

const TermsContentEn = () => (
  <div>
    <h1>TERMS AND CONDITIONS OF USEE</h1>
    <h2>Carfix</h2>
    <h2>
      version 3 from 25.01.2022
    </h2>
    <p>
      Thank you for accessing the Carfix application owned and managed by
      {' '}
      <strong>GTM MANAGING SERVICES SA</strong>
      {' '}
      (the "Administrator").
    </p>

    <p>
      Please read these terms and conditions of use (the "terms") carefully before using the application services. The terms apply to access to and
      use of the application by all its visitors.
    </p>

    <h2>DEFINITIONS</h2>
    <p>
      Throughout this document the following terms shall be interpreted as follows:
      {' '}
      <br />
      • Application: The Carfix application available in the phone operating system store, iOS – AppStore, Android - Google play, Huawei App Gallery or
      accessible from the phone browser at webapp.Carfixapp.com, which is owned and managed by GTM MANAGING SERVICES SA company.
      <br />
      • Services: Means any service provided through the application and/or any module or component thereof.
      <br />
      • Digital service: A service having one of the following characteristics:
      <br />
      a) enable the user to create, process, store or access data in digital format;
      <br />
      b) allow the exchange of data in digital format loaded or created by users of the service concerned or any other interaction with such data;
      <br />
      •
      {' '}
      <strong>Application Administrator / Administrator: GTM MANAGING SERVICES SA referred to below as Administrator</strong>
      , based in Bucharest, Str. Nicolae Caramfil nr. 71-73, Corp A, floor 4, sector 1. J40/1483/2013, CUI RO 32542239;
      <br />
      <strong>RAF code:</strong>
      {' '}
      527617
      {' '}
      <strong>valid </strong>
      {' '}
      until
      {' '}
      <strong>11.10.2024</strong>
      <br />
      <strong>Date contact:</strong>
      <br />
      - Telefon +4021.539.4444
      <br />
      - Email: contact@Carfixapp.com
      <br />
      •
      {' '}
      <strong>User:</strong>
      {' '}
      The person who accesses the application for private or professional purposes and who has accepted the application's terms and conditions of use;
      <br />
      •
      {' '}
      <strong>Digital content </strong>
      {' '}
      - data produced and supplied in digital format.
      <br />
      •
      {' '}
      <strong>Integration </strong>
      {' '}
      - the creation of a link between the digital content or a digital service and the components of the user's digital environment and the incorporation
      of the digital content or the digital service into them, in such a way that the digital content or the digital service may be used in accordance with
      this contract.
      <br />
      • Service compliance/Digital content: The supply of digital content or digital services in accordance with the subjective and objective compliance
      requirements laid down in Articles 6 and 17 of Emergency Ordinance No 141 of 28 December 2021 on certain aspects relating to contracts for the
      supply of digital content and services.
    </p>
    <p>
      <strong>Carfix</strong>
      {' '}
      ("application"), developed by
      {' '}
      <strong>GTM MANAGING SERVICES S.A.</strong>
      {' '}
      provides a solution that allows users to:

      <br />
      • keep all data and documents relating to the car and driver in one place
      <br />
      • set up alerts for document renewal, extension of validity for insurance policies, performing recurrent technical inspections or overhaul checks,
      and replacing the fire extinguisher or medical kit on expiry
      <br />
      • to simplify and encourage the declaration of car claims files, facilitating the communication of users with the insurers on the territory of Romania,
      within the framework of a procedure for the declaration of car damage;
      <br />
      • to allow the purchase of insurance policies;
      <br />
      • allow an appointment to be made at the workshops
      <br />
      • to allow the purchase of towing services;
      <br />
      • to allow the purchase of car rental services;

    </p>
    <p>
      Carfix allows you to notify a car damage, perform a risk inspection, save documents and specific details concerning your car and insurance type RCA or Casco,
      purchase insurance policies, record an appointment with one of the partner repair units, get towing services, sign car rental agreements

    </p>
    <p>
      This document containing the application's terms and conditions of use (hereinafter referred to as "terms and conditions" or "the contract") sets out the conditions
      under which any person may use the application's services ("User").
    </p>
    <p>
      The application is managed by
      {' '}
      <strong>GTM MANAGING SERVICES S.A.hereinafter referred to as Administrator</strong>
      {' '}
      , based in Bucharest, Str. Nicolae Caramfil nr. 71-73, Corp A, floor 4, sector 1. J40/1483/2013, CUI RO 32542239.
    </p>
    <p>
      These terms and conditions are the provisions under which the user can request through Carfix the provision of the Service (defined below) by the Administrator
      (defined below).

    </p>
    <p>
      The User expressly understands and accepts, in full and unconditionally, the provisions of these terms and conditions (the contract between the User and the
      Site Administrator) and agrees to use the Service in strict compliance with them. Users who have an account created through application understand and accept
      that the continued use of their account, as well as any further access to or visit the application, any page thereof and/or use of the Service, And any
      component thereof shall constitute a full and unconditional acceptance of the terms and conditions and any provisions thereof; The failure of the
      above-mentioned users to accept the terms and conditions entails their obligation to cease access to the application as soon as possible.

    </p>
    <h2>1. CONCLUSION OF THE CONTRACT</h2>
    <p>
      1.1. The registration and use of the application constitute the express and unequivocal consent of the user to the conclusion of the contract which includes
      these terms and conditions and the Privacy Policy.
    </p>
    <p>
      1.2. The administrator-user contract shall be concluded only if all of the following conditions are met:
      <br />
      • The user is at least 18 years old;
      <br />
      • The user accesses the application on mobile devices;
      <br />
      • The user carefully reads these terms and conditions and Privacy Policy and consents to them by checking the "I agree to the terms and conditions and Privacy Policy" box.
      <br />
      • The user creates an account by completing and submitting the actual self-information specified in the application form in the application;
      <br />
      • The user accesses the created account for the first time.
      <br />
      • The user validates the e-mail address entered when the account was created;
    </p>

    <p>
      1.3. The contract will be deemed to have been concluded when the user ticks the "I agree to the terms and conditions and Privacy Policy" box at
      the time the account is created.
    </p>
    <h2>2. USE OF THE APPLICATION</h2>
    <p>
      2.1. By continuing to use this application, it means that you have read and understood the terms and conditions of use and that you agree to these terms.
      If you do not agree to these terms, please do not access or use the application.
    </p>
    <p>
      2.2. We encourage you to check our page frequently to see the current conditions and any changes that may have been made to them.
    </p>
    <p>
      2.3. If we make significant changes to the terms, we will post the revised terms and the date on which they were revised. By using the application later on
      to these changes to the terms, you accept these changes.
    </p>
    <p>
      2.4. No material available in the application may be copied, reproduced, republished, loaded, posted, Transmitted or distributed in any way, unless specifically permitted
      by the application Manager.
    </p>
    <p>
      2.5. The terms and conditions related to this application are governed by Romanian law. Any disputes arising from or in connection with the use of the application
      will be submitted to the Romanian courts for resolution.
    </p>
    <p>
      2.6. The sales service RCA insurance policies is provided by GTM Managing services SA as an assistant in brokerage, in collaboration with
      {' '}
      <strong>SC INTER BROKER INSURANCE SRL</strong>
    </p>
    <p>
      2.7. The administrator is not liable for any loss, costs, processes, claims, expenses, etc., if these are directly caused by non-compliance with the terms and conditions.
      If any of the above clauses is found null and void, whatever the cause, this clause shall not affect the validity of the other clauses of this contract.
    </p>
    <h2>3. FUNCTIONAL DESCRIPTION OF THE CARFIX MOBILE APPLICATION</h2>
    <p>
      3.1. The user downloads the Carfix app from the phone's operating system store, iOS – AppStore, Android - Google play, Huawei App Gallery, or can access it
      from the phone's browser at webapp.Carfixapp.com
    </p>
    <p>
      3.2. When accessing the application, for this case, the user will select the Client role and then either log in to the account already created in the
      application or create a new one.
    </p>
    <p>
      3.3. Email address and password are required to log in to the application. If he forgot his password he can retrieve it by pressing the "Forgot password"
      button, which generates an email message that contains a link to redefine the password. Once the password is redefined, the customer can resume the above
      steps to log in to the Carfix account.
    </p>

    <p>
      3.4. Once connected to the account, the user is redirected. to the "actions" screen where he can :
      <br />
      • Complete and submit a damage notification by clicking on the "notify damage" button
      <br />
      • Purchase a package of service, parts or repair services by accessing the appropriate areas of the same screen
      <br />
      • To acquire a RCA insurance policy
      <br />
      • Record an appointment at one of the partner repair units
      <br />
      • To acquire towing services;
      <br />
      • To sign a car rental contract.

    </p>

    <p>
      3.5. To notify a loss you must follow the steps below:
      <br />
      • From the
      {' '}
      <strong><em>Home</em></strong>
      {' '}
      screen, select
      {' '}
      <strong><em>“damage notification”</em></strong>
      {' '}
      to begin the claim declaration process, otherwise if it is RCA or CASCO.
      <br />
      • Complete the insurance data as indicated on the display.
      <br />
      • Insurance Type selection: RCA or CASCO
      <br />
      • We choose the insurer from the insurer selector
      <br />
      • Enter the policy number from the keypad
      <br />
      • Input from the keyboard - policy series
      <br />
      • We take a picture of the insurance policy phone
      <br />
      • Selection and additional information about the damaged driver
      <br />
      • Selection for the person who declares the damage : empowered or damaged
      <br />
      • Input of identification and contact information from the keyboard
      <br />
      • Loading the damaged driver's supporting documents
      <br />
      • Enter event details (city, address, date, time, cause of event)
      <br />
      • If 2 machines were involved in the event, in the next step data on the second driver involved in the event is completed and his machine together
      with supporting documents

    </p>

    <p>
      3.6. The user has the possibility to select details about a machine already salvated in the application or to add details about a new machine according
      to the following steps:
      <br />
      • Add a new car by filling in the form that opens by clicking on the green + button. This is the first entry of the chassis series and press the
      {' '}
      <strong><em>“Scan VIN” </em></strong>
      button that opens a pop-up that cushions taking pictures or loading an already existing mobile phone image with the SE chassis series ( VIN ) and then the
      remaining fields are completed automatically or manually.
      <br />
      • Step 9 shows the 2D image of the car where you need to select the damaged car parents.
      <br />
      • Select the affected parents in the event. The camera on your phone opens and you need to take a photo at the selected marker
      <br />
      • Select the type of damage suffered by the relevant part
      <br />
      • Indicate the location of the damage by touching the screen

    </p>

    <p>
      3.7. The damage folder request sent for validation can be seen by pressing the
      {' '}
      <strong><em>Dashboard icon</em></strong>
      {' '}
      and then pressing one of
      <strong><em>THE NEW</em></strong>
      {' '}
      or validation tabs.
    </p>
    <p>3.8. Once the submitted request is validated, it moves to THE CLOSED tab. </p>
    <p>
      3.9. In order to acquire an RCA insurance policy, the user must complete the following steps :
      <br />
      • From the “General” screen, select “services/Insurance” to begin the acquisition process of an insurance policy, which is not a RCA or CASCO
      <br />
      • Complete the data on the subject and owner as indicated on the screen
      <br />
      • We choose the best policy from the offers screen
      <br />
      • We make the payment online through the integrated module in applied, funded by Banca Transilvania
      <br />
      • The insurance policy purchased is saved in the application and sent by email to the address mentioned on the form

    </p>

    <p>
      3.10. In the “Dashboard” screen the user will retrieve the following options:
      <br />
      • The "requests" section contains all requests, whether we are talking about a finding or a risk inspection
      <br />
      • The "garage" section contains the vehicles saved by the user
      <br />
      • The RCA section of the "Garage" contains copies of the user's insurance policies, photographed by the user.
      <br />
      • The "documents" section contains relevant documents for the user: Car registration certificate, driving license, car identity card, registration
      certificates and more
    </p>
    <p>
      3.11.
      {' '}
      <strong><em>“"Garage" section“</em></strong>
      {' '}
      -  in this section you can save machines for easier access to information and automatic filling in the event of damage or risk advice.
    </p>
    <p>
      3.12. To add the new CAR, follow the steps below:
      <br />
      • Add a new car by filling in the form that opens by clicking on the green + button. This is the first entry of the chassis series and press
      the
      {' '}
      <strong><em>“Scan chassis series” </em></strong>
      button that opens a pop-up that cushions taking pictures or loading an already existing mobile phone image with the SE
      chassis series ( VIN ) and then the remaining fields are completed automatically or manually.
    </p>

    <p>
      3.13. When accessing a car from “My garage”, it is possible to save the associated documents and other useful information (coupon, RCA insurance policy:
      Loading document with the possibility of activating document expiry notification, optional insurance policy CASCO: Loading document with possibility of
      activating document expiry notification, Card identity card: Loading document)
    </p>
    <p>
      3.14. The user also has the possibility to load a series of documents that they consider useful : ITP:
      Loading document with the possibility of activating the expiry notification ; Rovette: Loading document
      with expiry notification enabled ; Fire extinguisher: Possibility of activation of expiry notification ; Medical
      kit: Enable expiry notification, overhauls: Enable expiry notification

    </p>
    <p>
      <strong>
        Important : GTM Managing services SA, through the mobile application Carfix, does not store details of the payment instruments used for the purchases made
        through it, such as : Card number, owner name, expiration date or security code.
      </strong>

    </p>
    <h2>4. CONFIDENTIALITY POLICY; USER INFORMATION</h2>
    <p>
      4.1. In the course of your use of the application, you may be asked to provide us with certain information. The use by the administrator of any information
      you provide shall be governed by our privacy policy available
      at https://webapp.Carfixapp.com/termeni-si-conditii
    </p>
    <h2>5. THE PROVISION OF DIGITAL CONTENT OR SERVICES</h2>
    <p>
      5.1. The administrator shall provide the digital content or the Digital Service without undue delay after the conclusion of the contract. The administrator
      shall fulfill his obligation to supply when, as required by the user:
      <br />
      a) the machine documents can be loaded into the application;
      <br />
      b) The user is duly notified of the updating of the documents;
      <br />
      c) Application allows a service record to be made
      <br />
      d) the platform allows for the conclusion of insurance policies
      <br />
      e) towing services may be purchased;
      <br />
      f) the conclusion of car rental agreements is allowed.
    </p>
    <p>
      5.2. The digital content or the digital service is in conformity with the contract if it meets the following requirements, as appropriate
      <br />
      a) complies with the description, quantity and quality and has functionality, compatibility, interoperability;
      <br />
      b) correspond to the particular purpose for which the user requests, which the user has notified the administrator at the latest at the time of the conclusion
      of the contract and which the administrator has accepted;
      <br />
      c) be provided with all accessories, instructions, including installation instructions, and customer support services;
      <br />
      d) is supplied with updates in accordance with the provisions of the contract.
    </p>
    <p>
      5.3.  The administrator is also obliged to comply with objective compliance requirements. The digital content or the Digital Service complies if:
      <br />
      a)  it corresponds to the purposes for which it would be normally used;
      <br />
      b) respect the quantity and possess the qualities and performance characteristics.
    </p>
    <p>
      5.4. The administrator shall ensure that the user is informed that updates are provided to him or her and are effectively provided, including security updates, which
      are necessary to maintain compliance of the digital content or the digital service.
    </p>
    <p>
      5.5. There is no non-conformity and the administrator's obligation is fulfilled, where a particular characteristic of the digital content or the digital service does
      not meet objective requirements if, at the time of the conclusion of the contract, the user has been explicitly informed in clear language which allows him to understand
      the consequences of the non-compliance, And the user expressly and separately accepted this at the time of the conclusion of the contract.
    </p>
    <p>
      5.6. If changes occur that could significantly change the user's experience, the administrator will notify the user in advance without incurring any additional costs
      for this. From then on, if the modification could have a negative impact on it, the user will have the right to terminate the contract or to maintain the content or the digital service without the modification.
    </p>

    <h2>6. TARIFFS AND PAYMENTS</h2>
    <p>6.1. The application download and the services provided to users who create their account are free of charge.</p>
    <p>
      6.2. 	Services for listing insurers who wish to become partners will be charged according to their contracts.
    </p>

    <p>
      6.3. As certain services available through the application can be offered for payment, the user undertakes to pay the administrator the fee for those services, according
      to the information available in the application, on the payment page. A user will be able to access these services under these terms and conditions and according
      to the information available in the application only after authentication and payment of the appropriate charges
    </p>
    <p>
      6.4. The administrator will not claim and the user is not obliged to pay any additional charges other than those mentioned on the Site. The user may not request reimbursement
      of this rate in any event in breach of any of these terms and conditions
    </p>
    <p>
      6.5.The site administrator reserves the right to change fares for any of the services and to set new fares at any time, in accordance with its own option. Any change
      in rates will become effective immediately upon notice to the user (by e-mail or by posting it on the Site, as per the site administrator option)
    </p>
    <p>
      6.6. The following rules shall apply to the transfer from the user to the administrator of charges corresponding to services provided to the user: (A) the user will
      transfer the value of the services to the administrator in advance, payment of the amount being deemed to have been made only at the time when the administrator's bank account has been credited with that amount and the bank will be confirmed by bank extracts or other similar bank documents issued to the administrator; (B) the user understands and accepts that it is not possible for the administrator to refund the amounts paid by him to the administrator for the services provided, irrespective of the reason invoked or the amount of the sum
    </p>
    <p>
      6.7. The user understands and agrees to bear any applicable taxes, duties or other charges imposed in accordance with the relevant legal provisions in connection
      with the use of the services by the user, As well as any fees or charges of any kind provided for in the application or due in any way in respect of the amounts
      transferred to the administrator in any title.
    </p>
    <h2>7. INTELLECTUAL PROPERTY RIGHTS</h2>
    <p>
      7.1. The application, including its content, such as text, images and HTML code used to generate the pages ("materials"), is the property of our administrator
      or suppliers or licensors and is protected by patents, trademarks and/or copyrights. Unless otherwise provided herein, you may not use, download, load, copy,
      print, display, perform, reproduce, publish, modify, delete, add, license, post, Transmit or distribute any material on this application, in whole or in part
      , for any public or commercial purpose without the specific written permission of the administrator.
    </p>
    <p>
      7.2. Each user has a non-exclusive, non-transferable personal license to access the application and to use the information and services contained herein.
      Instead, you grant us a non-exclusive license, without royalties, to use any content you post to this application for any purpose, subject to the express
      terms of this Agreement
    </p>
    <p>
      7.3.The content applied, regardless of the area in which it is located and regardless of its type, may be used exclusively for personal purposes, defined
      in accordance with this document as being purely non-commercial purposes, without any direct or indirect intention to obtain profits or any gain from such
      use. Any use of content by third parties for any purposes other than personal use may be made only with the written, express and prior consent of the
      administrator.
    </p>
    <p>
      7.4. Copying, taking over, reproduction, publication, transmission is prohibited, The sale, distribution in whole or in part, in whole or in part of the
      contents of this application or any part thereof for purposes other than personal use; the administrator reserves the right to take legal action against
      any person and/or entity in any way that violates the above provisions

    </p>
    <p>
      7.5. Requests for use of the content of the application for any purposes other than personal ones can be sent to: GTM MANAGING SERVICES SA, based in Bucharest,
      Str. Nicolae Caramfil nr. 71-73, Corp A, floor 4, sector 1. J40/1483/2013, CUI RO 32542239, with the mention "for the Carfix team".
    </p>
    <p>
      7.6. The user shall comply fully with all legal provisions concerning copyright and related rights, as well as any information and restrictions relating
      to any content accessed in connection with the services.
    </p>
    <p>
      7.7. Users shall respect all copyright and related rights and any other intellectual property rights that the Adminstrator and its partners hold in or
      in connection with the application, its content, the Service, any module or composition thereof or in connection with its use. Users understand and accept
      that in the event of a breach of any of the above provisions, they will be liable to the authors/owners of the application content.
    </p>
    <p>
      7.8.  Any person who in any way sends information or material to the application undertakes not to prejudice in any way the copyright which a third party
      may invoke in relation to the materials and information transmitted in any way to the application, And persons who send information or material in any way
      understand and accept that any breach of this obligation in any way may not assume the responsibility of the administrator, but only of the persons concerned.
      The user who provides material/information to the administrator declares and warrants that he does not breach, and has not thereby violated, any legal or
      contractual obligation to any third person or entity or any rights that they may invoke, including without limitation to confidentiality obligations.
    </p>
    <p>
      7.9. Users who in any way send information or material to the application agree to surrender, on an unlimited basis and in all territories, non-exclusive
      basis, at the time of sending to the administrator all rights of use, adaptation, Change and/or retransmit the content it contributes to or posts on the
      application. The administrator cannot be held responsible for any damage caused by the content posted on the application by any person, regardless of which
      section, service or facility the content is part.
    </p>
    <p>
      7.10. Posting on the application of any content, including materials, comments, images, video sequences, is strictly prohibited, but not limited to these,
      which have obscene or vulgar language, contain illegal, threatening, abusive, indecent texts. racist, chauvinist messages that could discriminate against
      any person in any way or that would violate any other human rights and freedoms according to the legislation in force.
    </p>
    <p>
      7.11. Users understand and accept that in the event of any breach in any way and to any extent of any of the terms and conditions, the administrator will
      be able to choose in a discretionary manner to suspend all or part of the user's access to any application service, Or for not publishing any content submitted
      by the user for posting to the application or otherwise modifying such content, for permanently prohibiting access to one or more of the facilities offered by
      the application or for discarding the user's account, without any prior notice or notification and without any further formalities to that effect
    </p>
    <h2>8. LIABILITY</h2>
    <p>
      8.1. The administrator shall be responsible for any failure to supply the digital content or the Digital Service if this is done with undue delay after the
      conclusion of the contract. Content or Service of the site is deemed to be provided when it becomes available or accessible to the user.
    </p>
    <p>
      8.2. The administrator shall be responsible for any non-compliance which exists at the time of the supply and is established within five years of the
      date of the supply. If the contract provides for the continuous provision of the services during a given period, the administrator shall be liable for
      any lack of conformity which occurs or is established within the period within which the content or the Digital Service was to be provided.
    </p>
    <p>
      8.3. Non-conformity of the digital content or the Digital Service shall be deemed to be non-conformity due to the incorrect integration of the digital
      content or the Digital Service into the user's digital environment if one of the following conditions is fulfilled:
      <br />
      a) the digital content or the digital service has been integrated by or under the responsibility of the administrator;
      <br />
      b) the digital content or the Digital Service was intended to be integrated by the user, and incorrect integration was due to shortcomings in the integration
      instructions provided by the administrator.
    </p>
    <p>
      8.4. The user understands and accepts that through the services the manager offers only a platform which facilitates the declaration of car damage, the
      performance of risk inspections, the acquisition of insurance policies, the provision of towing services, The conclusion of car rental contracts and allows
      the saving of specific documents and details regarding car or Coco vehicles and insurance, so it has no obligation or liability for, but is not limited to,
      the following: The content of the files transmitted by the users to the administrator; Any consequences which may derive from the above; actions which may/may
      not be taken by any user or any person as a direct or indirect result of the use of the services. The administrator may not be held liable in any manner
      or measure for any actions performed by the user, or for any use of the services or any other activity related to the services. The user calls on the
      services at their own responsibility.
    </p>
    <p>
      8.5. The user understands and accepts that through the services the manager offers only a platform which facilitates the declaration of car damage, the
      performance of risk inspections, the acquisition of insurance policies, the provision of towing services, Conclusion of car rental contracts and allows
      saving of specific documents and details concerning car or Casco vehicles and insurance, so has no obligation or liability for, but not limited to,
      the following: Users who access the services through the application; Contents of files sent by users to the administrator; any consequences that may
      derive from the above; actions that can/cannot be taken by any user or any person as a direct or indirect result of using the services. The administrator
      may not be held liable in any manner or measure for any actions performed by the user, or for any use of the services or any other activity related
      to the services. The user calls on the services at their own responsibility. Use of any information provided through the services will be made by
      the user entirely at his or her own responsibility.

    </p>
    <p>
      8.6. The administrator shall not be liable to the owner/lessor under the car lease or to third parties for any material damage caused by the user as
      a result of the improper handling of the rented vehicles in question:
      <br />
      • traffic accidents;
      <br />
      • hammers, knocks, overturns;
      <br />
      • sharp scratching or deformation of bodywork components, breakage of windscreen, rear window, side windows, headlights, stop lights or indicators

    </p>
    <p>
      8.7. The administrator does not implicitly or expressly warrant the provision of the services. The administrator shall make all reasonable efforts
      to ensure accuracy and confidence in the services and shall endeavor to correct errors and omissions as soon as possible.
    </p>
    <p>
      8.8. The services and web pages attached thereto are made available to users according to the principle “as they are”, “as available”, without any
      warranty, express or implied, and without liability of administrators in connection with them. The administrator shall not warrant in any way or measure
      the results of the use of the services and the user shall assume all risks and all liability relating to them. Users expressly understand and accept that
      the Administrator is made responsible for any direct, indirect, consequential damages arising out of the use of the Service or any other matter in connection
      with the Service and from the use of its pages in any manner or any legal effect arising therefrom.
    </p>
    <p>
      8.9.Users understand and accept that the Administrator is discharge of all liability in the event of any stoppage, interruption, disruption, malfunction
      or failure in the operation of the services and related web pages in the event of technical failure of any kind or failure in the provision of the Service,
      And in any event where it would not prove certain that any errors or problems of a technical nature referred to above are directly and exclusively due to
      the serious fault of the administrator. In cases of force majeure, the manager and/or its staff, operators, directors, employees, branches, its subsidiaries
      and representatives shall be totally exempt from liability. Cases of force majeure include, but are not limited to, operating errors in the technical
      equipment of the administrator, lack of internet connection, lack of telephone connections, computer viruses, attacks of any kind and interfering with
      malicious software, unauthorized access to application systems, operating errors, strike, etc.

    </p>
    <p>
      8.10. Users understand and accept that the Administrator is discharge of all liability in the event of any stoppage, interruption, disruption, malfunction
      or failure in the operation of the services and related web pages in the event of technical failure of any kind or failure in the provision of the Service,
      As well as in any situation where it would not prove certain that any errors or problems of a technical nature referred to above are directly and exclusively
      due to the incorrect integration of the digital content or the digital service. In cases of force majeure, the manager and/or its staff, operators, directors
      , employees, branches, its subsidiaries and representatives shall be totally exempt from liability. Cases of force majeure include, but are not limited to,
      operating errors in the technical equipment of the administrator, lack of internet connection, lack of telephone connections, computer viruses, attacks of
      any kind and interfering with malicious software, unauthorized access to application systems, operating errors, strike, etc.

    </p>
    <h2>9. LINK TO OTHER APPLICATIONS</h2>
    <p>
      9.1. Users understand and accept that the application may contain links or references to other applications/websites, which are considered by the
      administrator to be useful in connection with the content of the application, but not under its control or guidance. The administrator is disallowed from
      any responsibility for the content or opinions expressed on all the abovementioned websites, as well as their accuracy and correctness, and users understand
      and accept that these websites are not monitored, controlled or otherwise verified by the administrator. The inclusion of a link or reference to other
      applications/websites does not imply approval by the administrator in any way. When users access these applications/websites, they do so at their own risk,
      knowing that the use of the services provided by these applications/sites is subject to the conditions set by the administrators of these applications.
    </p>
    <h2>10. DURATION AND TERMINATION OF THE CONTRACT</h2>
    <p>10.1. The contract is deemed to be concluded for the entire period during which the user uses the application.</p>
    <p>10.2. The user may stop using the application at any time.</p>
    <p>
      10.3. The administrator reserves the right to suspend or terminate services immediately to a user at any time and without notice:
      <br />
      a) if it finds that it does not comply with the provisions of these terms and conditions, or other policies published in the application;
      <br />
      b) in the event of a failure to use the account for a period of 24 months
    </p>
    <p>
      10.4. The user exercises the right to terminate the contract by email to the administrator at contact@Carfixapp.com, stating his decision to terminate
      the contract and the contract terminates at the date of submission of the declaration.
    </p>
    <p>
      10.5. In the event of termination of the contract, the administrator shall reimburse the user all sums paid under the contract.
    </p>
    <p>
      10.6. However, in cases where the contract provides for the supply of the digital content or the digital service in return for payment of a price and
      for a certain period and where the digital content or service was in conformity during a period prior to the date of termination of the contract,
      The administrator shall reimburse the user only the portion of the price paid corresponding to the period during which the digital content or the
      digital service was not in conformity, and any portion of the price paid by the user in advance for the remainder of the term of the contract, which
      would have been carried out if the contract had not been terminated.
    </p>
    <p>
      10.7. The administrator shall refrain from using any content other than personal data that has been provided or created by the user in the use of the
      digital content or the digital service provided by the administrator, except in one of the following situations where such content:
      <br />
      a) has no use other than in the context of the digital content or the digital service provided by the administrator;
      <br />
      b) relate only to the user's activity when using the digital content or the digital service provided by the administrator;

      <br />
      c) it has been aggregated with other data by the administrator and cannot be disaggregated or disaggregated only with disproportionate effort;
      <br />
      d) it has been jointly generated by the Utiator and other persons, and other users may continue to use the content.

    </p>
    <p>
      10.8. The administrator shall make available to the user, at the user's request, any content which is not personal data, which has been provided
      or created by the user when using the digital content or the digital service provided by the administrator, except in the circumstances referred
      to in paragraph 9.6 (a) to (c), as appropriate.
    </p>
    <p>
      10.9. The user shall have the right to recover that digital content without cost and without impediment from the administrator, within a reasonable
      period of time which may not exceed 15 calendar days from the date of the request, in a commonly used and machine-readable format.
    </p>
    <p>
      10.10. The administrator may prevent any further use of the digital content or the Digital Service by the user and terminate the contract, in particular
      by switching off the user's access to the digital content or the Digital Service or by disabling the user's user account at any time and without notice:
      <br />
      a) if it finds that it does not comply with the provisions of these terms and conditions or other policies published on the website;

      <br />
      b) in the event of a failure to use the account for a period of 24 months.
    </p>
    <p>
      10.11. The user shall refrain from using the digital content or the Digital Service and from making it available to third parties after termination of the contract.
    </p>
    <p>
      10.12. The user shall not be liable to pay for the use of the digital content or the Digital Service during the period prior to the termination of the contract
      and in which the digital content or the Digital Service was not in conformity.
    </p>
    <h2>11. APPLICABLE LAW. DISPUTES</h2>
    <p>
      11.1. The rights and obligations of users and of the administrator, as set out in the terms and conditions, as well as any legal effects resulting from the terms and conditions,
      shall be interpreted and governed in accordance with the Romanian law in force.
    </p>
    <p>
      11.2. Any dispute arising out of or in connection with the terms and conditions shall be settled amicably. In case of impossibility
      to reach an agreement, the dispute will be settled by the Romanian court with jurisdiction within the territorial area of Bucharest.
    </p>
    <p>
      11.3. Consumers cannot give up their legal rights. Any contractual clauses which remove or limit the rights conferred by law on consumers shall not have
      effect on them.
    </p>
    <p>
      11.4. The burden of proof of the supply of the digital content or the digital service in accordance with the criteria laid down in this contract shall
      be on the administrator.
    </p>
    <h2>12. CORRECTIVE ACTION IN CASE OF NON-PROVISION/NON-COMPLIANCE</h2>
    <p>
      12.1.  If the administrator does not provide the digital content or the Digital Service in accordance, the user requests the administrator to provide
      the digital content or the Digital Service. If, in these circumstances, the administrator does not provide the digital content or the Digital
      Service without undue delay or within an additional period of 90 days as provided for in this contract, the user shall have the right to terminate
      the contract.
    </p>
    <p>
      12.2. In the event of non-compliance, and unless the administrator provides the digital content or the Digital Service in accordance with the user's
      request, or after the additional period of 90 days from the date of the request has elapsed, the user shall be entitled to a proportionate reduction
      in the price or termination of the contract.

    </p>
    <p>
      12.3. The user shall have the right to bring the digital content or the Digital Service into conformity unless this would be impossible or would impose
      costs on the administrator which would be disproportionate, taking into account all circumstances, including:
      <br />
      a) the value of the digital content or the digital service if there had been no lack of conformity;
      <br />
      b) the seriousness of the non-compliance.
    </p>
    <p>
      12.4. The administrator shall bring the digital content or the Digital Service into conformity within a reasonable period of time which may not exceed
      15 calendar days from the time the administrator has been informed by the user of the non-compliance and which shall be agreed in writing between the
      administrator and the user, Without any significant cost or inconvenience to the user, taking into account the nature of the digital content or the
      Digital Service and the purpose for which the user requested the digital content or the Digital Service.
    </p>
    <p>
      12.5. The user shall be entitled to either a proportionate reduction in the price where the digital content or the digital service is supplied in return
      for payment of a price, or upon termination of the contract in any of the following cases:

      <br />
      a) the corrective measure to bring the digital content or the digital service into conformity is impossible or disproportionate;
      <br />
      b) the administrator has failed to bring the digital content or the Digital Service into conformity;
      <br />
      c) non-conformity is established despite the attempt by the Adminstrator to bring the digital content or the Digital Service into conformity;
      <br />
      d) the non-compliance is of such severity as to justify an immediate price reduction or immediate termination of the contract;
      <br />
      e) the administrator has declared that he will not bring the digital content or the Digital Service into conformity, or that is clearly apparent
      from the circumstances of the case.
    </p>
    <p>
      12.6. The price reduction is proportional to the decrease in the value of the digital content or the digital service provided to the user
      in comparison with the value that the digital content or the digital service would have if it were in conformity.

    </p>
    <p>
      12.7.  Where the contract provides that the digital content or the digital service is to be supplied during a certain period in return for
      payment of a price, the price reduction shall apply for the period during which the digital content or the digital service was not in conformity.

    </p>
    <p>
      12.8. Where the digital content or the digital service is supplied in return for payment of a price, the user shall have the right to terminate
      the contract only if the non-conformity is not minor.
    </p>
    <p>
      12.9.  In the case referred to in paragraph 10.8, the administrator shall bear the burden of proof of the minor nature of the non-compliance.
    </p>
    <p>
      12.10. In the event of non-compliance, the user shall have the right to suspend payment of any remaining part of the price of the content or the
      Digital Service, or of any part thereof, until the administrator has brought the digital content or the Digital Service into conformity, Provided
      that the suspended payment is not linked to a digital content or service already provided by the administrator and which was in conformity.
    </p>
    <h2>13. TTERMS AND CONDITIONS FOR REIMBURSEMENT BY THE ADMINISTRATOR</h2>
    <p>
      13.1. Any refund which the Adireistor owes to the user as a result of a price reduction or termination of the contract shall be made without
      undue delay and in any event, Within 14 days of the date on which the administrator is informed of the user's decision to invoke the right to the
      price reduction or his right to terminate the contract.
    </p>
    <p>
      13.2. The administrator shall make a refund using the same means of payment as the user has used for payment of the digital content or the digital
      service, Unless the user has given his express consent to the use of other means of payment and provided that the reimbursement does not involve
      the payment of fees by the user.
    </p>
    <p>
      13.3. The administrator does not charge the user any fees related to the reimbursement.
    </p>

    <h2>14. Application rules, limits and modifications</h2>

    <p>
      14.1. The Administrator has the right to modify any of the terms and conditions or terms and conditions in their entirety at any time and in any way
      without prior notice and without being obliged to fulfill any other formality toward the users.
    </p>
    <p>
      14.2. Any modification shall be deemed to be fully and unconditionally accepted by any user by simply using or accessing any facility offered by the
      application or the service, or by accessing the application, occurring at any time after the operation of the modification, And failure to accept any
      change entails the obligation of the respective user to immediately cease accessing the application and/or using the Service in any way.
    </p>
    <p>
      14.3. The application administrator may at any time assign to a third party the rights and obligations arising out of the provisions of these terms
      and conditions, including assignment to a third party of the contract (terms and conditions), and the user consents in advance to such assignment,
      in accordance with the provisions of Articles 1315 et seq. of the Civil Code. To avoid any doubt, application users agree in advance to the substitution
      of GTM MANAGEMENT SERVICES SA with a third party in the relationship between the latter and the users under the terms of these terms and conditions.
    </p>
    <p>
      14.4. The user is advised to periodically check these Terms and Conditions.
    </p>
    <p>
      <em>
        Version date 25.01.2022.
      </em>

    </p>
  </div>
);

export default function LegalPage({ pdfView, fileType }) {
  const TERMS_FILE_ROOT = '/files/terms/';

  // if pdfView is true, it renders the pdf from files, else show a hardcoded page with terms and conditions
  if (pdfView) {
    let fileName = '';
    let pageTitle = '';

    if (fileType === 'gdpr') {
      fileName = 'gdpr.pdf';
      pageTitle = 'Politica de confidențialitate';
    } else if (fileType === 'terms') {
      fileName = 'termenisiconditii.pdf';
      pageTitle = 'Termeni și condiții';
    }

    return (
      <LegalPageWrapper className="LegalPageWrapper">
        <HeaderSteps title={pageTitle} />
        <PDFViewer
          document={{
            url: TERMS_FILE_ROOT + fileName,
          }}
        />
      </LegalPageWrapper>
    );
  }

  const getPageTitle = () => {
    switch (fileType) {
      case 'gdrp': return 'Politica de confidențialitate';
      case 'gdpr_en-US': return 'Privacy policy';
      case 'terms': return 'Termeni și condiții';
      case 'terms_en-US': return 'Terms and coditions';
      default: return 'Document legal';
    }
  };

  return (
    <LegalPageWrapper className="LegalPageWrapper">
      <HeaderSteps title={getPageTitle()} />
      <LegalPageContent className="LegalPageContent">
        {fileType === 'gdpr' && <Privacy />}
        {fileType === 'terms' && <TermsContent />}
        {fileType === 'gdpr_en-US' && <PrivacyEn />}
        {fileType === 'terms_en-US' && <TermsContentEn />}
        {fileType === 'contest_rules' && <ContestRules />}
      </LegalPageContent>
    </LegalPageWrapper>
  );
}
