import React from "react";
import { ReactComponent as LogoSvg } from "../../assets/images/logo-on-white.svg";

import { LogoWrapper } from "./logo.styled";

export default function Logo() {
  return (
    <LogoWrapper className="LogoWrapper">
      <LogoSvg />
    </LogoWrapper>
  );
}
